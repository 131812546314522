import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function RolePermissions() {
    const history = useHistory();
    const translations = language();

    // const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    // const [modalDialogFile, setModalDialogFile] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [id, setId] = useState(0);
    const [roles, setRoles] = useState([]);
    const [userpermissions, setUserpermissions] = useState([]);
    async function getUserPermissions(id) {
        try {
            await api.get(configData.API + '/userrole/' + id).then((response) => {
                // console.log('user request.data', response.data);

                setUserpermissions(response.data);
                // document.getElementById('name').value = response.data.data.name;
                // document.getElementById('email').value = response.data.data.email;


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }

    async function getRoles() {
        try {
            await api.get(configData.API + '/roles').then((response) => {
                // console.log('roles request.data', response.data);

                setRoles(response.data);
                // document.getElementById('name').value = response.data.data.name;
                // document.getElementById('email').value = response.data.data.email;


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }

    function iniciate() {
        getRoles()
    }

    useEffect(() => {
        iniciate();
    }, [iniciate]);

    async function toggleModal(id) {
        await setModalDialog(!modalDialog);
        setErrors('');

        if (id !== 0) {
            getUserPermissions(id);
            setId(id);
        } else {
            setId(0);
        }
    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    async function handleNew(e) {
        // console.log("handleNew")
        e.preventDefault();
    }

    async function handleEdit(e) {
        e.preventDefault();

    }

    const [deleter, setDeleteR] = useState(0);
    async function handleDelete(row) {
        try {
            await api.post(configData.API + '/deleteuser/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);
                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(translations['backoffice']['users']['responses']['delete'][err.response.data]);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    function handleChange(e) {
        // console.log('handleChange', e.target.value, e.target.checked)
        let temp = userpermissions;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setRoles(temp);
        // console.log('after temp', temp)

    }

    const tableColumns = [
        // {
        //     name: translations['backoffice']['roles']['table'].active,
        //     selector: 'str_active',
        //     sortable: true,
        //     center: true,
        //     // width: 100
        // },
        {
            name: translations['backoffice']['roles']['table'].name,
            selector: 'name',
            sortable: true,
            center: true,
            width: 150
        },
        {
            name: translations['backoffice']['roles']['table'].permissions,
            selector: 'permissions',
            sortable: true,
            center: true,
            // width: 100
        },
        {
            name: 'Opções',
            width: 10,
            cell: row => <center>
                {renderOptions(row)}
            </center>
        }
    ];

    function renderOptions(row) {
        let show = true;
        if (row.id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={() => toggleModal(`${row.id}`)}>
                                        {translations['backoffice']['users']['table-buttons'].edit}
                                    </div>
                                </DropdownItem>
                                {/* <DropdownItem as="button"> */}
                                <Link to="/rolepermissions" className="dropdown-item">{translations['backoffice']['users']['table-buttons'].permissions}</Link>
                                {/* <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {translations['backoffice']['users']['table-buttons'].permissions}
                                    </div> */}
                                {/* </DropdownItem> */}
                                {/* <DropdownItem as="button">
                                    <div onClick={() => { resetPassword(row) }}>
                                        {translations['backoffice']['users']['table-buttons']['password-reset']}
                                    </div>
                                </DropdownItem> */}
                                <DropdownItem as="button">
                                    <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {(row.active === 1) ? translations['backoffice']['users']['table-buttons'].delete : translations['backoffice']['users']['table-buttons'].active}
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }

    return (
        <>
        

        <Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
            <ModalHeader toggle={() => toggleModal(0)}>
                {
                    id === 0 ?
                        translations['backoffice']['users']['modal']['header']['add'] :
                        translations['backoffice']['users']['modal']['header']['edit']
                }
            </ModalHeader>
            <form onSubmit={id === 0 ? handleNew : handleEdit} ref={form}>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['name'].title}</label>
                        <div className="col-md-9">
                            <input type="text" id="name" name="user_name"
                                className={(errors.user_name) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['users']['modal']['body']['name'].placeholder}
                                // defaultValue={userpermissions.name}
                                onChange={(e) => handleChange(e)}
                            />
                            {errors.user_name &&
                                <span className="is-invalid">{errors.user_name[0]}</span>
                            }
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['email'].title}</label>
                        <div className="col-md-9">
                            <input type="email" id="email" name="user_email"
                                className={(errors.user_email) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['users']['modal']['body']['email'].placeholder}
                                defaultValue={userpermissions.email}
                                onChange={(e) => handleChange(e)}
                            />
                            {errors.user_email &&
                                <span className="is-invalid">{errors.user_email[0]}</span>
                            }
                        </div>
                    </div>

                    {/* <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['userpermissions-type'].title}</label>
                                <div className="col-md-9">
                                    <select className="form-control" id="userType" onChange={(e) => handleTypeChange(e)}>
                                        <option value={0}>{translations['backoffice']['users']['modal']['body']['userpermissions-type'].placeholder}</option>
                                        {
                                            usersType.map(userType => (
                                                <option key={userType.id} value={userType.id}>{userType.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div> */}
                    {/* <div className="form-group row m-b-15" style={id === 0 ? { display: 'none' } : { display: 'flex' }}>
                                <div className="col-md-12">
                                    <button type="button" id="btnPassword" className="btn btn-warning pull-right" onClick={() => {
                                        document.getElementById('btnPassword').style.display = 'none';
                                        document.getElementById('passwordDiv').style.display = 'block';
                                    }}>{translations['backoffice']['users']['modal']['buttons'].edit_password}</button>
                                </div>
                            </div> */}
                    {/* <div id="passwordDiv" style={id === 0 ? { display: 'block' } : { display: 'none' }}>
                                <div className="form-group row m-b-15">
                                    <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['password'].title}</label>
                                    <div className="col-md-9">
                                        <input type="password" id="password" className="form-control" placeholder={translations['backoffice']['users']['modal']['body']['password'].placeholder} />
                                        {errors.password &&
                                            <span className="is-invalid">{errors.password[0]}</span>
                                        }
                                    </div>
                                </div>
                                <div className="form-group row m-b-15">
                                    <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['password-confirmation'].title}</label>
                                    <div className="col-md-9">
                                        <input type="password" id="passwordConfirmation" className="form-control" placeholder={translations['backoffice']['users']['modal']['body']['password-confirmation'].placeholder} />
                                    </div>
                                </div>
                            </div> */}
                    {/* <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['photo'].title}</label>
                                <div className="col-md-9">
                                    <input type="file" className="form-control" id="file"
                                        onChange={(e) => {
                                            const image = e.target.files[0];

                                            if (validateImage(image)) {
                                                if (image.size > (3 * 1024 * 1024)) {
                                                    setErrorMessage(translations['backoffice']['users']['modal']['body']['photo']['size']);
                                                    setSweetAlertError(true);
                                                }
                                            } else {
                                                document.getElementById('file').value = null;
                                                setErrorMessage(translations['utils']['validateImage']['extension']);
                                                setSweetAlertError(true);
                                            }
                                        }}
                                    />
                                </div>
                            </div> */}

                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations['backoffice']['users']['modal']['footer'].close}</button>
                    <button type="submit" className="btn btn-primary">{translations['backoffice']['users']['modal']['footer'].save}</button>
                </ModalFooter>
            </form>
        </Modal>
        {
            (sweatSuccess &&
                <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
            )
        }
        {
            (alertWarning &&
                <SweetAlert warning showCancel
                    confirmBtnText={translations['alerts']['warning'].confirmBtn}
                    cancelBtnText={translations['alerts']['warning'].cancelBtn}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={translations['alerts']['warning'].title}
                    onConfirm={() => { handleDelete(deleter); setAlertWarning(false) }}
                    onCancel={() => setAlertWarning(false)}
                >
                    {translations['backoffice']['users']['alert']['warning'].message}
                </SweetAlert>
            )
        }
        {
            (SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                    {SweatErrmsg}
                </SweetAlert>
            )
        }

    </>

)}