import React from 'react';
import { Redirect } from 'react-router';

/*App*/
// import Login from './../pages/app/login.jsx';
import Login from './../pages/app/login_apache.jsx'; 
import Logout from './../pages/app/logout.jsx';
import Register from './../pages/app/register.jsx';
import Listprocesses from './../pages/app/processes/listprocesses';

import NewProcessForm from './../pages/app/processes/newprocess';
import ViewProcessForm from './../pages/app/processes/viewprocess';
import Dashboard from './../pages/app/dashboard/dashboard';
import Import from './../pages/app/import/Import';

import Users from './../pages/app/backoffice/users';
import UserProfile from '../pages/app/backoffice/userprofile';
import Courts from './../pages/app/backoffice/courts';
import FocalPoints from './../pages/app/backoffice/focalpoints';
import TerminationMotives from './../pages/app/backoffice/terminationmotives';
import Roles from './../pages/app/backoffice/roles';
import RolePermissions from './../pages/app/backoffice/rolepermissions';


const routes = [
  {
    path: '/',
    exact: true,
    // component: () => <Redirect to='/dashboard/v2'/>
    component: () => <Redirect to='/app/login' />
  },
  {
    path: '/register',
    exact: true,
    title: 'Registo',
    component: () => <Register />
  },
 {
   path: '/app/login',
   exact: true,
   title: 'Login',
   component: () => <Login />,
  //  component: () => <Redirect to='/app/login' />
  },
  {
    path: '/app/logout',
    exact: true,
    title: 'Logout',
    component: () => <Logout />,
    //  component: () => <Redirect to='/app/login' />
  },
  {
    path: '/profile',
    title: 'Profile',
    component: () => <UserProfile />
  },
  {
    path: '/app/dashboard',
    exact: true,
    title: 'Dashboard',
    component: () => <Dashboard />,
    //  component: () => <Redirect to='/app/login' />
  },
  {
    path: '/app/list-processes',
    exact: true,
    title: 'Lista de Processos',
    component: () => <Listprocesses />,
  },
  {
    path: '/app/new-process',
    exact:true,
    title: 'Novo Processo',
    component: () => <NewProcessForm />
  },
  {
    path: '/app/view-process/:id',
    // path: '/app/view-process',
    exact: true,
    title: 'Ver Processo',
    component: () => <ViewProcessForm />
  },
  {
    path: '/app/import',
    exact: true,
    title: 'Importar Processos',
    component: () => <Import />
  },
  {
    path: '/app/users',
    exact: true,
    title: 'Utilizadores',
    component: () => <Users />
  },
  
  {
    path: '/app/courts',
    exact: true,
    title: 'Tribunais',
    component: () => <Courts />
  },
  {
    path: '/app/terminationmotives',
    exact: true,
    title: 'Motivos de Cessação',
    component: () => <TerminationMotives />
  },
  {
    path: '/app/focalpoints',
    exact: true,
    title: 'Lista de Focal Points',
    component: () => <FocalPoints />
  },
  {
    path: '/app/roles',
    exact: true,
    title: 'Tipos de Utilizador',
    component: () => <Roles />
  },
  {
    path: '/app/userpermission',
    exact: true,
    title: 'Perfis e Permissões',
    component: () => <RolePermissions />
  },
  {
    path: '/app/download',
    exact: true,
    title: 'Download',
    // component: () => <RolePermissions />
  }

];


export default routes;