import React, { Component } from "react";
import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

class PermissionList extends Component {
    constructor(props) {
        super(props);
        // this.handleChangeGender = this.handleChangeGender.bind(this);
        this.getPermissionList = this.getPermissionList.bind(this);
        // this.verifyProcess = this.verifyProcess.bind(this);

        this.state = {

            'permissionlist': {},
            'translations': {}

        };

    }

    componentDidMount() {
        this.getPermissionList();

        this.setState({
            'translations': language()
        })

    }

    getPermissionList() {
        //LISTS
        api.get(configData.API + '/permissions').then((request) => {
            console.log('request.data permissions', request.data);
            this.setState({
                permissionlist: request.data
            });

        }).catch((err) => {
            console.log(err);
        });
    }


    render() {
        // console.log('handleChangedistrict OUT', this.state.district);
        // console.log('handleChangeCounty OUT', this.state.county);

        const options = Object.keys(this.state.permissionlist).map((key) =>
            <option key={this.state.permissionlist[key].id} value={this.state.permissionlist[key].id} > {this.state.permissionlist[key].name}</option>
        );

        return (
            <>
                <label>{this.state.translations['permissions']['list-select']['label']} * </label>
                <select
                    multiple
                    id='gender_id'
                    name='gender_id'
                    className="form-control m-b-5"
                    disabled={this.props.disabled}
                    // value={(this.state.gender_value) }
                    value={this.props.gender}
                    onChange={this.props.handleChange}
                    // ref={this.props.refGender}
                    // onBlur={this.props.handleBlur}
                // selectedgendervalue={this.state.selectedgendervalue}
                >
                    <option value="">Selecione as permissões</option>
                    {options}
                </select>
            </>
        )
    }


}

export default PermissionList;