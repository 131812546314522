import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../components/panel/panel.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';

// import validateEmail from '../../../utils/validateEmail';
// import validateImage from '../../../utils/validateImage';

export default function Users() {
    const history = useHistory();
    const translations = language();

    const [id, setId] = useState(true);
    const [reload, setReload] = useState(false);
    const [reloadchgpwd, setReloadchgpwd] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [user, setUser] = useState([]);
    async function getUser() {
        const userinfo = JSON.parse(sessionStorage.getItem('user'));
        
        setId(userinfo.id);
        try {
            await api.get(configData.API + '/user/' + userinfo.id).then((response) => {
                // console.log('user request.data', response.data);

                setUser(response.data);
                document.getElementById('name').value = response.data.data.name;
                document.getElementById('email').value = response.data.data.email;


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        setReload(false);
    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    
    async function handleEdit(e) {
        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);
        await api.post(configData.API + '/userp/' + id, formContent).then((response) => {
            // console.log('response update', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));

            sessionStorage.setItem('user', response.data.user)


            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            setReload(false)
            setTimeout(() => {
                history.go('/app/dashboard')
            }, 3000);




        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }


        });
        setReload(false);
    }

    const formchgpwd = useRef(null);
    async function handlechgpwd(e) {
        e.preventDefault();

        setReloadchgpwd(true);

        const formContent = new FormData(formchgpwd.current);
        await api.post(configData.API + '/userpchgpwd/' + id, formContent).then((response) => {
            // console.log('response update', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            setReloadchgpwd(false)
            setTimeout(() => {
                // history.go('/app/dashboard')
            }, 3000);




        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }


        });
        setReloadchgpwd(false);
    }

    function doCancel() {
        setReload(false)
        history.push('/app/dashboard')

    }

    function handleChange(e) {
        // console.log('handleChange', e.target.value, e.target.checked)
        let temp = user;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setUser(temp);
        

    }
 

    useEffect(() => {
        getUser();
    }, []);

    


    return (
        <>
            <h1 className="page-header">{translations['backoffice']['userprofile'].title}
                {/* <small>&nbsp;{translations['backoffice']['userprofile'].title}</small> */}
            </h1>
            <div className="row">

                <div className="col-md-6">
                    <Panel>

                        <PanelHeader noButton>
                            {translations['backoffice']['userprofile']['panel'].title}
                        </PanelHeader>
                        <form onSubmit={handleEdit} ref={form}>
                            <PanelBody className="paneluserp">


                                <div className="form-group row m-b-15">
                              
                                        <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['name'].title}</label>
                                        <div className="col-md-9">
                                            <input type="text" id="name" name="user_name"
                                                className={(errors.user_name) ? 'form-control m-b-15 is-invalid' : 'form-control m-b-15'}
                                                placeholder={translations['backoffice']['users']['modal']['body']['name'].placeholder}
                                                defaultValue={user.name}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {errors.user_name &&
                                                <span className="is-invalid">{errors.user_name[0]}</span>
                                            }
                                        </div>

                                        <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['email'].title}</label>
                                        <div className="col-md-9">
                                            <input type="email" id="email" name="user_email"
                                                className={(errors.user_email) ? 'form-control m-b-15 is-invalid' : 'form-control m-b-15'}
                                                placeholder={translations['backoffice']['users']['modal']['body']['email'].placeholder}
                                                defaultValue={user.email}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {errors.user_email &&
                                                <span className="is-invalid">{errors.user_email[0]}</span>
                                            }
                                        </div>
                                    </div>


                            </PanelBody>
                            <PanelFooter>
                                <div>
                                    <button className="btn btn-default m-r-10" type="button" onClick={doCancel}>{translations['backoffice']['users']['modal']['footer'].close}</button>
                                    <button className="btn btn-primary float-right" type="submit">{translations['backoffice']['users']['modal']['footer'].save}</button>
                                </div>
                            </PanelFooter>

                        </form>
                    </Panel>
                    {(reload &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                    
                </div>
                
                <div className="col-md-6">
                    <Panel>
                        <PanelHeader noButton>
                            {translations['backoffice']['userprofile']['panel'].title_chgpwd}
                        </PanelHeader>
                        <form onSubmit={handlechgpwd} ref={formchgpwd}>
                            <PanelBody className="paneluserp">
                                <div className="form-group row m-b-15">
                                    <label className="col-form-label col-md-3">{translations['backoffice']['userprofile']['form']['password'].title}</label>
                                    <div className="col-md-9">
                                        <input type="password" id="password" name="password" className={(errors.password) ? 'form-control is-invalid' : 'form-control'}
                                            placeholder={translations['backoffice']['userprofile']['form']['password'].placeholder}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <small className="f-s-12 text-grey-darker">{translations['backoffice']['userprofile']['form']['password'].msg}</small><br></br>
                                        {errors.password &&
                                            <span className="is-invalid">{errors.password}</span>
                                        }
                                    </div>

                                    <label className="col-form-label col-md-3 m-t-15">{translations['backoffice']['userprofile']['form']['password-new'].title}</label>
                                    <div className="col-md-9">
                                        <input type="password" id="passwordNew" name="passwordNew" className={(errors.passwordNew) ? 'form-control m-t-15 is-invalid' : 'form-control m-t-15'}
                                        placeholder={translations['backoffice']['userprofile']['form']['password-new'].placeholder}
                                            // onChange={(e) => handleChangePass(e)} 

                                            />
                                        <small className="f-s-12 text-grey-darker">{translations['backoffice']['userprofile']['form']['password-new'].msg}</small><br></br>
                                        {errors.passwordNew &&
                                            <span className="error">{translations['backoffice']['userprofile']['form']['password-length']}</span>
                                        }
                                    </div>

                                    <label className="col-form-label col-md-3 l m-t-15">{translations['backoffice']['userprofile']['form']['password-confirmation'].title}</label>
                                    <div className="col-md-9">
                                        <input type="password" id="passwordConfirmation" name="passwordConfirmation" 
                                            className={(errors.passwordConfirmation) ? 'form-control m-t-15 is-invalid' : 'form-control m-t-15'}
                                            // placeholder={translations['backoffice']['userprofile']['form']['password-confirmation'].placeholder} onChange={(e) => handleChangePass(e)}

                                            />
                                        {errors.passwordConfirmation &&
                                            <span className="error">{translations['backoffice']['userprofile']['form']['password-match']}</span>
                                        }
                                    </div>
                                </div>
                     
                            </PanelBody>
                            <PanelFooter>
                                <div>
                                    <button className="btn btn-default m-r-10" type="button" onClick={doCancel}>{translations['backoffice']['users']['modal']['footer'].close}</button>
                                    <button className="btn btn-primary float-right" type="submit">{translations['backoffice']['users']['modal']['footer'].save}</button>
                                </div>
                            </PanelFooter>
                        </form>
                    </Panel>
                    {(reloadchgpwd &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                </div>

            </div>
            {(sweatSuccess &&
                <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
            )}
            {(SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                    {SweatErrmsg}
                </SweetAlert>
            )}
            
            
        </>
    )
}