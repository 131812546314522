import React, { Component } from "react";

import api from '../../../config/api';
import configData from '../../../config/config.js';

class StateList extends Component { 

    constructor(props) {
        super(props);   

        this.getStatelist = this.getStatelist.bind(this);

        this.state = {
            'state': this.props.state,
            'disabled': this.props.disabled,
            'errors': null,

            'statelist': {},
        }
    }

    async getStatelist() {
        //LISTS
        await api.get(configData.API + '/statelist').then((request) => {
            this.setState({
                statelist: request.data.data
            });


        }).catch((err) => {
            console.log(err);
        });
    }

    componentDidMount() {


        this.getStatelist();

        if (this.props.errors)
            this.state.errors = this.props.errors;

        this.state.state = (this.props.state !== 0) ? this.props.state : this.state.state;

    }


    render() {

        let optionstmp = []
        optionstmp.push(
            {
                desc: "Selecione o Estado da Medida",
                id: 0,
                
            }
        )

        Object.keys(this.state.statelist).map((i) =>{
                optionstmp.push({
                    desc: this.state.statelist[i].description,
                    id: this.state.statelist[i].id,

                })
            });

        const options = optionstmp.map((item,key) =>
            <option key={item.id} value={item.id}>{item.desc}</option>
        );

        return (
            <>
            <select
                id="state_id"
                name="state_id"
                className="form-control"
                disabled={this.props.disabled}
                value={(this.props.state !== null) ? this.props.state : 0}
                onChange={this.props.handleChangeState}
            >
                {options}
            </select>
            {
                (this.props.errors) && (
                    <span className="is-invalid">{this.props.errors}</span>
                )
            }
            </>
        )
        }
    

}

export default StateList;