import api from '../../../config/api';
import configData from '../../../config/config.js';

export default function userpermissions() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const url = configData.API + '/userrole/' + user.id;
    let abilities=[];
    // console.log('url', url)


    api.get(url).then((request) => {
        // console.log('userpermissions request', request)
        abilities = request.data;
        // console.log('userpermissions abilities', abilities)

    }).catch(function (error) {
        console.log("error",error) 
    });

    return abilities;
    // switch (sessionStorage.getItem('language')) {
    //     case 'pt':
    //         return translations;
    //         break;

    //     case 'en':
    //         return translations_en;
    //         break;
    // }
    // return translations;

}

/*
function getTranslations() {
    const url = configData.API + '/getTranslations/' + sessionStorage.getItem('language');
    console.log('url', url)
    let translation;
    api.get(url).then((request) => {
        console.log('translations js', request)
        return translation = request.data;

    }).catch(function (error) {
        console.log("error", error)
    });
    console.log('translation', translation)
}
*/

// const url = configData.API + '/getTranslations/' + sessionStorage.getItem('language');
// api.get(url).then((request) => {
//     let json = request.data;
// });

// export default language;
