import React from 'react';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';

import configData from '../../config/config.js'
import api from '../../config/api';
import language from './utils/language';

import SweetAlert from 'react-bootstrap-sweetalert';
import {prepareFormData} from './../../config/formdata';


var date = new Date();
const initialstate = {
	userSession: false,
	SweatLogin: false,
	SweatSucmsg: '',
	SweatError: false,
	SweatErrmsg: '',
	formfield: {
		username: '',
		password: '',
	},
	year: date.getFullYear()
};


class Login extends React.Component {
	static contextType = PageSettings;

	state = initialstate;
	constructor(props) {
		super(props);
		
		this.onLogin = this.onLogin.bind(this);
		this.configData = configData;
		this.prepareFormData= this.prepareFormData;

		// console.log('const', this.configData);
	}
  
	componentDidMount() {
		this.context.handleSetPageSidebar(false);
		this.context.handleSetPageHeader(false);
		this.context.handleSetBodyWhiteBg(true);
	}

	componentWillUnmount() {
		this.context.handleSetPageSidebar(true);
		this.context.handleSetPageHeader(true);
		this.context.handleSetBodyWhiteBg(false);
	}
	
	onChangeField = (event) => {
        const valor = event.target.value;
        const nomeCampo = event.target.name;
		var fields = this.state.formfield;

		fields[nomeCampo] = valor;
		this.setState({formfield:fields});
    }

	onLogin = (event) => {
		
		const translations = language();
		// const loginform = prepareFormData(this.state.formfield);
		event.preventDefault();


		//apitokens - passport
		/*

		api.post('/login', loginform).then((request,response) => {
			
			console.log('request, response', request, response);

			localStorage.setItem('access_token', request.data.access_token);
			localStorage.setItem('expires_at', request.data.expires_at);
			localStorage.setItem('token_type', request.data.token_type);
			// localStorage.setItem('name', JSON.stringify(request.data.name));
			// localStorage.setItem('function', request.data.function);

			this.setState({ 
						userSession : true,
						SweatLogin: !this.state.SweatLogin,  
						SweatSucmsg: "Login efetuado com sucesso!"
					});

			
			// this.props.history.push('/app/list-processes');
			this.props.history.push(request.data.route);
		}).catch((err) => {
			console.log(err);
			if (err.request.status === 400) {
				this.setState({ SweatError: !this.state.SweatError,  
								SweatErrmsg: "Credênciais de acesso erradas!",
								formfield: {
									username: '',
									password: '',
								}
							});
			}
		});
		*/
		

		//Sanctum - SPA
		api.get('/sanctum/csrf-cookie').then((response) => {
			// console.log('response sanctum', response);
			
			//FUNCIONA NO APACHE
			var datasend = new FormData()
			if(this.state.formfield !== undefined)
				datasend.append('a',btoa(JSON.stringify(this.state.formfield)))
			// console.log('btoa(JSON.stringify(this.state.formfield)',btoa(JSON.stringify(this.state.formfield)));

			//FUNCIONA NO NGINX
			// var data = { //FUNCIONA NO NGINX
			// 	a:btoa(JSON.stringify(this.state.formfield))
			// }
			// console.log('data',data);
			
			//sanctum: a chamada ao /sanctum/csrf-cookie cria os cookies com os tokens/@csrf e a sessao para ser usado no acesso
			api.post('/api/login', datasend).then((response) => { //FUNCIONA NO APACHE
			//  api.post('/api/login', data).then((response) => { //FUNCIONA NO NGINX
				// console.log('response', response);

				//passport
				// localStorage.setItem('access_token', response.data.access_token);
				// localStorage.setItem('expires_at', response.data.expires_at);
				// localStorage.setItem('token_type', response.data.token_type);
				// localStorage.setItem('name', JSON.stringify(response.data.name));
				// localStorage.setItem('function', response.data.function);

				//localization
				sessionStorage.setItem('language',response.data.language);
				sessionStorage.setItem('user', response.data.user);

				this.props.history.push(response.data.route);
				this.setState({
					userSession: true,
					SweatLogin: !this.state.SweatLogin,
					SweatSucmsg: "Login efetuado com sucesso!"
				});

				// this.props.history.push('/app/list-processes');

			}).catch((err) => {
				console.log(err,err.request.status,err.response.data);
				if (err.request.status === 401) {
					this.setState({
						SweatError: !this.state.SweatError,
						SweatErrmsg: translations['alerts']['wrong-password'],
						userSession: false,
						formfield: {
							username: '',
							password: '',
						}
					});
				}else 
					if (err.request.status === 402) {
						this.setState({
							SweatError: !this.state.SweatError,
							SweatErrmsg: translations['alerts']['inactive-user'],
							userSession: false,
							formfield: {
								username: '',
								password: '',
							}
						});
					}else
						if (err.request.status === 422) {
						this.setState({
							SweatError: !this.state.SweatError,
							SweatErrmsg: err.response.data.message,
							userSession: false,
							formfield: {
								username: '',
								password: '',
							}
						});
					}
			});;


			
		}).catch((err) => {
			console.log(err);
			if (err.request.status === 400) {
				this.setState({
					SweatError: !this.state.SweatError,
					SweatErrmsg: translations['alerts']['wrong-password'],
					userSession: false,
					formfield: {
						username: '',
						password: '',
					}
				});
			}else 
				if (err.request.status === 402) {
					this.setState({
						SweatError: !this.state.SweatError,
						SweatErrmsg: translations['alerts']['inactive-user'],
						userSession: false,
						formfield: {
							username: '',
							password: '',
						}
					});
				}else
					if (err.request.status === 422) {
					this.setState({
						SweatError: !this.state.SweatError,
						SweatErrmsg: err,
						userSession: false,
						formfield: {
							username: '',
							password: '',
						}
					});
				}

				
		});
		
	}

	render() {

		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/gallery-12.jpg)'}}></div>
					<div className="news-caption">
						<img src="/assets/img/alentapp/logo1.png" alt="AA" style={{width: '25%'}}/>
					</div>
				</div>
				<div className="right-content">
					<div className="login-header">
						<div className="brand text-center">
							<img src="/assets/img/cig/logocig_teleassistencia.png" alt="Logo Cig Teleassistência" />
						</div>
						{/* <p className="text-center fw-bolder fs-6 titlelogin">
							tele-assistência
						</p> */}
						<div className="icon">
							<i className="fa fa-sign-in"></i>
						</div>
					</div>
					<div className="login-content">
						<form className="margin-bottom-0" onSubmit={this.onLogin}>
							<div className="form-group m-b-15">
								<input 
									type="text" 
									className="form-control form-control-lg" 
									placeholder="Email Address" 
									value={this.state.formfield.username}
									name="username"
									onChange={this.onChangeField}
									required 
								/>
							</div>
							<div className="form-group m-b-15">
								<input 
									type="password" 
									className="form-control form-control-lg" 
									placeholder="Password" 
									name="password"
									value={this.state.formfield.password}
									onChange={this.onChangeField}
									required 
								/>
							</div>
							<div className="login-buttons">
								<button 
									type="submit" 
									className="btn btn-success btn-block btn-lg w-49" 
								>Login</button>
								{/* <Link to="/register" className="btn btn-primary btn-block btn-lg w-49">Register</Link> */}
								{/* <button type="button" onClick="" className="btn btn-primary btn-block btn-lg w-49">Register</button> */}
							</div>
							<hr />
							<p className="text-center text-grey-darker">
								&copy; alentapp ® All Rights Reserved {this.state.year}
							</p>
							<center>
								<img src="/assets/img/cig/logo_republica.png" alt="AA" style={{ width: '40%' }} />
								<br />
								{/* <img src="/assets/img/cig/logo_comb.jpg" alt="AA" style={{ width: '100%' }} /> */}
								<img src="/assets/img/cig/logocig_comb2.png" alt="AA" style={{ width: '100%' }} />
							</center>
						</form>
					</div>
				</div>
				{(this.state.SweatLogin &&
					<SweetAlert success
						title="Sucesso!"
						confirmBtnBsStyle="success"
						onConfirm={() => this.setState({ SweatLogin: !this.state.SweatLogin })}
					>
						{this.state.SweatSucmsg}
					</SweetAlert>
				)}

				{(this.state.SweatError &&
					<SweetAlert danger
						title="Erro!"
						confirmBtnBsStyle="danger"
						onConfirm={() => this.setState({ SweatError: !this.state.SweatError })}
					>
						{this.state.SweatErrmsg}
					</SweetAlert>
				)}
			</div>
		)
	}
}

export default withRouter(Login);