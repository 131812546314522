import React, { useEffect, useState } from 'react';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from 'react-router-dom';

import DataTable from 'react-data-table-component-with-filter';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle } from 'react-icons/md';

import 'react-circular-progressbar/dist/styles.css';

import SweetAlert from 'react-bootstrap-sweetalert';

function Import(props) {

    const history = useHistory();

    const translations = language();
    const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    const [modalDialogFile, setModalDialogFile] = useState(false);
    
    const [progress, setProgress] = useState(0);
    
    const [files, setFiles] = useState([]);
    const [savedfiles, setSavedFiles] = useState([]);

    const [stateFiles, setStateFiles] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');

    

    async function getImportationList(n) {
        // console.log('getImportationList')
        if(n===200) setReload(true);
        try {
            
            await api.get(configData.API + '/getfiles/'+n).then((response) => {
                // console.log('import request.data', response.data);
                setSavedFiles(response.data);


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        if(n===200) setReload(false);
 
    }

    async function iniciate() {
        await getImportationList(200)
        await getImportationList(500)
        await getImportationList(0)


    }
    useEffect(() => {
        iniciate();
    },[]);

    function toggleModalFile() {
        setModalDialogFile(!modalDialogFile);
    }

    async function sendFiles(e) {
     
        if (files.length === 0) {
            setSweatErrmsg(translations['import']['responses']['send-files']['error-2']);
            setSweatError(!SweatError);
        } else {
            setStateFiles(true);
            const formdata = new FormData();

            formdata.append('file', files);
            // data.append('id', useId.id);
            // data.append('id_user', user.id);
            var erro = 'Ocorreu um erro com o ficheiro de importação.'
            try {
                // api.post(configData.API + '/processu/' + id, formContent).then((response) => {
                await api.post(configData.API + '/import', formdata
                // , {
                //     onUploadProgress: e => {
                //         const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                //         setProgress(progress);
                //     }
                // }
                ).then(response => {
                        // console.log('*** sendFiles response',response);
                        
                        if (response.data.error !== undefined) {
                        console.log('*** sendFiles response error',response.data.error);
                        
                        if (response.data.error.errorinfo !== undefined) erro = response.data.error.errorinfo
                        setSweatErrmsg('Error: Importing to Excel -' + erro);
                        setSweatError(!SweatError);
                        toggleModalFile(0);
                    }else {

                        setSweatSucmsg(response.data.message);
                        setSweatSuccess(!sweatSuccess);
                        toggleModalFile(0);
                        getImportationList(200)
                    }

                    setStateFiles(false);
                        
                        // history.push(response.data.route)

                        // setStateFiles(false);
                        // setProgress(0);
                    })
                .catch(err => {
                    if (err.response.status === 500) {
                        setSweatErrmsg('Error: Formatação de dados no ficheiro a importar.'); //col excel mal formatados
                        setSweatError(!SweatError);
                    } else {
                        setSweatErrmsg(err.response.data.message);
                        setSweatError(!SweatError);
                    }
                    setStateFiles(false);
                })
                
            } catch (error) {
                console.log('*** sendFiles error',error);
                setSweatErrmsg(error);
                setSweatError(!SweatError);
                setStateFiles(false);
            }


        }
    
    }

    // UPLOAD DO FICHEIRO 
    async function upFile(e) {
        if (document.getElementById("valFile").files.length !== 0) {
            await setFiles(e.target.files[0]);
        } else {
            setFiles([]);
        }
    }

    function progressBar(state, options) {
        return (
            <div className="progress rounded-corner mmt-5">
                {options[0] === true && options[1] === true && options[2] === true ?
                    <>
                        {state < 4 ?
                            <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                width: `${((state * 100) / 4)}%`,
                                backgroundColor: ((state * 100) / 4) > 80 ? '#00acac'
                                    : ((state * 100) / 4) > 34 ? '#f59c1a'
                                        : '#ff5b57',
                                transition: 'all .2s ease-out',
                                minWidth: '30px',
                            }}
                            >{((state * 100) / 4).toFixed(0)}%</div>
                            :
                            <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                width: `100%`,
                                backgroundColor: '#00acac',
                                transition: 'all .2s ease-out',
                                minWidth: '30px',
                            }}
                            >100%</div>
                        }
                    </>
                    : (options[0] === true && options[1] === true && (options[2] === false || options[0] === true) && options[2] === true && (options[1] === false || options[2] === true) && options[1] === true && options[0] === false) ?
                        options[2] === true ?
                            <>
                                {state < 4 ?
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `${(((state === 0 ? state : (state - 1)) * 100) / 3)}%`,
                                        backgroundColor: (((state === 0 ? state : (state - 1)) * 100) / 3) > 50 ? '#00acac'
                                            : ((state * 100 - 1) / 3) === 50 ? '#f59c1a'
                                                : '#ff5b57',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >{(((state === 0 ? state : (state - 1)) * 100) / 3).toFixed(0)}%</div>
                                    :
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `100%`,
                                        backgroundColor: '#00acac',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >100%</div>
                                }
                            </>
                            :
                            <>
                                {state < 4 ?
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `${(((state) * 100) / 2)}%`,
                                        backgroundColor: (((state) * 100) / 2) > 50 ? '#00acac'
                                            : ((state * 100 - 1) / 2) === 50 ? '#f59c1a'
                                                : '#ff5b57',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >{(((state * 100) / 2).toFixed(0))}%</div>
                                    :
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `100%`,
                                        backgroundColor: '#00acac',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >100%</div>
                                }
                            </>
                        :
                        options[2] === true ?
                            <>
                                {state < 4 ?
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `${(((state - 2) * 100) / 2)}%`,
                                        backgroundColor: (((state - 1) * 100) / 2) > 50 ? '#00acac'
                                            : ((state * 100 - 1) / 2) === 50 ? '#f59c1a'
                                                : '#ff5b57',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >{(((state - 2) * 100) / 2).toFixed(0)}%</div>
                                    :
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `100%`,
                                        backgroundColor: '#00acac',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >100%</div>
                                }
                            </>
                            :
                            <>
                                {state < 4 ?
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `0%`,
                                        backgroundColor: '#ff5b57',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >0%</div>
                                    :
                                    <div className="progress-bar progress-bar-striped f-s-10 f-w-600" style={{
                                        width: `100%`,
                                        backgroundColor: '#00acac',
                                        transition: 'all .2s ease-out',
                                        minWidth: '30px',
                                    }}
                                    >100%</div>
                                }
                            </>

                }
            </div>
        );
    }

    

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

     const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };

    const tableColumns = [
        {
            name: translations['import']['table'].name,
            selector: row => row.name,
            sortable: true,
            center: true,
            width: 10,
            filterable: true,
        },
        {
            name: translations['import']['table'].user,
            selector: row => row.user,
            sortable: true,
            center: true,
            width: 10,
            filterable: true,
        },
        {
            name: translations['import']['table'].process,
            selector: row => row.process_numorder,
            sortable: true,
            center: true,
            width: 30,
            filterable: true,
        },
        {
            name: translations['import']['table'].created_at,
            selector: row => row.created_at,
            sortable: true,
            center: true,
            width: 30,
            filterable: true,
        },
        // {
        //     name: translations['import']['table'].state,
        //     selector: row => row.state,
        //     sortable: true,
        //     center: true,
        //     width: 30
        // }
    ];

    // console.log('files',files)

    return (
        <>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">{translations['import'].title}</h4>
                    <div className="panel-heading-btn">
                        <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModalFile(0)}><i className="fa fa-plus"></i></button>
                    </div>
                    {/* {({ toggleExpand, toggleRemove, toggleCollapse, toggleReload, onClick }) => (
                        <div className="panel-heading-btn">
                            <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button> &nbsp;&nbsp;
                            <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={toggleReload}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
                            <button className="btn btn-xs btn-icon btn-circle btn-warning" onClick={toggleCollapse}><i className="fa fa-minus"></i></button>&nbsp;&nbsp;
                            <button className="btn btn-xs btn-icon btn-circle btn-danger" onClick={toggleRemove}><i className="fa fa-times"></i></button>
                        </div>
                    )} */}
                </div>
          
                <PanelBody>
                    <div>
                        {/* tabela de importacoes / exportacoes */}
                        <div className="row">
                            <div className="col-md-12">
                                
                                <div className="col-md-12"><p style={{fontWeight: "bold",fontSize: "14px"}} /> 
                                    {/* {translations['import']['panel']['table_applied_measures'].title}</p> */}
                                    <DataTable
                                        keys="id"
                                        pagination
                                        columns={tableColumns}
                                        data={savedfiles}
                                        paginationComponentOptions={paginationComponentOptions}
                                        noDataComponent={"Sem Registos"}
                                        customStyles={customStyles}
                                    />
                                    {(reload &&
                                        <div className="panel-loader">
                                            <span className="spinner-small"></span>
                                        </div>
                                    )}
                                {(reload && 
                                    <div className="panel-loader">
                                        <span className="spinner-small"></span>
                                    </div>
                                )}
                                </div>
                    
                            </div>
                        </div>
                        {
                            (sweatSuccess &&
                                <SweetAlert success
                                    title={translations['alerts']['success'].title}
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => setSweatSuccess(!sweatSuccess)}
                                >
                                    {SweatSucmsg}
                                </SweetAlert>
                            )
                        }

                        {
                            (SweatError &&
                                <SweetAlert danger
                                    title={translations['alerts']['error'].title}
                                    // confirmBtnBsStyle="danger"
                                    confirmBtnBsStyle="default"
                                    onConfirm={() => setSweatError(!SweatError )}
                                >
                                    {SweatErrmsg}
                                </SweetAlert>
                            )
                        }
                    </div>
                </PanelBody>
            </Panel>

            {/* MODAL UPLOAD FILE */}
            <Modal isOpen={modalDialogFile} toggle={() => toggleModalFile()}>
                <ModalHeader toggle={() => toggleModalFile()}>{translations['import']['modal-file']['header'].add}</ModalHeader>
                <ModalBody>
                    {(stateFiles &&
                        <div>
                            <div className="row">
                                <div className="col-md-10"><h5>{translations['import']['modal-file']['body'].uploading}</h5></div>
                                <div className="col-md-2">
                                    <div className="d-flex justify-content-around circular-progressbar">
                                        {progress < 100 ?
                                            <CircularProgressbar
                                                styles={{
                                                    root: { width: 28 },
                                                    path: { stroke: '#0067b1' }
                                                }}
                                                strokeWidth={10}
                                                value={progress}
                                            /> :
                                            <MdCheckCircle size={34} color="#0067b1" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <br />
                            {progress === 100 ?
                                <div className="row">
                                    <div className="col-md-10"><h5>{translations['import']['modal-file']['body'].processing}</h5></div>
                                    <div className="col-md-2">
                                        <div className="d-flex justify-content-around end-circular-progressbar">
                                            <div className="panel-loader mr-3">
                                                <span className="spinner-small"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <div></div>
                            }
                        </div>
                    )}
                    {(!stateFiles &&
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-2">{translations['import']['modal-file']['body']['file'].title}</label>
                            <div className="col-md-10">
                                <input type='file' className='form-control' onChange={upFile} id='valFile' />
                            </div>
                        </div>
                    )}
                </ModalBody>
                {(!stateFiles &&
                    <ModalFooter>
                        <button className="btn btn-white mr-auto" onClick={() => toggleModalFile()}>{translations['import']['modal-file']['footer'].close}</button>
                        <button className="btn btn-primary" onClick={sendFiles}>{translations['import']['modal-file']['footer'].save}</button>
                    </ModalFooter>
                )}
            </Modal>
        </>

        
    )
}

export default Import;