import translations from '../../../config/language/pt.json';
import translations_en from '../../../config/language/en.json';

export default function language() {
    // var translation;
    // const url = configData.API + '/getTranslations/' + sessionStorage.getItem('language');
    // // console.log('url', url)
    // api.get(url).then((request) => {
    //     console.log('translations request', request)
    //     translation = request.data;
    //     console.log('language translation', translation)

    //     return translation;
    // }).catch(function (error) {
    //     console.log("error",error) 
    // });

    // return translation;
    switch (sessionStorage.getItem('language')) {
        case 'pt':
            return translations;
            break;
    
        case 'en':
            return translations_en;
            break;
    }
    return translations;

}

/*
function getTranslations() {
    const url = configData.API + '/getTranslations/' + sessionStorage.getItem('language');
    console.log('url', url)
    let translation;
    api.get(url).then((request) => {
        console.log('translations js', request)
        return translation = request.data;

    }).catch(function (error) {
        console.log("error", error)
    });
    console.log('translation', translation)
}
*/

// const url = configData.API + '/getTranslations/' + sessionStorage.getItem('language');
// api.get(url).then((request) => {
//     let json = request.data;
// });

// export default language;
