import React, { useEffect, useState, useRef } from 'react';
import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { useHistory } from 'react-router-dom';

import DistrictList from "./../components/DistrictList";
import GenderList from "./../components/GenderList";
import MeasureCoercionList from "./../components/MeasureCoercionList";
import CohabitationList from "./../components/CohabitationList";
import FocalPointList from "./../components/FocalPointList";
import TerminationMotiveList from "./../components/TerminationMotiveList";
import DurationList from "./../components/DurationList";
import StateList from "./../components/StateList";

import FilesUploadComponent from "./../components/FilesUpload";

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import DataTable from 'react-data-table-component';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt';

import SweetAlert from 'react-bootstrap-sweetalert';


function NewProcessForm() {

    const history = useHistory();
    const translations = language();

    const moment = require('moment');
    require('moment/locale/pt');
    
    registerLocale('pt', pt);
    setDefaultLocale(pt);

    const possibleDateTimeFormats = [
        // 'D MMMM YYYY',
        // 'D MMMM YYYY HH:mm',
        // 'DD/MM/YY',
        // 'DD/MM/YYYY',
        'DD-MM-YY',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        // 'DD.MM.YYYY HH:mm',
        // 'DD/MM/YYYY HH:mm:ss',
        // 'HH:mm:ss',
        // 'h:mm',
        // 'M/D/YYYY',
        // 'D/M/YYYY',
        // 'MM-DD-YY',
        // 'MM-DD-YYYY',
        // 'MM-DD-YYYY HH:mm:ss',
        // 'MM/DD/YY',
        // 'MM/DD/YYYY',
        // 'MM/DD/YYYY HH:mm:ss',
        // 'MMM D YYYY',
        // 'MMM D YYYY LT',
        // 'MMMM Do YYYY',
        // 'MMMM Do YYYY LT',
        // 'YYYY-DD-MM HH:mm:ss',
        // 'YYYY-MM',
        // 'YYYY-MM-DD',
        // 'YYYY-MM-DD HH:mm',
        // 'YYYY-MM-DD HH:mm:ss',
        // 'YYYY-MM-DD LT',
        // 'YYYY-MM-DD h:mm:ss A',
        // 'YYYY-MM-DDTHH:mm:ssZ',
        'ddd, MMM D YYYY LT',
        'dddd D MMMM YYYY HH:mm',
        'dddd, MMMM Do YYYY LT'
    ];

    // const [districtlist, setDistrictlist] = useState([]);
    // const [districtlist_victim, setDistrictlist_victim] = useState([]);
    
    const [countylist, setCountylist] = useState([]);
    const [countylist_victim, setCountylist_victim] = useState([]);

    // const [courtlist, setCourtlist] = useState([]);

    const [disabled, setDisabled] = useState(false);
    const [locality, setLocality] = useState('');
    const [victim_district, setVictimDistrict] = useState(0);
    const [victim_county, setVictimcounty] = useState(0);
    const [court_district, setCourtDistrict] = useState(0);
    const [court_county, setCourtcounty] = useState(0);

    const [court, setCourt] = useState(0);
    
    const [age, setAge] = useState('');
    // const [num_order, setNumOrder] = useState('');
    const [birth_date, setBirthDate] = useState(new Date());
    const [litigation, setLitigation] = useState(null);
    const [litigationsequence, setLitigationsequence] = useState(null);
    const [litigationid_sequence, setLitigationid_sequence] = useState(0);
    const [state, setState] = useState(1);
            
    const [name, setName] = useState('');
    const [measure_coercion, setMeasureCoercion] = useState(0);
    const [cohabitation, setCohabitation] = useState(0);
    const [model6, setModel6] = useState('');
    const [equipamentocvp, setEquipamentocvp] = useState('');
    // const [disabledMeasureCoercion, setDisabledMeasureCoercion] = useState(true);
    const [measure_coercion_description, setMeasureCoercionDescription] = useState('');
    // const [disabledMeasureCoercionDescription, setDisabledMeasureCoercionDescription] = useState(true);

    const [focal_point_id, setFocalPoint] = useState(0);
    // const [disabledFocalPoint, setDisabledFocalPoint] = useState(true);
    const [focal_point_contact, setFocalPointContact] = useState('');
    // const [disabledFocalPointContact, setDisabledFocalPointContact] = useState(true);

    const [activation_date, setActivationDate] = useState('');
    // const [disabledActivationDate, setDisabledActivationDate] = useState(true);
  
    const [termination_date, setTerminationDate] = useState('');
    // const [disabledTerminationDate, setDisabledTerminationDate] = useState(true);
    const [origtermination_date, setOrigTerminationDate] = useState('');
    const [cessationTerminationDate, setCessationTerminationDate] = useState('');
    // const [disabledOrigTerminationDate, setDisabledOrigTerminationDate] = useState(true);
    const [termination_motive, setTerminationMotive] = useState('');
    const [disabledTerminationMotive, setDisabledTerminationMotive] = useState(true);
    
    const [extension, setExtension] = useState(0);
    const [disabledExtension, setDisabledExtension] = useState(true);
    const [extension_date, setExtensionDate] = useState('');
    const [disabledExtensionDate, setDisabledExtensionDate] = useState(true);
    const [termination_order_date, setTerminationOrderDate] = useState('');
    // const [disabledTerminationOrderDate, setDisabledTerminationOrderDate] = useState(true);

    // const [archive_order_date, setArchiveOrderDate] = useState('');
    // const [voluntary_termination_date, setVoluntaryTerminationDate] = useState('');
    // const [process_files, setProcessFiles] = useState('');

    const [duration, setDuration] = useState(0);
    const [disabledDuration, setDisabledDuration] = useState(true);

    const [selectedFile, setSelectedFile] = useState([]);
    const [progress, setProgress] = useState();
    // const [disabledSelectedFile, setDisabledSelectedFile] = useState(true);

    const [errors, setErrors] = useState('');

    const [SweatSucmsg, setSweatSucmsg] = useState(false);
    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [sweatWarning, setSweatWarning] = useState(false);
    const [SweatError, setSweatError] = useState('');
    const [SweatErrmsg, setSweatErrmsg] = useState(false);

    const [verify, setVerify] = useState(false);

    const form = useRef(null);

   
    function getAge(bDate) {
        // console.log('getAge', bDate);

        var age = moment().diff(bDate,'y')
        
        setAge(age);
        return age;
    }

    


    
    function handleChangeTerminationMotive(e) {
        // console.log('handleChangeTerminationMotive', e.target.value);
        setTerminationMotive(e.target.value);
  
    
    }
    
    function handleChangeCohabitation(e) {
        setCohabitation(e.target.value);
    }

    const [observations, setObservations] = useState('');
    const [disabledObservations, setDisabledObservations] = useState(true);
    function handleChangeObserv(e) {
        
        setObservations(e.target.value);
        document.getElementById('observations').value = e.target.value;
    }

    

    function doCancel() {
        // console.log('doCancel');

        history.push('/app/list-processes');
        
    }


    const [disabledmeasureCoercionDescription, setDisabledmeasureCoercionDescription] = useState(true);
    function handleChangeMeasureCoercion(e) {

        setMeasureCoercion(e.target.value);

        var disablemc = true;
        if (Number(e.target.value) === 1) disablemc = false;
         
        setDisabledmeasureCoercionDescription(disablemc);
 
        
        
    }

    function handleChangeMeasureCoercionDescription(e) {

        setMeasureCoercionDescription(e.target.value);
        document.getElementById('measure_coercion_description').value = e.target.value;
    


    }

    

    async function handleChangeFocalPoint(e) {

        // document.getElementById('focal_point_id').value = e.target.value;

        setFocalPoint(e.target.value);
        var filter = focalpointAr.filter((el)=> el.id===Number(e.target.value))
        
        if (filter.length>0) {
            var email = filter[0].email
            setFocalPointContact(email)
            
            
        }

    }

    function handleChangeFocalPointContact(e) {

        setFocalPointContact(e.target.value);
        document.getElementById('focal_point_contact').value = e.target.value;
   


    }

    function calcTerminationdate(date, months) {
        //calculo da data de termo
        return moment(date).add(months,'months')

      

    }


    function handleChangeDuration(e) {
        // console.log('handleChangeDuration', e.target.value)
        setDuration(e.target.value);

        if(Number(e.target.value) === 0) {
            setTerminationDate(null);
        }else {
            // console.log('activation_date', activation_date);
            var newdate = calcTerminationdate(activation_date, e.target.value);
            // console.log('newdate', newdate,new Date(newdate),moment(newdate,'DD-MM-YYYY'),new Date(moment(newdate,'DD-MM-YYYY').format('YYYY-MM-DD')));
            setTerminationDate(newdate);
            // document.getElementById('termination_date').value = newdate;
            setOrigTerminationDate(newdate);

            setDisabledExtensionDate(false)
        }

        // console.log('extension', extension);
        

    }

    const handleChangeActivationDate = (date) => {
        // console.log('handleChangeActivationDate', date,moment(date, possibleDateTimeFormats, true).isValid());

        
        if(date.length<10) return;
        if (moment(date, possibleDateTimeFormats, true).isValid()) {
        }else {
            // console.log('no valid date',date);
            return
            
        }

            setActivationDate(date);
            
            if (date !== null) {
                //altera tb o termo de cessacao se a duracao for !== 0
                if (duration !== 0) {
                    var newdate = calcTerminationdate(date, duration);
                    // console.log('newdate', newdate);
                    setTerminationDate(newdate);
                    // document.getElementById('termination_date').value = newdate;
                    setOrigTerminationDate(newdate);
                }
                setDisabledDuration(false)
                setDisabledExtension(false)
                
            } else {
                setDisabledDuration(true)
                setDisabledExtension(true)
                setDisabledExtensionDate(true)
            }

    }

    const handleChangeCessationTerminationDate = (date) => {
        // console.log('handleChangeCessationTerminationDate')
        setCessationTerminationDate(date);
        

    }

    function handleChangeState(e) {
        setState(e.target.value);
    }
    

    // const handleChangeTerminationDate = (date) => {
    //     // console.log('handleChangeTerminationDate')
    //     setTerminationDate(date);
    //     document.getElementById('termination_date').selected = date;

    // }

    function handleChangeExtension(e) {
      
        setExtension(e.target.value);
        
        // var term_date = new Date(moment(origtermination_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
        var newdate = calcTerminationdate(extension_date, e.target.value);
        setTerminationDate(newdate);
        
        // verifyprocess('extension');
        // // verifyprocess('termination_date');

    }

    
    function OpExtensionList() {
      
        let numbers = [];
        for (let i = 0; i <= 36; i++) {
            numbers.push(i);
        }

        const options = numbers.map((numbers) =>
            <option key={numbers} value={numbers} > {numbers}</option>
        );

        return (
            <select
                id="extension"
                name="extension"
                className="form-control m-b-5"
                value={extension}
                // defaultValue={extension}
                disabled={disabledExtensionDate}
                onChange={(e) => handleChangeExtension(e)}
            >
                {options}
            </select>

        )
    }

    function handleChangeModel6(e) {
        setModel6(e.target.value);
    }
    
    function OpModel6List(props) {
        // console.log('props', props);

        const opts= ['Não', 'Sim'];
        
        const options = opts.map((opt,index) =>
            <option key={opt} value={opt} >{opt}</option>
        );

        return (
            <select
                id="model6"
                name="model6"
                className="form-control text-center"
                disabled={disabled}
                value={model6}
                onChange={(e) => handleChangeModel6(e)}
            >
                {options}
            </select>

        )
    }

    function handleChangeEquipamentocvp(e) {
        setEquipamentocvp(e.target.value);
    }
    
    function OpEquipamentocvpList(props) {
        // console.log('props', props);

        const opts= ['Não', 'Sim'];
        
        const options = opts.map((opt,index) =>
            <option key={opt} value={opt} >{opt}</option>
        );

        return (
            <select
                id="equipamentocvp"
                name="equipamentocvp"
                className="form-control text-center"
                disabled={disabled}
                value={equipamentocvp}
                onChange={(e) => handleChangeEquipamentocvp(e)}
            >
                {options}
            </select>

        )
    }
    
    

    // console.log('OUT erros',errors);

    // const [startDate, setStartDate] = useState(new Date());
    const [disableBirthdate, setDisableBirthdate] = useState(true);
    const handleChangeBirthDate = (date) => {
        // console.log('handleChangeBirthDate', date);

        setBirthDate(date);
        getAge(date);
        
        // verifyprocess('birth_date');

    }

    // const handleChangeTerminationDate = (date) => {
    //     setTerminationDate(date);
    // }

    

    const handleChangeExtensionDate = (date) => {
        // console.log('handleChangeExtensionDate', date);
        setExtensionDate(date);
        
        if (date !== null) setDisabledExtensionDate(false)
        else setDisabledExtensionDate(true)
        // document.getElementById('activation_date').value = date;
        // verifyprocess('activation_date');
    }

    

    function onBlurActivationDate(e) {
        // console.log('onBlurActivationDate', this);
        this.select = e.target.value;
        this.value = e.target.value;
        // verifyprocess('activation_date');

    }

    // const handleChangeArchiveOrderDate = (date) => {
    //     setArchiveOrderDate(date);
    // }

    const handleChangeTerminationOrderDate = (date) => {
        setTerminationOrderDate(date);
        // setDisabledTerminationMotive(false);
        
        // verifyprocess('termination_order_date');
    }
    
    // function handleChangeTerminationMotive(e) {

    //     // this.setState({
    //     //     termination_motive: e.target.value,
    //     // });

    //     setTerminationMotive(e.target.value);

    // }

    // const handleChangeVoluntaryTerminationDate = (date) => {
    //     setVoluntaryTerminationDate(date);
    // }

    const refBirthDate = React.createRef();
    const refActivationDate = React.createRef();
        
    const refLitigation = React.createRef();
    const [temp_id, setTempId] = useState(0);

    const [disableName, setDisabledName] = useState(true);
    // const [disableGender, setDisabledGender] = useState('true');

    // const [selectedgendervalue, setSelectedgendervalue] = useState(0);

    // async function searchProcess() {
    //     // console.log('searchProcess');

        
    // }

    const [focalpointAr, setFocalpointAr] = useState([]);
    // let focalpointAr=[]
    async function getFocalPointlist() {
        
        //LISTS
        api.get(configData.API + '/focalpointlist').then((request) => {
            // console.log('request.data focalpointlist', request.data.data);
            setFocalpointAr(request.data.data)
        //    focalpointAr = request.data.data
            

        }).catch((err) => {
            console.log(err);
        });
    
    }

    async function iniciate() {
        
        if(focalpointAr.length===0) await getFocalPointlist()
        // if(verify) {
        //     if (litigation !== null) {
        //     // if (newinit) {
        //         // setNewint(false);
        //         // const timer = setTimeout(() => {
        //         //     // console.log('!!! CORRE AGR');
        //         //     searchProcess();
        //         // }, 1000);
        //         return () => clearTimeout(timer);
        //     }
        // }
    }

    // const [newinit, setNewint] = useState(true);
    useEffect(() => {
        // console.log('useeffect', litigation);
        iniciate()

        
    }, []);
    // }, [litigation, searchProcess ]);

    // function formatDate(dt) {
    //     if(dt !== '')
    //         return ((dt.getDate() < 10) ? '0' + dt.getDate() : dt.getDate()) + '-' +
    //             (((dt.getMonth() + 1) < 10) ? '0' + (dt.getMonth() + 1) : + (dt.getMonth() + 1)) + '-' +
    //             dt.getFullYear();
    //     else return '';
    // }
    

    // console.log('OUT temp_id', temp_id);
    
    const refGender = React.createRef();
    const [gender, setGender] = useState('');
    const [disabledgender, setDisabledgender] = useState(true);

    // function handleChange(e) {
    //     //i: index do campo
    //     console.log('handleChange');
    //     const value = e.target.value;
    //     setState({
    //         ...state,
    //         [e.target.name]: value
    //     });

    //     console.log('handleChange',state);
    // }
    
    function handleChangeGender(e) {
        // console.log('handleChangeGender', e.target.value);
        setGender(e.target.value);
        // verifyprocess('gender');
        // this.setState({
        //     gender: e.target.value,
        // });

        // // verifyprocess();

    }

    // function handleBlur(e) {
    //     console.log('BLLUURRR', gender);
    //     // verifyprocess('gender');
    // }

    // const refVictimdistrict = React.createRef();
    
    
    

    async function handleChangeDistrict(e,field) {
        // console.log("setDistrict_Selected", field,e.target.value);

        switch (field) {
            case 'victim_district_id':
                document.getElementById('victim_district_id').value = e.target.value;    
                setVictimDistrict(e.target.value);
                // verifyprocess('victim_district_id');
                
                break;
            case 'court_district_id':
                document.getElementById('court_district_id').value = e.target.value;
                setCourtDistrict(e.target.value)
                // verifyprocess('court_district_id');
                
                break;
            default:
                break;
        }

       
        // document.getElementById('victim_district_id').value = e.target.value;
        await getCountylist(e.target.value, field)


       
    }

    async function getCountylist(district_id,field) {
        // console.log('primer getCountylist', district_id);
        //LISTS
        if ((district_id !== undefined) && (district_id !== '')) {
            await api.get(configData.API + '/countylist/' + district_id).then((request) => {
                // console.log('request.data countylist', request.data.data);

                switch (field) {
                    case 'victim_district_id':
                        setCountylist_victim(request.data.data)
                        break;
                    case 'court_district_id':
                        setCountylist(request.data.data);
                        break;
                    default:
                        break;
                }

                
                // this.setState({
                //     countylist: request.data.data
                // });

            }).catch((err) => {
                console.log(err);
            });
        }
        
    }


    async function verifyProcessNumber() {
        
        api.get(configData.API + '/vprocess/', {
            params: {
                litigation: document.getElementById('litigation').value
            }
            }).then((response) => {
            // console.log('verifyProcessNumber', response.data)

            let rprocess = response.data.process
            if (response.data.litigationsequence !== null) {
                setLitigationsequence(response.data.litigationsequence)
                setSweatSucmsg(response.data.message)
                setSweatSuccess(true)
                
                //da origem a novo processo com os dados do antigo
                //fica a hipotese de haver campos diferentes se estiver fechado ou nao
                // if (response.data.process.closed) { //da origem a novo processo com os dados do antigo
                setLitigationid_sequence(rprocess.id)
                setLitigation(rprocess.litigation)
                setState(1)
                setName(rprocess.name);
                setGender(rprocess.gender_id);
                setBirthDate(new Date(rprocess.birth_date));
                setVictimDistrict(rprocess.victim_district_id);
                setVictimcounty(rprocess.victim_county_id);
                setLocality(rprocess.locality)
                setCourtDistrict(rprocess.court_district_id);
                setCourtcounty(rprocess.court_county_id);
                setCourt(rprocess.court_id);
                // setMeasureCoercion(response.data.measure_coercion);
                setFocalPoint(rprocess.focal_point_id);
                setFocalPointContact(rprocess.focal_point_contact)
                // setDuration(response.data.duration);
                // setTerminationDate(response.data.termination_date);
                // setExtension(response.data.extension);
                // if (response.data.activation_date !== null) {
                //     setActivationDate(new Date(response.data.activation_date));
                // }
                // setOrigTerminationDate(response.data.termination_date);
                // setTerminationMotive(response.data.termination_motive_id);
                // setObservations(response.data.observations);
                setSavedFiles(response.data.docs);
           
            }

            
        });
    }

    async function handleBlurLitigation(e) {
        // console.log('handleBlurLitigation');
        setVerify(true);
        // setLitigation(e.target.value);

        //clear all
        // await clearAll();
        verifyProcessNumber();

    }

    const [typingTimer, setTypingTimer] = useState(0);
    const [doneTypingInterval, setDoneTypingInterval] = useState(1000);
    function handleKeyUpLitigation(e) {
        clearTimeout(typingTimer);
        setLitigation(e.target.value);
        setTypingTimer(setTimeout(handleBlurLitigation, doneTypingInterval));
    }


    function handleChangeName(e) {
        setName(e.target.value);
        // if(temp_id !== 0) {
        //     // verifyprocess(e.target.name);
        // }
        
    }
    

    

    const [files, setFiles] = useState([]);
    const [savedfiles, setSavedFiles] = useState([]);
    function onFileChange(e) {
        // this.setState({ files: e.target.files })
        setFiles([]);
        let tmpfiles = [];
        for (const key of Object.keys(e.target.files)) {
            // formData.append('imgCollection', this.state.imgCollection[key])
            tmpfiles.push(e.target.files[key]);
        }
        // console.log('onfilechange array ', tmpfiles)
        setFiles(tmpfiles);

        // console.log('onfilechange', files)
    }

    const handleSubmit = (event) => {
        // console.log('submit form');
        event.preventDefault();
        setVerify(false);
        

        // console.log('submit files', files)

        // console.log('form.current',form.current);        
        
        let formContentArray=[]
        
        formContentArray.push({name:name})
        formContentArray.push({locality:locality})

        formContentArray.push({victim_district_id:document.getElementById('victim_district_id').value })
        formContentArray.push({victim_county_id: document.getElementById('victim_county_id').value })
        
        formContentArray.push({litigation:litigation})
        formContentArray.push({litigationsequence:litigationsequence})

        formContentArray.push({gender_id:(gender!=='') ? Number(gender) : null})
        // formContentArray.push({court_id:court})
        formContentArray.push({court_id:document.getElementById('court_id').value })
        formContentArray.push({court_district_id:document.getElementById('court_district_id').value })
        formContentArray.push({court_county_id: document.getElementById('court_county_id').value })
       
        // AINDA FALTAM CAMPOS
        formContentArray.push({birth_date:moment(birth_date,'DD-MM-YYYY').format('DD-MM-YYYY')});
        formContentArray.push({termination_date: moment(termination_date,'DD-MM-YYYY').format('DD-MM-YYYY')});
        formContentArray.push({termination_order_date: moment(termination_order_date,'DD-MM-YYYY').format('DD-MM-YYYY')});
        
        formContentArray.push({activation_date: (activation_date !== null) ? moment(activation_date,'DD-MM-YYYY').format('DD-MM-YYYY') : null});
        formContentArray.push({extension_date: moment(extension_date,'DD-MM-YYYY').format('DD-MM-YYYY')});
        formContentArray.push({cessation_termination_order_date: (cessationTerminationDate !== null) ?  moment(cessationTerminationDate,'DD-MM-YYYY').format('DD-MM-YYYY') : null});
        
        formContentArray.push({state_id:(state !== null) ? state: 1})
        formContentArray.push({cohabitation:cohabitation})
        formContentArray.push({model6:model6})
        formContentArray.push({equipamentocvp:equipamentocvp})
        formContentArray.push({measure_coercion:measure_coercion})
        formContentArray.push({measure_coercion_description:measure_coercion_description})
        formContentArray.push({focal_point_id:focal_point_id})
        formContentArray.push({focal_point_contact:focal_point_contact})
        formContentArray.push({termination_motive:(termination_motive!=='') ? termination_motive : null})
        formContentArray.push({extension:extension})
        formContentArray.push({duration:(duration!=='') ? duration : 0})

        formContentArray.push({litigationid_sequence:litigationid_sequence})
        
        
        // console.log('JSON.stringify',JSON.stringify(formContentArray));
        const formContent = new FormData();
        formContent.append('data', JSON.stringify(formContentArray));

        
        // Details of the uploaded file
        for (let i = 0; i < files.length; i++) {
            formContent.append('file_'+i,files[i]);
            
        }

        api.post(configData.API + '/process', formContent, {
            onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            }
        }
        ).then((response) => {
            // console.log('response', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success

            setSweatSucmsg(response.data.message);
            if(response.data.type) setSweatWarning(true);
            else setSweatSuccess(true);

            setTimeout(() => {
                history.push(response.data.route);
            }, 5000);
            // history.push('/app/list-processes');


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) {
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) {
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }



        });
        
    }
    

    function handleback() {

        history.push('/app/list-processes');
    }


    // let rows=[];
    const rows = Object.keys(files).map((i) =>
        <tr key={i}><td>{files[i].name}</td><td>{files[i].type}</td><td>{new Date(files[i].lastModified).toLocaleString()}</td><td>A enviar...</td></tr>
    );

    
    function deleteFile(id) {
        //elimina o fich da bd    
        // console.log('delete', id)

        const formdata = new FormData();
        formdata.append('process_doc_id', id);

        // console.log('formdata', formdata);

        api.post(configData.API + '/deletefile/' + id, formdata).then((response) => {
            // console.log('request.data process delete', JSON.stringify(response.data.data));
            //sweetalert success

            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

        }).catch((err) => {
            // console.log('erro POST DELETE', err);
            if (err.request.status === 422) {
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);
           
            } else {
                setSweatSucmsg();
                setSweatError(!{ SweatError });
                setSweatErrmsg(err);
            }



        });


    }

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const columns = [
        {
            name: 'Estado',
            selector: 'state',
            width: 10,
            sortable: true,
            center: true,
            // className: 'text-center'
        },
        {
            name: 'Ficheiro',
            selector: 'name',
            sortable: true,
            center: true,
            // className: 'text-center'
        },
        {
            name: 'Tipo de Ficheiro',
            selector: 'filetype',
            sortable: true,
            center: true,
        },
        {
            name: 'Última Modificação',
            selector: 'created_at',
            sortable: true,
            center: true,
        },
        {
            name: 'Opções',
            center: true,
            cell: row => <center>
                {renderOptions(row.id)}
            </center>,
        }
    ]

    function renderOptions(id) {
        let show = true;
        if (id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={(e) => deleteFile(`${id}`)}>
                                        Eliminar
                                    </div>
                                </DropdownItem>
                                {/* <DropdownItem as="button">
                            <div onClick={() => deactivateProcess(`${id}`)}>
                                Desativar
                            </div>
                        </DropdownItem> */}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }
    

    return( 
        <Panel>
            <div className="panel-heading">
                <h4 className="panel-title">Novo Processo</h4>
               
                <div className="panel-heading-btn">
                    <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => handleback()}><i className="fa fa-reply"></i></button>
                </div>
            </div>
            <PanelBody>
                
                <form ref={form} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group m-b-15 col-xl-2 ">
                            <label>Nº Processo Judicial *</label>
                            <input
                                id="litigation"
                                name="litigation"
                                type="text"
                                className={(errors.litigation) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                defaultValue={litigation}
                                // onChange={(e) => setLitigation(e.target.value)}
                                // onChange={handleChangeLitigation}
                                // onBlur={(e) => handleBlurLitigation(e)}
                                onKeyUp={(e) => handleKeyUpLitigation(e)}
                                ref={refLitigation}
                                autoComplete='off'
                            />
                            {errors.litigation &&
                                <span className="is-invalid">{errors.litigation[0]}</span>
                            }
                        </div>
                        <div className="form-group m-b-15 col-xl-2 ">
                            <label>Processo Judicial Relacionado</label>
                            <input
                                id="litigationsequence"
                                name="litigationsequence"
                                type="text"
                                className='form-control m-b-5'
                                defaultValue={litigationsequence}
                                disabled
                              
                            />
                            
                        </div>
                        <div className="form-group m-b-15 col-xl-1 ">
                            
                            <label>Estado</label>
                            <StateList
                                state={state}
                                handleChangeState={(e) => handleChangeState(e)}
                             />
                            
                        </div>

                        <div className="form-group m-b-15 col-xl-1">
                            <label htmlFor="">Data de Despacho *</label>

                            <Datetime
                                className={(errors.termination_order_date) ? 'text-center is-invalid' : 'text-center'}
                                id="termination_order_date"
                                name="termination_order_date"
                                locale="PT"
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                selected={termination_order_date}
                                value={termination_order_date}
                                onChange={(date) => handleChangeTerminationOrderDate(date)}
                                
                            />
                           
                            {
                                (errors.termination_order_date) && (
                                    <span className="is-invalid m-t-20">{errors.termination_order_date[0]}</span>
                                )
                            }

                        </div>
                        <div className="form-group m-b-15 col-xl-2 ">

                            <CohabitationList
                                cohabitation={cohabitation}
                                handleChangeCohabitation={(e) => handleChangeCohabitation(e)}
                                disabled={disabled}
                            />

                        </div>
                        <div className="form-group m-b-15 col-xl-1">
                            <label htmlFor="">Modelo 6</label>
                            <OpModel6List />
                            
                        </div>
                        <div className="form-group m-b-15 col-xl-2">
                            <label title="Equipamento Entregue na CVP" htmlFor="">Equip. Entregue na CVP</label>
                            <OpEquipamentocvpList />
                            
                        </div>
                        <div className="form-group m-b-15 col-xl-1">
                                 
                            <GenderList
                                handleChangeGender={handleChangeGender}
                                
                                gender={gender}
    
                            />
                            {errors.gender_id &&
                                <span className="is-invalid">{errors.gender_id[0]}</span>

                            }
                            
                        </div>
                    </div>

                    <div className="row">
                        

                        <div className="form-group m-b-15 col-md-8">
                            <label htmlFor="">Nome do/a Utente * </label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                className={(errors.name) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5' }
                                defaultValue={name}

                                onChange={(e) => handleChangeName(e)}
                    
                                autoComplete='off'
                            />
                            {
                                (errors.name) && (
                                    <span className="isValid">{errors.name[0]}</span>
                                )
                            }
                        </div>
                        
                        <div className="form-group m-b-15 col-xl-2">
                            <label htmlFor="">Data de Nascimento * </label>
                                <Datetime
                                    // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                    className={(errors.birth_date) ? 'text-center is-invalid' : 'text-center'}
                                    id="birth_date"
                                    name="birth_date"
                                    locale="PT"
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat={false}
                                    selected={birth_date}
                                    value={birth_date}
                                    onChange={(date) => handleChangeBirthDate(date)}
                                    ref={refBirthDate}
                                />
                                {/* <DatePicker locale="pt" dateFormat="dd-MM-yyyy" 
                                    className={(errors.birth_date) ? 'form-control text-center m-b-5 is-invalid' : 'form-control text-center m-b-5'}
                                    id="birth_date"
                                    name="birth_date"
                                    selected={birth_date}
                                    onChange={(e) => handleChangeBirthDate(e)}
                                    ref={refBirthDate}
                    
                                /> */}
                            
                            {errors.birth_date &&
                                <span className="is-invalid">{errors.birth_date[0]}</span>

                            }
                        </div>
                        <div className="form-group m-b-15 col-xl-2">
                            <label htmlFor="">Idade</label>
                            <input
                                name='age'
                                type="number"
                                className="form-control m-b-5 text-center"
                                value={age}
                                disabled

                            />
                        </div>
                    </div>
                    
                    <div className="row m-b-15">
                        

                        <DistrictList
                            cols='4'
                            name_district='victim_district_id'
                            name_county='victim_county_id'
                    
                            hcourt='false'
                            error_district={errors.victim_district_id}
                            error_county={errors.victim_county_id}
                
                            handleChangeDistrict={handleChangeDistrict}
            
                            district={victim_district}
                            county={victim_county}
                        />
                        
                        
                        
                        <div className="form-group col-xl-4">
                            <label htmlFor="">Localidade (Vitima) * </label>
                            <input
                                id="locality"
                                name="locality"
                                type="text"
                                className={(errors.locality) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                defaultValue={locality}
                                onChange={(e) => setLocality(e.target.value)}
                                // disabled={disabledLocality}
                                autoComplete='off'
                            />
                            {errors.locality &&
                                <span className="is-invalid">{errors.locality[0]}</span>

                            }
                        </div>
                    
                    </div>
                    <hr></hr>
                    <div className="row">
                                    
                        <legend className="no-border f-w-700 p-b-0 m-t-10 m-b-10 m-l-10 f-s-16 text-inverse">Tribunal</legend>

                            <DistrictList
                                name_district='court_district_id'
                                name_county='court_county_id'
                                cols='4'
                                hcourt='true'
                                error_district={errors.court_district_id}
                                error_county={errors.court_county_id}
                                error_court={errors.court_id}
                                district={court_district}
                                county={court_county}
                                court={court}
                                handleChangeDistrict={handleChangeDistrict}
                                
                            />

                    </div>

                    <div className="row">
                      
                        <MeasureCoercionList
        
                            handleChangeMeasureCoercion={(e) => handleChangeMeasureCoercion(e)}
                                cols='2'
                                errors={errors.measure_coercion}
                                measure_coercion={measure_coercion}
                                />
                           
                                
                        <div className="m-b-15 col-xl-5">
                            <label>Qual?</label>
                            <div >
                                <input
                                    id="measure_coercion_description"
                                    name="measure_coercion_description"
                                    disabled={disabledmeasureCoercionDescription}
                                    type="text"
                                    className="form-control m-b-5"
           
                                    onChange={(e) => handleChangeMeasureCoercionDescription(e)}
                                    autoComplete='off'
                                />
                            </div>
                        </div>

                        <FocalPointList
                            // disable='true'

                            handleChangeFocalPoint={(e) => handleChangeFocalPoint(e)}

                            cols='2'
                            errors={errors.focal_point_id}
                            focal_point_id={focal_point_id}
                        />


                        <div className="form-group m-b-15 col-xl-3">
                            <label htmlFor="">Focal Point: Contato *</label>
                            <input
                                id="focal_point_contact"
                                name="focal_point_contact"
                                type="text"
                                className={(errors.focal_point_contact) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                defaultValue={focal_point_contact}
                                onChange={(e) => handleChangeFocalPointContact(e)}
                                autoComplete='off'
                            />
                            {errors.focal_point_contact &&
                                <span className="is-invalid">{errors.focal_point_contact[0]}</span>

                            }
                        </div>
                    </div>
                    <hr></hr>
                   
                    <div className="row">
                 
                        <div className="form-group m-b-5 col-xl-5 offset-1">
                            <div className="row">
                                <div className="form-group col-xl-5 ">
                                    <legend className="no-border f-w-700 p-b-0 m-t-10 f-s-16 text-inverse">Ativação</legend>
                                    <div className='col-xl-12'>
                                    <label  htmlFor="">Data de Ativação *</label>
                                    {/* <input type="date" id="activation_date" name="activation_date"
                                        className={(errors.activation_date) ? 'form-control text-center m-b-5 is-invalid' : 'form-control text-center m-b-5'}
                                        value={activation_date}
                                        // onChange={(date) => handleChangeActivationDate(date)}
                                        /> */}
                                    <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className="text-center"
                                            id="activation_date"
                                            name="activation_date"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={activation_date}
                                            value={activation_date}
                                            onChange={(date) => handleChangeActivationDate(date)}
                                            ref={refActivationDate}
                                        />
                                    {/* <DatePicker 
                                        locale="PT" 
                                        dateFormat="dd-MM-yyyy"
                                        className={(errors.activation_date) ? 'form-control text-center m-b-5 is-invalid' : 'form-control text-center m-b-5'}
                                        id="activation_date"
                                        name="activation_date"
                                        selected={activation_date}
                                        onChange={(date) => handleChangeActivationDate(date)}

                                        ref={refActivationDate}

                                    /> */}
                                    {errors.activation_date &&
                                        <span className='invalid-feedback'>{errors.activation_date[0]}</span>

                                    }
                                    </div>
                                </div>
                            

                                <div className="form-group m-b-15 m-t-40 col-xl-6">
                                    <label htmlFor="">Duração (meses) *</label>
                                    <DurationList
                                    
                                        duration={duration}
                                        errors={errors.duration}
                                        limit={36}
                                        handleChangeDuration={(e) => handleChangeDuration(e)}
                                        disabled={disabledDuration}
                                    />
                                
                                </div>
                            </div>
                        </div>

                        <div className="form-group m-b-15 col-xl-5 ">
                            <div className="row">
                                <div className="form-group m-b-15 col-xl-5 ">
                                    <legend className="no-border f-w-700 p-b-0 m-t-10 f-s-16 text-inverse">Prorrogação</legend>
                                    <div className="form-group m-b-15 offset-1">
                                        <label htmlFor="">Data de Prorrogação *</label>
                                        <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className={(errors.extension_date) ? 'text-center is-invalid' : 'text-center'}
                                            id="extension_date"
                                            name="extension_date"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={extension_date}
                                            value={extension_date}
                                            disabled={disabledExtensionDate}
                                            onChange={(date) => handleChangeExtensionDate(date)}
                                            
                                        />
                                        {/* <DatePicker locale="pt" dateFormat="dd-MM-yyyy"
                                            className={(errors.extension_date) ? 'form-control text-center m-b-5 is-invalid' : 'form-control  text-center m-b-5'}
                                            id="extension_date"
                                            name="extension_date"
                                            selected={extension_date}
                                            disabled={disabledExtensionDate}
                                            onChange={(date) => handleChangeExtensionDate(date)}

                                        /> */}
                                        {errors.extension_date &&
                                            <span className='invalid-feedback'>{errors.extension_date[0]}</span>

                                        }
                                    </div>
                                </div>
                                <div className="form-group m-b-15  m-t-40 col-xl-6 ">
                            
                                        <label htmlFor="">Prorrogação (meses)</label>
                                        <OpExtensionList />

                            
                                </div>
                            </div>
                        </div>

                    </div>
                                        
                    <div className="row form-group m-b-15 ">
                        <div className='col-xl-12  offset-1 '>
                            <div className="row">
                                <div className="form-group m-b-15 col-xl-6">
                                    <legend className="no-border f-w-700 p-b-0 m-t-10 f-s-16 text-inverse">Cessação</legend>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-xl-2 p-l-35'>
                                    <label htmlFor="">Data de Despacho de Cessação *</label>
                                    <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className={(errors.cessation_termination_order_date) ? 'text-center is-invalid' : 'text-center'}
                                            id="cessationTerminationDate"
                                            name="cessationTerminationDate"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={cessationTerminationDate}
                                            value={cessationTerminationDate}
                                            onChange={(date) => handleChangeCessationTerminationDate(date)}
                                            
                                        />
                                    
                                    {errors.cessation_termination_order_date &&
                                        <span className='invalid-feedback'>{errors.cessation_termination_order_date[0]}</span>

                                    }
                                </div>
                                
                                <div className="form-group m-b-15 col-xl-5">
                                    <TerminationMotiveList

                                    handleChangeTerminationMotive={(e) => handleChangeTerminationMotive(e)}

                                    termination_motive={termination_motive}
                                    cols='12'
                                    />
                                </div>
                                
                                <div className="form-group m-b-15  col-xl-2">
                                    <label htmlFor="">Termo Previsto da Cessação</label>
                                    <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className="text-center"
                                            id="termination_date"
                                            name="termination_date"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={termination_date}
                                            value={termination_date}
                                            
                                            
                                        />
                                   
                                    
                                </div>
                   
                            </div>
                        </div>
                    </div>

                    <div className="row form-group m-b-15 ">
                        <div className="col-md-6 ">
                            <label htmlFor="">Observações</label>
                            <textarea className="form-control m-b-5" 
                                value={observations}
                                onChange={(e) => handleChangeObserv(e)}
                   
                                id="observations"
                                name="observations"
             
                                rows={20}
                                >
                                {observations}</textarea>
                        </div>
                        <div className="col-md-6 ">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Ficheiros de Processo</h6>
                                </div>
                            </div>
                            <FilesUploadComponent 
                                files={files} 
                                onFileChange={(e) => onFileChange(e)}
                                
                                />    
                            <div className="row">
                                <table className="table" >
                                    <thead>

                                        <tr>
                                            <th>Ficheiro</th>
                                            <th>Tipo de Ficheiro</th>
                                            <th>Ultima Modificação</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                    
                                </table>
                            </div>
                            {/* <div className="row">
                                <DataTable
                                    keys="id"
                                    pagination
                                    paginationPerPage={5}
                                    id="filedocs"
                                    columns={columns}
                                    data={savedfiles}
                                    paginationComponentOptions={paginationComponentOptions}
                                    noDataComponent={"Sem Registos"}
                                />

                            </div> */}
                    
                        </div>

                    </div>

                    <div>
                        {
                            (sweatSuccess &&
                                <SweetAlert success
                                    title={translations['alerts']['success'].title}
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => setSweatSuccess(!sweatSuccess)}
                                >
                                    {SweatSucmsg}
                                </SweetAlert>
                            )
                        }

                        {
                            (sweatWarning &&
                                <SweetAlert warning
                                    title={translations['alerts']['missingfields'].title}
                                    confirmBtnBsStyle="warning"
                                    onConfirm={() => setSweatWarning(!sweatWarning)}
                                >
                                    {SweatSucmsg}
                                </SweetAlert>
                            )
                        }

                        {
                            (SweatError &&
                                <SweetAlert danger
                                    title={translations['alerts']['error'].title}
                                    confirmBtnBsStyle="danger"
                                    onConfirm={() => setSweatError({ SweatError: !SweatError })}
                                >
                                    {SweatErrmsg}
                                </SweetAlert>
                            )
                        }
                    </div>
                    <>
                        <hr></hr>
                        <div>

                            <button className="btn btn-danger m-r-10" type="button" onClick={(e) => doCancel(e)}>Cancelar</button>
                            <button className="btn btn-success " type="submit">Guardar</button>
                        </div>
                    </>
                </form>
            </PanelBody>

        </Panel>
    )
}

export default NewProcessForm;