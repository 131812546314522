import React, { useEffect, useState } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DataTable from 'react-data-table-component';
import SweetAlert from 'react-bootstrap-sweetalert';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";

import StateList from "./../components/StateList";


import configData from '../../../config/config.js';
import api from '../../../config/api';


export default function ModalExport(props) {


    registerLocale('pt', pt);
    setDefaultLocale(pt);
    const possibleDateTimeFormats = [
        // 'D MMMM YYYY',
        // 'D MMMM YYYY HH:mm',
        'DD-MM-YY',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'DD.MM.YYYY HH:mm',
        'DD/MM/YY',
        'DD/MM/YYYY',
        'DD/MM/YYYY HH:mm:ss',
        'HH:mm:ss',
        'h:mm',
        'M/D/YYYY',
        'D/M/YYYY',
        'MM-DD-YY',
        'MM-DD-YYYY',
        'MM-DD-YYYY HH:mm:ss',
        'MM/DD/YY',
        'MM/DD/YYYY',
        'MM/DD/YYYY HH:mm:ss',
        'MMM D YYYY',
        'MMM D YYYY LT',
        'MMMM Do YYYY',
        'MMMM Do YYYY LT',
        'YYYY-DD-MM HH:mm:ss',
        'YYYY-MM',
        'YYYY-MM-DD',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DD LT',
        'YYYY-MM-DD h:mm:ss A',
        'YYYY-MM-DDTHH:mm:ssZ',
        'ddd, MMM D YYYY LT',
        'dddd D MMMM YYYY HH:mm',
        'dddd, MMMM Do YYYY LT'
    ];



    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [terminationDate, setTerminationDate] = useState('');
    const [created_at, setCreated_at] = useState('');
    const [litigation, setLitigation] = useState('');
    const [state, setState] = useState(0);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [sweetAlertError, setSweetAlertError] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [reload, setReload] = useState(false);

    const [data, setData] = useState([]);

    function clearForm() {
        // console.log('clearForm')
        setStartDate('')
        setEndDate('')
        setLitigation('')
        setTerminationDate('')
        setCreated_at('')
        setState(0)
        setData([])

        // let tmp=[]
        // for (let i = 0; i < arfields.length; i++) {
        //     tmp.push(arfields[i])
        // }
        // tmp[19]=0 //debug
        // setChkfields(tmp)


        // document.getElementById('startdate').value = new Date()
        // document.getElementById('enddate').value = new Date()
        // document.getElementById('litigation').value=''
    }

    function init() {
        // console.log('init',arfields.length,(Math.round((arfields.length-1)/2)));
        if(arfields !== undefined)
            new Array((Math.round((arfields.length-1)/2))).fill(true)
    }

    useEffect(() => {
       
        clearForm()
        init()

    }, [props]);

    

    function handleChangeDate(datetime, field) {
        // console.log('handleChangeDate', field, datetime, moment(datetime, possibleDateTimeFormats, true).isValid())

        if (moment(datetime, possibleDateTimeFormats, true).isValid()) {


            switch (field) {
                case 'startdate':
                    setStartDate(datetime)
                    setEndDate(datetime)
                    break;
                
                case 'enddate':
                    setEndDate(datetime)
                    break;

                case 'termination_date':
                    setTerminationDate(datetime)
                    break;
                case 'created_at':
                    setCreated_at(datetime)
                    break;
                default:
                    break;
            }

        }else {
             switch (field) {
                case 'startdate':
                    setStartDate('')
                    setEndDate('')
                    break;
                
                case 'enddate':
                    setEndDate('')
                    break;

                case 'termination_date':
                    setTerminationDate('')
                    break;
                case 'created_at':
                    setCreated_at('')
                    break;
                default:
                    break;
            }
        }

    }

    function handleChangeState(e) {
        setState(e.target.value);
    }

    
    async function getProcessData() {
        
        // console.log('getProcessData', startdate, enddate)
        // console.log('state', state)

        setReload(true)

        const formdata = new FormData();
        formdata.append('data_inicio', (startdate !== '') ? moment(startdate).format('DD-MM-YYYY') : null);
        formdata.append('data_fim', (enddate !== '') ? moment(enddate).format('DD-MM-YYYY') : null);
        formdata.append('litigation', litigation);
        formdata.append('termination_date', (terminationDate !== '') ? moment(terminationDate).format('DD-MM-YYYY') : null);
        formdata.append('created_at', (created_at !== '') ? moment(created_at).format('DD-MM-YYYY') : null);
        formdata.append('state', (state !== null) ? state: 1);
        // formdata.append('fields',chkfields)
        try {

            await api.post(configData.API + '/getData', formdata).then((response) => {
                // console.log('getProcessData request data', response.data.data);

                setData(response.data.data)


            }).catch((err) => {
                console.log(err);
            });


        } catch (error) {
            setSweatSucmsg();
            setSweetAlertError(!sweetAlertError);
            setErrorMessage(error);

        }

        setReload(false)

    }


    function handleChange(e) {
        // console.log('onChange',e.target.value)
        setLitigation(e.target.value);
        // if(temp_id !== 0) {
        //     // verifyprocess(e.target.name);
        // }

    }

    async function handleExport() {
        // console.log('handleExport');

        setReload(true)

        const formdata = new FormData();
        formdata.append('data_inicio', (startdate !== '') ? moment(startdate).format('DD-MM-YYYY') : null);
        formdata.append('data_fim', (enddate !== '') ? moment(enddate).format('DD-MM-YYYY') : null);
        formdata.append('litigation', litigation);
        formdata.append('termination_date', (terminationDate !== '') ? moment(terminationDate).format('DD-MM-YYYY') : null);
        formdata.append('state', (state !== null) ? state: 1);
        formdata.append('created_at', (created_at !== '') ? moment(created_at).format('DD-MM-YYYY') : null);
        formdata.append('fields',chkfields)

        // console.log('termination_date')
        try {

            await api.post(configData.API + '/export', formdata).then((response) => {
                // console.log('handleExport request data', response.data);

                setSweatSuccess(true);
                setSweatSucmsg('Exportação concluida com sucesso!');

                var file =  response.data.f;
                try {
                     
                     api.get(configData.API + '/downloadexp/'+file).then((response) => {
                        //  console.log('handleExport downloadexp request data', response.data);
                        let newWindow = window.open();
                        newWindow.location = configData.BASE_URL + '/api/downloadexp/'+file;
                     
                        setSweatSuccess(true);
                        setSweatSucmsg(response.data.message);
        
                        setTimeout(() => {
                            newWindow.close();
                            props.toggleModal()
                        }, 2000);
                    });
                   
                } catch (error) {
                    console.log(error);
                }




            }).catch((err) => {
                console.log(err);
            });


        } catch (error) {
            setSweatSucmsg();
            setSweetAlertError(!sweetAlertError);
            setErrorMessage(error);

        }

        setReload(false)
    }
    

    async function handleSubmit(e) {
        // console.log('handleSubmit');
        // e.preventDefault();
        await getProcessData()
       
    }

    function toggleModalLocal() {
        //  console.log('toggleModalLocal', toggleModalLocal)
        props.toggleModal()
    }

    
    const arfields = [
        'Cohabitação',
        'Modelo 6',
        'Equipamento Entregue na CVP',
        'Data de Ativação',
        'Data de Despacho de Cessação',
        'Data de Despacho',
        'Data de Nascimento',
        'Data de Prorrogação',
        'Desdobramento de Processo',
        'Duração',
        'Estado da Medida',
        'Focal Point: Nome',
        'Focal Point: Contato',
        'Medida de Coação',
        'Motivo de Cessação',
        'Número de Ordem',
        'Nº Processo Judicial',
        'Processo Judicial Relacionado',
        'Nome do/a Utente ',
        'Observações',
        'Prorrogação',
        'Sexo',
        '(Vitima) Localidade',
        'Termo Previsto da Cessação',
        'Tribunal',
        'Tribunal: Data de Notificação'
        
    ]
    
    // const [chkfields,setChkfields]=useState([])
    const [chkfields, setChkfields] = useState(
        new Array(arfields.length).fill(true)
    );
    var block=[]
    function fieldblock(f) {
        let tmp=[]

        let end = ((4*2 * f) >= arfields.length) ? arfields.length : (4*2 * (f-1)) + 4*2 
        for (let i = (4*2 * (f-1)); i < end; i++) {
            tmp.push(arfields[i])
            
        }
        block.push(tmp)
    }

    let lim=0
    for (let i = 1; i < arfields.length / 4; i++) {
        fieldblock(i)
        lim=i
    }
    if (arfields.length % 4 !==0) {
        fieldblock(lim+1)
    }

    // console.log('fieldblock', block)

    
    function handleChangeCheckbox(e,p) {
        // console.log('***handleChangeCheckbox ',e.target.name, p)
        var p = Number(e.target.name.split('_')[1])
        
        const updatedCheckedState = chkfields.map((item, index) =>
            index === p ? !item : item
            );
        setChkfields(updatedCheckedState)
  
        
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registos por Página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        // noRowsPerPage: 10
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const columns = [
        {
            name: 'Estado',
            selector: row => row.state,
            width: 10,
            sortable: true,
            center: true,
            // className: 'text-center'
        },
        {
            name: 'Número de Ordem',
            selector: row => row.num_order,
            width: 10,
            sortable: true,
            center: true,
            // className: 'text-center'
        },
        {
            name: 'Nome',
            selector: row => row.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Data de Nascimento',
            selector: row => row.birth_date,
            sortable: true,
            center: true,
        },
        {
            name: 'Data de Activação',
            selector: row => row.activation_date,
            sortable: true,
            center: true,
        },
        {
            name: 'Nº Processo Judicial',
            selector: row => row.litigation,
            sortable: true,
            center: true,
        },
        {
            name: 'Desdobramento de Processo',
            selector: row => row.litigationsequence,
            sortable: true,
            center: true,
        },
        {
            name: 'Criado Em',
            selector: row => row.createat,
            sortable: true,
            center: true,
        },
      
    ];

    var cnt=0
    return (
        <>
            <Modal isOpen={props.modalDialog} size='xl'toggle={() => props.toggleModal()}>
                <ModalHeader toggle={() => props.toggleModal()}>
                   Exportação de Processos
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <div className="col-md-12">
                            <legend className="no-border f-w-700 p-b-0 m-t-10 m-b-10 m-l-10 f-s-16 text-inverse">Filtros</legend>
                        </div>  
                    </div>
                    <div className="form-group row m-b-15">
                        <div className="col-md-2">
                        
                            <label className="col-form-label">Data de Ativação: Inicio</label>

                            <DatePicker locale="pt" dateFormat="dd-MM-yyyy"
                                className='form-control text-center m-b-5'
                                id="startdate"
                                name="startdate"
                                selected={startdate}
                                onChange={(date) => handleChangeDate(date, 'startdate') }
                                

                            />
                            
                            
                        </div>


                        <div className="col-md-2">
                            <label className="col-form-label">Data de Ativação: Fim</label>
                            <DatePicker locale="pt" dateFormat="dd-MM-yyyy"
                                className='form-control text-center m-b-5'
                                id="enddate"
                                name="enddate"
                                selected={enddate}
                                onChange={(date) => handleChangeDate(date, 'enddate')}


                            />
                            
                            
                        </div>

                        <div className="col-xl-2 ">
                            <label className="col-form-label">Nº Processo Judicial</label>
                            <input
                                id="litigation"
                                name="litigation"
                                type="text"
                                className={'form-control m-b-5'}
                                onChange={(e) => handleChange(e)}
                           
                            />
                            
                        </div>

                        <div className="col-md-2">
                            <label className="col-form-label">Data de Despacho</label>
                            <DatePicker locale="pt" dateFormat="dd-MM-yyyy"
                                className='form-control text-center m-b-5'
                                id="termination_date"
                                name="termination_date"
                                selected={terminationDate}
                                onChange={(date) => handleChangeDate(date, 'termination_date')}


                            />
                           
                        </div>

                        <div className="col-md-2">
                            <label className="col-form-label">Estado da Medida</label>
                            <StateList
                                state={state}
                                handleChangeState={(e) => handleChangeState(e)}
                                propexp={1}
                             />
                           
                        </div>

                        <div className="col-md-2">
                            <label className="col-form-label">Data de Criação</label>
                            <DatePicker locale="pt" dateFormat="dd-MM-yyyy"
                                className='form-control text-center m-b-5'
                                id="created_at"
                                name="created_at"
                                selected={created_at}
                                onChange={(date) => handleChangeDate(date, 'created_at')}


                            />
                           
                        </div>
                        

                    </div>
                    <div className="form-group row m-b-15">
                        <div className="col-md-12">
                            <hr />
                            <legend className="no-border f-w-700 p-b-0 m-t-10 m-b-10 m-l-10 f-s-16 text-inverse">Campos a exportar</legend>
                                
                                {
                                   
                                block.map((elblock, i) => {
                                        {/* console.log('block', elblock,i) */}
                                        
                                        return (
                                        
                                            <div className='row'>
                                            {
                                                elblock.map( (el,l) => (
                                                    (el !== undefined) &&(
                                                        
                                                        <>
                                                            <div key={i+l} className="form-group m-b-15 col-xl-4 ">
                                                                <input
                                                                    key={cnt+l}
                                                                    name={`checkbox_${cnt}`}
                                                                    type="checkbox"
                                                                    className='checkbox'
                                                                    id={`checkbox_${cnt}`}
                                                                    checked={chkfields[cnt]}
                                                                    onChange={(e,cnt) => handleChangeCheckbox(e,cnt)}
                                                                /><label className={`labelcheckbox ${cnt}`} htmlFor={`checkbox_${cnt++}`}>{el}</label>

                                                            </div>
                                                        </>)
                                                    )
                                                    
                                                )
                                                
                                            
                                            }
                                            </div>
                                        )
                                        }
                                    )
                                }


                            <div className="m-t-30 col-xl-10 ">
                                {/* <button type="button" onClick={() => clearForm()} className="btn btn-inverse m-r-10">Limpar Filtros</button> */}
                                <button type="button" onClick={() => handleSubmit()} className="btn btn-inverse" style={{ 'float': 'right' }}>Gerar</button>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row m-b-15">
                        <div className="col-md-12">
                        
                            <DataTable
                                keys="id"
                                columns={columns}
                                data={data}
                                striped
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                noDataComponent={"Sem Registos"}
                            />   
                        </div>
                    </div>
                    {
                        (reload &&
                            <div className="panel-loader">
                                <span className="spinner-small"></span>
                            </div>
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    {/* <button type="button" className="btn btn-default btn-inverse mr-auto" onClick={() => props.toggleModal(0)}>Fechar</button> */}
                    <button type="button" onClick={() => handleExport()} className="btn btn-success">Exportar</button>
                </ModalFooter>

            </Modal>
            {
                (reload &&
                    <div className="panel-loader">
                        <span className="spinner-small"></span>
                    </div>
                )
            }

            {

                (sweatSuccess &&
                    <SweetAlert success

                        title="Sucesso!"
                        confirmBtnBsStyle="success"
                        onConfirm={() => setSweatSuccess(!sweatSuccess)}
                    >
                        <span id="spanmsg">{SweatSucmsg}</span>
                    </SweetAlert>
                )

            }

            {
                (sweetAlertError &&
                    <SweetAlert danger
                        confirmBtnText="OK"
                        confirmBtnBsStyle="default"
                        // title={translations['pt']['alerts']['error']['title']}
                        title="Erro"
                        onConfirm={() => setSweetAlertError(false)}
                    >
                        {errorMessage}
                    </SweetAlert>
                )
            }
        </>
    )
}