import React, { Component } from 'react';

export default class FilesUploadComponent extends Component {

    constructor(props) {
        super(props);

        
        // this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.table = this.table.bind(this);

        this.state = {
            files: this.props.files,
            disabled: this.props.disabled
        }

        // console.log('comp upload', this.state.files)
    }

   

    onSubmit(e) {
        e.preventDefault()

     
    }
        

    render() {
        return (
            <div className="container">
        
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group float-left">
                        
                        <input type="file" name="process_files" id="process_files" multiple className={this.props.className}
                            onChange={this.props.onFileChange}
                        
                            />
                            
                        </div>
                        
                    </div>
                </div>
                    
                    
               
            </div>
        )
    }
}