import React, { Component } from "react";

class CohabitationList extends Component {
    constructor(props) {
        super(props);
        // this.handleChangeCohabitation = this.handleChangeCohabitation.bind(this);
        
        this.state = {
            // 'measure_coercion': this.props.measure_coercion,  //nao muda o estado
            'disabledcohabitation': (this.props.disable === 'false') ? false : true,

        };

    }


    render() {

        let opts = ['Não se Aplica', 'Não', 'Sim'];
        /*
        1:nao;2:sim;0: N/A
        */
        // let i = 0;
        const options = opts.map((opt,index) =>
            <option key={index} value={opt} >{opt}</option>
        );

        const stclassName = `form-group col-xl-${this.props.cols}`;

        return (
            <div className={stclassName}>
                <label>Está em Coabitação? *</label>
                <select
                    id="cohabitation"
                    name='cohabitation'
                    className={(this.props.errors) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                    disabled={this.props.disabled}
                    value={this.props.cohabitation}
                    onChange={this.props.handleChangeCohabitation}
                >
                    {/* <option value="">Selecione um Distrito</option> */}
                    {options}
                </select>
                {
                    (this.props.errors) && (
                        <span className="is-invalid m-t-20">{this.props.errors[0]}</span>
                    )
                }
            </div>
        )
    }


}

export default CohabitationList;