import React, { useEffect, useState } from 'react';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'react-datepicker/dist/react-datepicker.css';


export default function Dashboard(props) {

    // var translation = language();
    // console.log('dashboard translation', translation)

    const [reload, setReload] = useState(false);

    const today = new Date();
    // const firstDayWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1).toLocaleDateString();
    // const lastDayWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7).toLocaleDateString();
    const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1).toLocaleDateString();
    const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toLocaleDateString();
    const [dateRange, setDateRange] = useState({ startDate: firstDayMonth, endDate: lastDayMonth });

    const [classpie, setClasspie] = useState('col-md-6 offset-3');

    
    const translations = language();
    // console.log('dashboard translations', translations)

    const [barChart, setBarChart] = useState({
        options: {
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: 0,
                style: {
                    fontSize: '12px',
                    colors: ['#ffffff']
                }
            },
            // colors: ['#0099ff', '#ff0000'],
            colors: ['#0099ff'],
            stroke: {
                show: true,
                width: 1,
                colors: ['#ffffff']
            },
            title: {
                text: translations.dashboard.charts.processes_barchart_quarter.title,
                align: 'center'
            },
            grid: {
                row: {
                    colors: ['rgba(182, 194, 201, 0.1)', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [],
                axisBorder: {
                    show: true,
                    color: 'rgba(182, 194, 201, 0.5)',
                    height: 1,
                    width: '100%',
                    offsetX: 0,
                    offsetY: -1
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#b6c2c9',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                }
            },
            // yaxis: {
            // 	labels: {
            // 		formatter: function(val, index) {
            // 			return val.toFixed(0);
            // 		}
            // 	}
            // },
            legend: {
                show: true,
                position: 'top',
                offsetY: -10,
                horizontalAlign: 'right',
                floating: true,
            }
        },
        series: []
    });
    const [barChartAnual, setBarChartAnual] = useState({
        options: {
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: 0,
                style: {
                    fontSize: '12px',
                    colors: ['#ffffff']
                }
            },
            // colors: ['#0099ff', '#ff0000'],
            // colors: ['#0099ff'],
            
            colors: ['#ee7578'],
            stroke: {
                show: true,
                width: 1,
                colors: ['#ffffff']
            },
            title: {
                text: translations.dashboard.charts.processes_barchart_anual.title,
                align: 'center'
            },
            grid: {
                row: {
                    colors: ['rgba(182, 194, 201, 0.1)', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [],
                axisBorder: {
                    show: true,
                    color: 'rgba(182, 194, 201, 0.5)',
                    height: 1,
                    width: '100%',
                    offsetX: 0,
                    offsetY: -1
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#b6c2c9',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                }
            },
            // yaxis: {
            // 	labels: {
            // 		formatter: function(val, index) {
            // 			return val.toFixed(0);
            // 		}
            // 	}
            // },
            legend: {
                show: true,
                position: 'top',
                offsetY: -10,
                horizontalAlign: 'right',
                floating: true,
            }
        },
        series: []
    });

    const [pieChartDistrict, setPieChartDistrict] = useState({
        options: {
            chart: {
                height: 150,
                type: 'pie',
            },
            legend: {
                position: 'bottom'
            },
            dataLabels: {
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    opacity: 0.45
                }
            },
            plotOptions: {
                pie: {
                   customScale: 1
                }
            },
            labels: [],
            title: {
                text: translations.dashboard.charts.processes_district.title
            }
        },
        series: []
    });

    const [pieChartCounty, setPieChartCounty] = useState({
        options: {
            chart: {
                height: 150,
                type: 'pie',
            },
            legend: {
                position: 'bottom'
            },
            dataLabels: {
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    opacity: 0.45
                }
            },
            plotOptions: {
                pie: {
                    customScale: 1
                }
            },

            labels: [],
            title: {
                text: translations.dashboard.charts.processes_county.title
                
            }
        },
        series: []
    });

    const [pieChartAcompanhamento, setPieChartAcompanhamento] = useState({
        options: {
            chart: {
                height: 150,
                type: 'pie',
                width: '100%',
                zoom: {
                    enabled: true,
                    type: 'x',  
                    autoScaleYaxis: false,
                }
            },
            legend: {
                position: 'bottom'
            },
            dataLabels: {
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    opacity: 0.45
                },
                // formatter: function (val, opts) {
                //     console.log('formatter', val, opts) 
                //     return val
                // }, 
                // formatter: function (val,seriesIndex,w) {
                //     console.log('formatter',val, seriesIndex,seriesIndex.w)
                //     // return w.config.series[seriesIndex] 
                //     // return seriesIndex.w.config.series[seriesIndex]
                //     return val
                // },
            },
            plotOptions: {
                pie: {
                    customScale: 1
                }
            },

            labels: [],
            title: {
                text: translations.dashboard.charts.processes_acompanhamento.title

            }
        },
        series: []
    });


    const pickerOptions = {
        format: "DD/MM/YYYY",
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        daysOfWeek: [translations.dates.daysOfWeek],
        monthNames: [translations.dates.months]
    
    }
    
    const [allprocesses, setAllProcesses] = useState([]);
    const [applied_measures, setAppliedMeasures] = useState([]);
    const [with_extension, setWithExtension] = useState([]);
    const [applied_measures_list, setAppliedMeasuresList] = useState([]);
    // const [measures_transit_year, setMeasuresTransitYear] = useState({});
    const [measures_transit_year_list, setMeasuresTransitYearList] = useState([]);
    const [active_measures, setActiveMeasures] = useState([]);
    // const [active_measures_list, setActiveMeasuresList] = useState({});

    function iniciate(dates) {
        // getdataCharts({ startDate: firstDayMonth, endDate: lastDayMonth });

            getdata_medidasaplicadas(dates);
            getdata_medidasaplicadastransitamano(dates);
            getdata_medidasaporano(dates);
            getdata_medidasaporquadrimestre(dates);
            getdata_piecharts(dates);
            getdata_allcount(dates);
            
    }

    useEffect(() => {
        // console.log('props useeffect', props)
        iniciate({ startDate: firstDayMonth, endDate: lastDayMonth });
            
     
    }, []);

    async function getdata_allcount(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        await api.get(configData.API + '/chartsdata', {
                params: {
                    n:0,
                    startDate: daterange.startDate,
                    endDate: daterange.endDate,
                    
                    }
            }).then((response) => {

                setAllProcesses(response.data.processes);
                setWithExtension(response.data.processes_extension)
                
            }).catch((err) => {
            console.log(err);
        });
        setReload(false);
            
    }
    async function getdata_medidasaplicadas(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        await api.get(configData.API + '/chartsdata', {
                params: {
                    n:1,
                    startDate: daterange.startDate,
                    endDate: daterange.endDate,
                    
                    }
            }).then((response) => {
                // console.log('getdata_medidasaplicadas response', response)
                setAppliedMeasures(response.data.applied_measures)
                setAppliedMeasuresList(response.data.applied_measures.list_applied_date)
                
            }).catch((err) => {
            console.log(err);
        });
        setReload(false);
            
    }
    async function getdata_medidasaplicadastransitamano(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        await api.get(configData.API + '/chartsdata', {
                params: {
                    n:2,
                    startDate: daterange.startDate,
                    endDate: daterange.endDate,
                    
                    }
            }).then((response) => {

                // setMeasuresTransitYear(response.data.measures_transit_year)
                setMeasuresTransitYearList(response.data.measures_transit_year.list)
                
            }).catch((err) => {
            console.log(err);
        });
        setReload(false);
            
    }
    async function getdata_medidasaporano(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        await api.get(configData.API + '/chartsdata', {
                params: {
                    n:3,
                    startDate: daterange.startDate,
                    endDate: daterange.endDate,
                    
                    }
            }).then((response) => {
                setActiveMeasures(response.data.active_measures)

                setBarChartAnual(prevState => ({
                    options: {
                        ...prevState.options,
                        xaxis: {
                            ...prevState.options.xaxis,
                            categories: response.data.barchart_anual.data.categories
                        }
                    },
                    series: [{
                        data: response.data.barchart_anual.data.series.data,
                        name: response.data.barchart_anual.data.series.name
                    }]

                }));
                
                
            }).catch((err) => {
            console.log(err);
        });
        setReload(false);
            
    }
    async function getdata_piecharts(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        await api.get(configData.API + '/chartsdata', {
                params: {
                    n:5,
                    startDate: daterange.startDate,
                    endDate: daterange.endDate,
                    
                    }
            }).then((response) => {
                
               setPieChartDistrict(prevState => ({
                    options: {
                        ...prevState.options,
                        labels: response.data.piechart_district.labels
                    },
                    series: response.data.piechart_district.series
                }));

                console.log('\n\n series.length',response.data.piechart_county.series,response.data.piechart_county.series.length);
                if(response.data.piechart_county.series.length>20) {
                    setClasspie('col-md-12')
                }else {
                    if(response.data.piechart_county.series.length>10) {
                        setClasspie('col-md-8 offset-2')
                    }
                }

                setPieChartCounty(prevState => ({
                    options: {
                        ...prevState.options,
                        labels: response.data.piechart_county.labels
                    },
                    series: response.data.piechart_county.series
                }));

                setPieChartAcompanhamento(prevState => ({
                    options: {
                        ...prevState.options,
                        labels: response.data.piechart_acompanhamento.labels
                        // labels: response.data.piechart_acompanhamento.labels_custom
                    },
                    series: response.data.piechart_acompanhamento.series
                }));
                
                
            }).catch((err) => {
            console.log(err);
        });
        setReload(false);
            
    }
    async function getdata_medidasaporquadrimestre(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        await api.get(configData.API + '/chartsdata', {
                params: {
                    n:4,
                    startDate: daterange.startDate,
                    endDate: daterange.endDate,
                    
                    }
            }).then((response) => {
                
                let title = translations.dashboard.charts.processes_barchart_quarter.title + response.data.barchart_quarter.year;
                // console.log('title', title)
                setBarChart(prevState => ({
					options: {
						...prevState.options,
                        title: {
                            text : title
                        },
						xaxis: {
							...prevState.options.xaxis,
                            categories: response.data.barchart_quarter.data.categories
						}
					},
                    series: [{
                        data: response.data.barchart_quarter.data.series.data,
                        name: response.data.barchart_quarter.data.series.name
                    }]
                    
				}));
                
                
            }).catch((err) => {
            console.log(err);
        });
        setReload(false);
            
    }


    function getdataCharts(daterange) {
        // console.log('getdataCharts', daterange)
        setReload(true);
        api.get(configData.API + '/chartsdata', {
                params: {
                    startDate: daterange.startDate,
                    endDate: daterange.endDate
                    }
            }).then((response) => {
                // console.log('response.data getdataCharts', response.data);
                // console.log('response.data getdataCharts', response.data.barchart_quarter);
            

                setPieChartDistrict(prevState => ({
                    options: {
                        ...prevState.options,
                        labels: response.data.piechart_district.labels
                    },
                    series: response.data.piechart_district.series
                }));

                setPieChartCounty(prevState => ({
                    options: {
                        ...prevState.options,
                        labels: response.data.piechart_county.labels
                    },
                    series: response.data.piechart_county.series
                }));

                setPieChartAcompanhamento(prevState => ({
                    options: {
                        ...prevState.options,
                        labels: response.data.piechart_acompanhamento.labels
                        // labels: response.data.piechart_acompanhamento.labels_custom
                    },
                    series: response.data.piechart_acompanhamento.series
                }));

                // console.log('response.data getdataCharts barchart_quarter', response.data.barchart_quarter);
                
                let title = translations.dashboard.charts.processes_barchart_quarter.title + response.data.barchart_quarter.year;
                // console.log('title', title)
                setBarChart(prevState => ({
					options: {
						...prevState.options,
                        title: {
                            text : title
                        },
						xaxis: {
							...prevState.options.xaxis,
                            categories: response.data.barchart_quarter.data.categories
						}
					},
                    series: [{
                        data: response.data.barchart_quarter.data.series.data,
                        name: response.data.barchart_quarter.data.series.name
                    }]
                    
				}));

                setBarChartAnual(prevState => ({
                    options: {
                        ...prevState.options,
                        xaxis: {
                            ...prevState.options.xaxis,
                            categories: response.data.barchart_anual.data.categories
                        }
                    },
                    series: [{
                        data: response.data.barchart_anual.data.series.data,
                        name: response.data.barchart_anual.data.series.name
                    }]

                }));
  

            setReload(false);
        }).catch((err) => {
            console.log(err);
        });

        
    }

    function clearFilter() {
        setDateRange({ startDate: firstDayMonth, endDate: lastDayMonth });
        getdataCharts({ startDate: firstDayMonth, endDate: lastDayMonth });
    }

    function handleFilterDateRange(event, picker) {
        // console.log('picker', picker)
        const dates = {
            startDate: picker.startDate._d.toLocaleDateString(),
            endDate: picker.endDate._d.toLocaleDateString()
        }

        // console.log('picker', dates)

        setDateRange(dates);
        iniciate(dates);
        
    }

    // function manageProcess(id) {
    //     console.log('manageProcess', id);

    //     // toggleModal(0);
    //     history.push('/app/view-process/' + id);

    // }

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const tableColumns = [
        // {
        //     name: 'Estado',
        //     selector: 'state',
        //     width: 10,
        //     sortable: true,
        //     center: true,
        //     // className: 'text-center'
        // },
        {
            name: translations['table_processes'].num_order,
            selector: 'num_order',
            sortable: true,
            center: true,
            wrap:true,
            width: 40,
            // cell: row => <center>
            //     {<a href="#" onClick="() => manageProcess(`${row.id}`)">row.num_order</a>}
            // </center>,
        },
        {
            name: translations['table_processes'].litigation,
            selector: 'litigation',
            sortable: true,
            center: true,
            wrap:true,
            width: 10
        },
        // {
        //     name: translations['table_processes'].birth_date,
        //     selector: 'birth_date',
        //     sortable: true,
        //     center: true,
        //     width: 10
        // },
        {
            name: translations['table_processes'].age,
            selector: 'age',
            sortable: true,
            center: true,
            width: 10
        },
        // {
        //     name: translations['table_processes'].gender,
        //     selector: 'gender',
        //     sortable: true,
        //     center: true,
        // },
        {
            name: translations['table_processes'].activation_date_month,
            selector: 'activation_date_month',
            sortable: true,
            center: true,
            width: 10
        },
        {
            name: translations['table_processes'].activation_date_year,
            selector: 'activation_date_year',
            sortable: true,
            center: true,
            width: 10
        },
        
        // {
        //     name: translations['table_processes'].created_at,
        //     selector: 'createat',
        //     sortable: true,
        //     center: true,
        //     width: 30
        // }
    ];   

    const tableColumnsWithTerminationDate = [
        // {
        //     name: 'Estado',
        //     selector: 'state',
        //     width: 10,
        //     sortable: true,
        //     center: true,
        //     // className: 'text-center'
        // },
        {
            name: translations['table_processes'].num_order,
            selector: 'num_order',
            sortable: true,
            center: true,
            wrap:true,
            width: 40,
            // cell: row => <center>
            //     {<a href="#" onClick="() => manageProcess(`${row.id}`)">row.num_order</a>}
            // </center>,
        },
        {
            name: translations['table_processes'].litigation,
            selector: 'litigation',
            sortable: true,
            center: true,
            wrap:true,
            width: 10
        },
        // {
        //     name: translations['table_processes'].birth_date,
        //     selector: 'birth_date',
        //     sortable: true,
        //     center: true,
        //     width: 10
        // },
        {
            name: translations['table_processes'].age,
            selector: 'age',
            sortable: true,
            center: true,
            width: 10
        },
        // {
        //     name: translations['table_processes'].gender,
        //     selector: 'gender',
        //     sortable: true,
        //     center: true,
        // },
        {
            name: translations['table_processes'].termination_date,
            selector: 'termination_date',
            sortable: true,
            center: true,
            width: 10
        },
        {
            name: translations['table_processes'].activation_date_month,
            selector: 'activation_date_month',
            sortable: true,
            center: true,
            width: 10
        },
        {
            name: translations['table_processes'].activation_date_year,
            selector: 'activation_date_year',
            sortable: true,
            center: true,
            width: 10
        },

        // {
        //     name: translations['table_processes'].created_at,
        //     selector: 'createat',
        //     sortable: true,
        //     center: true,
        //     width: 30
        // }
    ];

  
    return (
        
        <>
            <div>
                <h1 className="page-header">
                    {translations['dashboard']['title']}
                <small>
                {/* {translations['dashboard']['title-small']}  */}
                </small></h1>

                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="float-right m-b-20 m-r-20">
                            <DateRangePicker
                                locale={pickerOptions}
                                onApply={handleFilterDateRange}
                            >
                                <button className='btn btn-default btn-xm' ><i className="fa fa-calendar"></i>&nbsp;{`${dateRange.startDate} - ${dateRange.endDate}`}</button>
                           
                            </DateRangePicker>
                            {(dateRange.startDate !== firstDayMonth || dateRange.endDate !== lastDayMonth) && (
                                <button className='btn btn-default btn-xm' onClick={clearFilter}><i className="fa fa-times"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <div className="widget widget-stats widget">
                            <div className="stats-icon stats-icon-lg"><i className="fas fa-balance-scale-left"></i></div>
                            <div className="stats-content">
                                <div className="stats-title">
                                    {translations['dashboard']['widgets']['month_processes'].title}
                                </div>
                                <div className="stats-number">{allprocesses.number_processes}</div>
                                <div className="stats-progress progress">
                                    <div className="progress-bar" style={{ width: allprocesses.perc+'%' }}></div>
                                </div>
                                <div className="stats-desc">{translations['dashboard']['widgets']['month_processes'].description} {allprocesses.all_processes}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="widget widget-stats">
                            <div className="stats-icon stats-icon-lg"><i className="fas fa-university"></i></div>
                            <div className="stats-content">
                                <div className="stats-title">{translations['dashboard']['widgets']['applied_measures'].title}
                                
                                </div>
                                <div className="stats-number">
                                    {applied_measures.perc_applied_date} %
                                    </div>
                                <div className="stats-progress progress">
                                    <div className="progress-bar" style={{ width: applied_measures.perc_applied_date + '%' }}>

                                    </div>
                                </div>
                                <div className="stats-desc">{translations['dashboard']['widgets']['applied_measures'].description} {applied_measures.total_applied_date}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="widget widget-stats">
                            <div className="stats-icon stats-icon-lg"><i className="far fa-clock"></i></div>
                            <div className="stats-content">
                                <div className="stats-title">{translations['dashboard']['widgets']['with_extension'].title}
                                </div>
                                <div className="stats-number">{with_extension.perc_extended} %</div>
                                <div className="stats-progress progress">
                                    <div className="progress-bar" style={{ width: with_extension.perc_extended + '%' }}></div>
                                </div>
                                <div className="stats-desc">{translations['dashboard']['widgets']['with_extension'].description} {with_extension.total}
                                </div>
                            </div>
                        </div>
                    </div>
                  

                </div>
            </div>
            <Panel>
                
                <PanelBody>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='panel panel-inverse'>
                                <div className='panel-body'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Chart type="pie" options={pieChartDistrict.options} series={pieChartDistrict.series} />
                                            {(reload &&
                                                    <div className="panel-loader">
                                                        <span className="spinner-small"></span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                        <div className="col-md-6">
                            <div className='panel panel-inverse'>
                                <div className='panel-body'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            
                                            <Chart type="pie" options={pieChartAcompanhamento.options} series={pieChartAcompanhamento.series} />
                                            {(reload &&
                                                    <div className="panel-loader">
                                                        <span className="spinner-small"></span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className='panel panel-inverse'>
                                <div className='panel-body'>
                                    <div className="row">
                                        <div className={classpie}>
                                            <Chart type="pie" options={pieChartCounty.options} series={pieChartCounty.series} />
                                            {(reload &&
                                                    <div className="panel-loader">
                                                        <span className="spinner-small"></span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </PanelBody>
            </Panel>

            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='panel panel-inverse'>
                                <div className='panel-body'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-12"><p style={{fontWeight: "bold",fontSize: "14px"}}> 
                                                       {translations['dashboard']['panel']['table_applied_measures'].title}</p>
                                                <DataTable
                                                    keys="id"
                                                    pagination
                                                    columns={tableColumns}
                                                    data={applied_measures_list}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    noDataComponent={"Sem Registos"}
                                                />
                                                {/* <ReactTable data={} columns={tableColumnsApplied} defaultPageSize={6} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} /> */}
                                            {(reload && 
                                                <div className="panel-loader">
                                                    <span className="spinner-small"></span>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className='panel panel-inverse'>
                                <div className='panel-body'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-12"><p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                             
                                                {translations['dashboard']['panel']['table_measures_transit_year'].title}</p>
                                                <DataTable
                                                    keys="id"
                                                    pagination
                                                    columns={tableColumnsWithTerminationDate}
                                                    data={measures_transit_year_list}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    noDataComponent={"Sem Registos"}
                                                />
                                               
                                                {(reload &&
                                                    <div className="panel-loader">
                                                        <span className="spinner-small"></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </PanelBody>
            </Panel>

            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-md-6">
                            <Chart type="bar" options={barChartAnual.options} series={barChartAnual.series} />

                        </div>

                        <div className="col-md-6">
                            <Chart type="bar" options={barChart.options} series={barChart.series} />
                                
                        </div>

                    </div>
                </PanelBody>
            </Panel>
        </>

    )


}