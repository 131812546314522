import React, { Component } from "react";
import api from '../../../config/api';
import configData from '../../../config/config.js';

class FocalPointList extends Component {
    constructor(props) {
        super(props);
        // this.handleChangeFocalPoint = this.handleChangeFocalPoint.bind(this);
        this.getFocalPointlist = this.getFocalPointlist.bind(this);
        

        this.state = {
            'focal_point': null,
            'focalPointlist': {},
            'disabledfocalpoint': (this.props.disable === 'false') ? false : true,

        };

        // if (this.props.error)
        //      console.log('erros', this.state.error.data.message);

    }

    componentDidMount() {
        this.getFocalPointlist();

        // if (this.state.error)
            //  console.log('erros', this.state.error);

    }

    getFocalPointlist() {
        //LISTS
        api.get(configData.API + '/focalpointlist').then((request) => {
            // console.log('request.data focalpointlist', request.data.data);
            this.setState({
                focalPointlist: request.data.data
            });
            

        }).catch((err) => {
            console.log(err);
        });
    }


    // handleChangeFocalPoint(e) {

    //     this.setState({
    //         focal_point: e.target.value,
    //     });


    // }

    render() {

        // const options = Object.keys(this.state.focalPointlist).map((key) =>
        //     <option key={this.state.focalPointlist[key].id} value={this.state.focalPointlist[key].id} >{`${this.state.focalPointlist[key].tipification} | ${this.state.focalPointlist[key].email}`}</option>
        // );
        const options = Object.keys(this.state.focalPointlist).map((key) =>
            <option key={this.state.focalPointlist[key].id} value={this.state.focalPointlist[key].id} email={this.state.focalPointlist[key].email}>{`${this.state.focalPointlist[key].name}`}</option>
        );

        const stclassName = `form-group col-xl-${this.props.cols}`;

        return (
            <div className={stclassName}>
                <label>Focal Point: Nome *</label>
                <select
                    id="focal_point_id"
                    name='focal_point_id'
                    className={(this.props.errors) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                    disabled={this.props.disabled}
                    value={(this.props.focal_point_id !== null) ? this.props.focal_point_id : 0}
                    onChange={this.props.handleChangeFocalPoint}
                    
                >
                    <option value="">Selecione o Focal Point</option>
                    {options}
                </select>
                {
                    (this.props.errors) && (
                        <span className="is-invalid m-t-20">{this.props.errors[0]}</span>
                    )
                }
                
            </div>
        )
    }


}

export default FocalPointList;