import React, { Component } from "react";
import api from '../../../config/api';
import configData from '../../../config/config.js';

class GenderList extends Component {
    constructor(props) {
        super(props);
        // this.handleChangeGender = this.handleChangeGender.bind(this);
        this.getGenderlist = this.getGenderlist.bind(this);
        // this.verifyProcess = this.verifyProcess.bind(this);

        this.state = {
 
            'genderlist': {},
            
        };

        // console.log('props gender',props)
    }

    componentDidMount() {
        this.getGenderlist();

    }

    getGenderlist() {
        //LISTS
        api.get(configData.API + '/genderlist').then((request) => {
            // console.log('request.data genderlist', request.data.data);
            this.setState({
                genderlist: request.data.data
            });

        }).catch((err) => {
            console.log(err);
        });
    }


    render() {
        // console.log('handleChangedistrict OUT', this.state.district);
        // console.log('handleChangeCounty OUT', this.state.county);

        const options = Object.keys(this.state.genderlist).map((key) =>
            <option key={this.state.genderlist[key].id} value={this.state.genderlist[key].id} > {this.state.genderlist[key].description}</option>
        );

        return (
                <>
                    <label>Sexo * </label>
                    <select
                        id='gender_id'
                        name='gender_id'
                        className="form-control m-b-5"
                        disabled={this.props.disabled}
                        // value={(this.state.gender_value) }
                        value={this.props.gender}
                        onChange={this.props.handleChangeGender}
                        // ref={this.props.refGender}
                        onBlur={this.props.handleBlur}
                        // selectedgendervalue={this.state.selectedgendervalue}
                    >   
                        <option value="">Selecione um Género</option>
                        {options}
                    </select>
                </>
            )
    }


}

export default GenderList;