import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router'

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component-with-filter';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function FocalPoints() {
    const history = useHistory();
    const translations = language();

    const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    // const [modalDialogFile, setModalDialogFile] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [id, setId] = useState(0);
    const [tipification, setTipification] = useState(0);
    const [focalpoints, setFocalpoints] = useState([]);
    const [focalpoint, setFocalpoint] = useState([]);

    async function getFocalPoint(id) {
        // console.log('getFocalPoint', id)
        try {
            await api.get(configData.API + '/focalpoint/' + id).then((response) => {
                // console.log('focalpoint request.data', response.data);

                setFocalpoint(response.data);
                document.getElementById('name').value = response.data.data.name;
                document.getElementById('email').value = response.data.data.email;
                document.getElementById('tipification').value = response.data.data.tipification;
                

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }

    async function toggleModal(id) {
        await setModalDialog(!modalDialog);
        setErrors('');

        if (id !== 0) {
            getFocalPoint(id);
            setId(id);
        } else {
            // let tmp = {
            //     'description': '',
            //     'active': 1
            // }
            // setFocalpoint(tmp)
            setId(0);
        }
    }

    function getData() {
        // console.log('getData')
        setReload(true);
        try {
            api.get(configData.API + '/getfocalpoints').then((response) => {
                // console.log('import request.data', response.data);
                setFocalpoints(response.data.data);


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        setReload(false);

    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    async function handleNew(e) {
        // console.log("handleNew")
        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);

        await api.post(configData.API + '/focalpoint', formContent).then((response) => {
            // console.log('response', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 3000);


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }

        });
        setReload(false);
    }

    async function handleEdit(e) {
        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);
        await api.post(configData.API + '/focalpoint/' + id, formContent).then((response) => {
            // console.log('response update', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 3000);

         


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }



        });
        setReload(false);
    }

    const [deleter, setDeleteR] = useState(0); 
    async function handleDelete(row) {
        try {
            await api.post(configData.API + '/deletefocalpoint/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);
                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(err.message);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    function handleChange(e) {
        // console.log('handleChange', e.target.value, e.target.checked)
        let temp = focalpoint;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setFocalpoint(temp);
        // console.log('after temp', temp)

    }

    function handleChangeCombo(e) {
        // console.log('handleChange', e.target.value, e.target.checked)
        let temp = focalpoint;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setFocalpoint(temp);
        // console.log('after temp', temp)
        setTipification(e.target.value)

    }

    async function iniciate() {
        await getData();
    }

    useEffect(() => {
        iniciate();
    }, []);

    
    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };

    const tableColumns = [
        {
            name: translations['backoffice']['focalpoints']['table'].active,
            selector: 'str_active',
            sortable: true,
            center: true,
            // width: 100
        },
        {
            name: translations['backoffice']['focalpoints']['table'].name,
            selector: 'name',
            sortable: true,
            center: true,
            width: 150
        },
        {
            name: translations['backoffice']['focalpoints']['table'].tipification,
            selector: 'tipification',
            sortable: true,
            center: true,
            // width: 100
        },
        {
            name: translations['backoffice']['focalpoints']['table'].email,
            selector: 'email',
            sortable: true,
            center: true,
            // width: 100
        },
        {
            name: translations['backoffice']['focalpoints']['table'].created_at,
            selector: 'created_at',
            sortable: true,
            center: true,
            // width: 30
        },
        {
            name: 'Opções',
            width: 10,
            cell: row => <center>
                {renderOptions(row)}
            </center>            
        }
    ];

    function renderOptions(row) {
        let show = true;
        if (row.id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={() => toggleModal(`${row.id}`)}>
                                        {translations['backoffice']['focalpoints']['table-buttons'].edit}
                                    </div>
                                </DropdownItem>
                                <DropdownItem as="button">
                                    <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {(row.active === 1) ? translations['backoffice']['focalpoints']['table-buttons'].delete : translations['backoffice']['focalpoints']['table-buttons'].active}
                                      
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }

    let opts = ['GNR', 'PSP'];
    const options = opts.map((opt,index) =>
        <option key={opt} value={opt} >{opt}</option>
    );


    return (
        <>
            <h1 className="page-header">{translations['backoffice'].title}
                <small>&nbsp;{translations['backoffice']['focalpoints'].title}</small>
            </h1>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">{translations['backoffice']['focalpoints']['panel'].title}</h4>
                    <div className="panel-heading-btn">
                        <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
                    </div>
                  
                </div>
                <PanelBody>
                    <DataTable
                        keys="id"
                        pagination
                        columns={tableColumns}
                        data={focalpoints}
                        paginationComponentOptions={paginationComponentOptions}
                        noDataComponent={"Sem Registos"}
                        customStyles={customStyles}
                    />
                   
                    {(reload &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                </PanelBody>
            </Panel>

            <Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
                <ModalHeader toggle={() => toggleModal(0)}>
                    {
                        id === 0 ?
                            translations['backoffice']['focalpoints']['modal']['header']['add'] :
                            translations['backoffice']['focalpoints']['modal']['header']['edit']
                    }
                </ModalHeader>
                <form onSubmit={id === 0 ? handleNew : handleEdit} ref={form}>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">{translations['backoffice']['focalpoints']['modal']['body']['name'].title}</label>
                            <div className="col-md-9">
                                <input type="text" id="name" name="focalpoint_name" 
                                    className={(errors.focalpoint_name) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['focalpoints']['modal']['body']['name'].placeholder}
                                    defaultValue={focalpoint.name}
                                onChange={(e) => handleChange(e)} />
                                {errors.focalpoint_name &&
                                    <span className="is-invalid">{errors.focalpoint_name[0]}</span>
                                }

                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">{translations['backoffice']['focalpoints']['modal']['body']['tipification'].title}</label>
                            <div className="col-md-9">
                                <select
                                    id="tipification"
                                    name='tipification'
                                    className={'form-control m-b-5'}
                                    value={tipification}
                                    onChange={handleChangeCombo}
                                >
                                    {options}
                                </select>

                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">{translations['backoffice']['focalpoints']['modal']['body']['email'].title}</label>
                            <div className="col-md-9">
                                <input type="email" id="email" name="focalpoint_email"
                                className={(errors.email) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['focalpoints']['modal']['body']['email'].placeholder}
                                defaultValue={focalpoint.email}
                                onChange={(e) => handleChange(e)} />
                                {errors.email &&
                                    <span className="is-invalid">{errors.email[0]}</span>
                                }
                            </div>
                        </div>
                        
                        
                        
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations['backoffice']['focalpoints']['modal']['footer'].close}</button>
                        <button type="submit" className="btn btn-primary">{translations['backoffice']['focalpoints']['modal']['footer'].save}</button>
                    </ModalFooter>
                </form>
            </Modal>
            {(sweatSuccess &&
                <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
            )}
            {(alertWarning &&
                <SweetAlert warning showCancel
                    confirmBtnText={translations['alerts']['warning'].confirmBtn}
                    cancelBtnText={translations['alerts']['warning'].cancelBtn}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={translations['alerts']['warning'].title}
                    onConfirm={() => { handleDelete(deleter); setAlertWarning(false) }}
                    onCancel={() => setAlertWarning(false)}
                >
                    {translations['backoffice']['focalpoints']['alert']['warning'].message}
                </SweetAlert>
            )}
            {(SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                {SweatErrmsg}
                </SweetAlert>
            )}
        </>
    )
}