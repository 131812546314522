import React from 'react';
import api from '../../config/api';
import SweetAlert from 'react-bootstrap-sweetalert';

const initialstate = {
	userSession: true,
	SweatLogout: false,
	SweatSucmsg: '',
	SweatError: false,
	SweatErrmsg: ''
};


class Logout extends React.Component {
	// static contextType = PageSettings;

	state = initialstate;
	constructor(props) {
		super(props);
		
		this.onLogout = this.onLogout.bind(this);
		// this.configData = configData;
		// this.prepareFormData= this.prepareFormData;

	}
  
	componentDidMount() {
		// this.context.handleSetPageSidebar(false);
		// this.context.handleSetPageHeader(false);
		// this.context.handleSetBodyWhiteBg(true);

		this.onLogout();
	}

	// componentWillUnmount() {
	// 	this.context.handleSetPageSidebar(true);
	// 	this.context.handleSetPageHeader(true);
	// 	this.context.handleSetBodyWhiteBg(false);
	// }
	
	// onChangeField = (event) => {
    //     const valor = event.target.value;
    //     const nomeCampo = event.target.name;
	// 	var fields = this.state.formfield;

	// 	fields[nomeCampo] = valor;
	// 	this.setState({formfield:fields});
    // }



	onLogout = (e) => {
		
		e.preventDefault();

		const formdata = {
			id:localStorage.getItem('id')
		}

		api.post('/logout', formdata).then((request, response) => {

			// console.log('logout request, response', request, response);

			localStorage.clear('access_token');
			localStorage.clear('token_type');
			localStorage.clear('expires_at');
			// localStorage.clear('name');
			// localStorage.clear('function');

			this.setState({
				userSession: false,
				SweatLogout: true,
				SweatSucmsg: "User Logout!"
			});


			// this.props.history.push('/');
			this.props.history.push(request.data.route);
		}).catch((err) => {
			console.log('error logout',err);
			// if (err.request.status === 401) {
			// 	this.setState({
			// 		SweatError: !this.state.SweatError,
			// 		SweatErrmsg: "Unauthorized User!",
					
			// 	});
			// }
		});

		
	}


	render() {

		return (
			
			<>
				Log Out
				{(this.state.SweatLogout &&
					<SweetAlert success
						title="Sucesso!"
						confirmBtnBsStyle="success"
						onConfirm={() => this.setState({ SweatLogin: !this.state.SweatLogin })}
					>
						{this.state.SweatSucmsg}
					</SweetAlert>
				)}

				{(this.state.SweatError &&
					<SweetAlert danger
						title="Erro!"
						confirmBtnBsStyle="danger"
						onConfirm={() => this.setState({ SweatError: !this.state.SweatError })}
					>
						{this.state.SweatErrmsg}
					</SweetAlert>
				)}
			</>
			
		)
	}
};

// export default withRouter(Logout);
export default Logout;