import React, { useEffect, useState } from 'react';
import {Panel, PanelBody} from '../../../components/panel/panel';

import language from '../utils/language';
import api from '../../../config/api';
import configData from '../../../config/config.js';
// import DataTable from 'react-data-table-component';
import DataTable from 'react-data-table-component-with-filter';

import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ModalExport from '../components/ModalExport';

import SweetAlert from 'react-bootstrap-sweetalert';


function ListProcesses() {
    const history = useHistory();

    const [data, setData] = useState([]);
    // const [dataFiltered, setDataFiltered] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [cbofield, setCbofield] = useState([]);
    const [searchfields, setSearchfields] = useState([
        'Nome',
        'Nº Processo Judicial',
        // 'Notifição Tribunal',
        // 'Notifição Procurador',
    ]);
    const [reload, setReload] = useState(false);

    const translations = language();

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [sweatWarning, setSweatWarning] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');

    const CallData = async (n) => {

        // if(n===200) 
        setReload(true)
        await api.get(configData.API + '/processlist/'+n).then((response) => {
            // console.log('rquest data', request.data.data);

            setData(response.data.data);
            // setDataFiltered(request.data.data);
             

        }).catch((err) => {
            console.log(err);
        });
        setReload(false)
    }

    async function iniciate() {
        // await CallData(200)
        await CallData(500)
        // await CallData(0)



    }

    useEffect(() => {
        // console.log('useeffect', litigation);

        iniciate()



    }, []);
    
 
    // console.log('Data after request', data);


    // FUNC PROCESS
    function manageProcess(id) {
        // console.log('manageProcess',id);

        // toggleModal(0);
        history.push('/app/view-process/'+id);

    }

    function newProcess() {
        // console.log('newProcess');
        history.push('/app/new-process');
    }

    const [iddel, setIddel] = useState(0);
    function confirmEliminateProcess(id) {
        setSweatSucmsg(`Deseja realmente eliminar o processo?`)
		setSweatWarning(true)    
        setIddel(id)    
    }
    
    async function eliminateProcess() {
        //console.log('eliminateProcess', iddel);

        await api.delete(configData.API + '/process/'+iddel).then((response) => {
       
            setSweatSucmsg(response.data.message);
            setSweatSuccess(true);
            history.go(response.data.route);
         
            // setTimeout(() => {
            // }, 1000);

        }).catch((err) => {
            console.log(err);
            setSweatError(!SweatError);
            setSweatSucmsg(err);

        });
    }

    const [modalDialog, setModalDialog] = useState(false);
    
    function toggleModal(id, modal) {
        // console.log('f toggleModal', id, modal)
        setModalDialog(!modalDialog);
    }

    async function exportProcess() {
        toggleModal()
    }

    function handleChange(e,field) {
        switch (field) {
            case 'cbofield':
                setCbofield(e.target.value)
                break;
        
            case 'search':
                setDataSearch(e.target.value)
                break;
        
            default:
                break;
        }
    }

    async function handleSubmit() {
        
        // var data={
        //     a: dataSearch
        // }

        setReload(true)

        const formdata = new FormData();
        formdata.append('a', dataSearch);
        await api.post(configData.API + '/search',formdata).then((response) => {
            //console.log('response.data process', response.data.data);
            setData(response.data.data);

            

        }).catch((err) => {
            console.log('erro POST SEARCH', err);
            setSweatSucmsg();
            setSweatError(!SweatError);
            setSweatErrmsg(err);
            

        });
        setReload(false)
    }
    

    // const [search, setSearch] = useState(false);
    // async function handleFilterOp(e) {
    //     // let stringSearch = "";
    //     // if (e.target === undefined) {
    //     //     stringSearch = "";
    //     // } else {
    //     //     setSearchOp(e.target.value);
    //     //     stringSearch = e.target.value.toLowerCase();
    //     // }
    //     // if (stringSearch === '') {
    //     //     setListAutoclaveFiltered(listAutoclave);
    //     // } else {
    //     //     let results = listAutoclave.filter(res => res.ciclo.includes(stringSearch));
    //     //     setListAutoclaveFiltered(results);
    //     // }
    // }

    function renderOptions (id) {
        return(
          
            <>
                
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem as="button">
                            <div onClick={() => manageProcess(`${id}`)}>
                                Ver
                            </div>
                        </DropdownItem>
                        <DropdownItem as="button">
                            <div onClick={() => confirmEliminateProcess(`${id}`)}>
                                Eliminar
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>

            </>
        )
        
        
    }

    
    const paginationComponentOptions = {
        rowsPerPageText: 'Registos por Página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        // noRowsPerPage: 10
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };

    
    const columns = [
        {
            name: 'Estado',
            selector: row => row.state,
            width: 10,
            sortable: true,
            center: true,
            filterable: false,
            wrap:true,
            width:'150px'
        },
        // {
        //     name: 'Número de Ordem',
        //     selector: row => row.num_order,
        //     width: 10,
        //     sortable: true,
        //     center: true,
        //     filterable: false,
        //     wrap:true,
        //     width:'150px'
        // },
        {
            name: 'Data de Ativação',
            selector: row => row.activation_date,
            sortable: true,
            center: true,
            filterable: false,
            width:'150px'
        },
        {
            name: 'Nome',
            selector: row => row.name,
            sortable: true,
            center: false,
            wrap:true,
            filterable: false,
            width:'300px'
        },
        {
            name: 'Género',
            selector: row => row.gender,
            sortable: true,
            center: true,
            filterable: false
        },
        {
            name: 'Nº Processo Judicial',
            selector: row => row.litigation,
            sortable: true,
            center: true,
            filterable: false,
            width:'200px'
        },
        {
            name: 'Desdobramento de Processo',
            selector: row => row.litigationsequence,
            sortable: true,
            center: true,
            filterable: false,
            width:'200px'
        },
        {
            name: 'Notificação Tribunal',
            selector: row => row.court_notification_date,
            sortable: true,
            center: true,
            filterable: false,
            width:'150px'
        },
        {
            name: 'Notificação Procurador',
            selector: row => row.attorney_notification_date,
            sortable: true,
            center: true,
            filterable: false,
            width:'150px'
        },
        {
            name: 'Criado Em',
            selector: row => row.createat,
            sortable: true,
            center: true,
            width:'150px'
        },
        {
            name: 'Opções',
            width: 450,
            cell: row => <center>
                {renderOptions(row.id)}
            </center>,
        },

    ];

    
    const options = searchfields.map((opt,i) =>
        <option key={i} value={opt} >{opt}</option>
    );



    return(
        <>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">Lista de Processos</h4>
                    <div className="panel-heading-btn">
                        
                        {/* <button className="btn btn-xs btn-default  " onClick={() => exportProcess()}><i className="fa fa-plus"></i>&nbsp;Exportar</button> */}
                        <button title="Exportar Processos" className="btn btn-xs btn-icon btn-circle btn-warning" onClick={() => exportProcess()}><i className="fas fa-download"></i></button>&nbsp;
                        <button title="Adicionar Processo" className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => newProcess()}><i className="fa fa-plus"></i></button>&nbsp;
                    </div>
                    
                </div>
                {/* <PanelHeader noButton>
                    <div>
                        <h4 className="panel-title">Lista de Processos</h4>
                        <button onClick={() => newProcess()} className="btn btn-default m-r-20 btn-xs float-right" data-toggle="tooltip" title="Nova Ação de Formação"><i className="fa fa-plus"></i> &nbsp;Adicionar</button>
                    </div>
                    
                </PanelHeader> */}
                <PanelBody>

                    <div  className='row m-b-20 m-t-10 '>
                        <div className='col-xl-10 offset-1 square_destk'>
                            <div className='row m-b-20 m-t-20'>
                                {/* <div className='col-xl-1 offset-1 '>
                                    <legend className="no-border f-w-700 p-b-0 f-s-16 text-inverse">Pesquisa: </legend>
                                </div> */}
                                {/* <div className='col-xl-2 offset-1 '>
                                    <select
                                        name='cbofield'
                                        className={'form-control m-b-5'}
                                        onChange={(e) => handleChange(e,'cbofield')}
                                        value={cbofield}
                                    >
                                        <option key={0} value='0'>Selecione o campo a pesquisar</option>
                                        {options}
                                    </select>
                                </div> */}
                            
                                <div className='col-xl-9 offset-1'>
                                    <input
                                        name="search"
                                        type="text"
                                        className='form-control m-b-5'
                                        value={dataSearch}
                                        onChange={(e) => handleChange(e,'search')}
                                    />
                                </div>
                                <div className="col-xl-2 ">
                                    <button type="button" onClick={() => handleSubmit()} className="btn btn-primary"><i className="fas fa-search"></i>&nbsp;Pesquisar</button>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-xl-12'>
                   
                            <DataTable
                                keys="id"
                                columns={columns}
                                data={data}
                                striped
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                noDataComponent={"Sem Registos"}
                            />                        
                            {(reload &&
                                    <div className="panel-loader">
                                        <span className="spinner-small"></span>
                                    </div>
                                )}               
                        </div>
                    </div>
                </PanelBody>
            </Panel>

                    <ModalExport 
                        modalDialog={modalDialog}
                        toggleModal={() => toggleModal()}
                    />

            {
                (sweatSuccess &&
                    <SweetAlert success
                        title={translations['alerts']['success'].title}
                        confirmBtnBsStyle="success"
                        onConfirm={() => setSweatSuccess(!sweatSuccess)}
                    >
                        {SweatSucmsg}
                    </SweetAlert>
                )
            }

            {
                (sweatWarning &&
                    <SweetAlert warning
						title="Confirmação"
						showCancel
						showCloseButton

						confirmBtnBsStyle="info"
						confirmBtnText="Sim"

						cancelBtnBsStyle="danger"
						cancelBtnText="Não"
						onConfirm={() => { eliminateProcess(); setSweatWarning(false);}}
						onCancel={() =>  setSweatWarning(false)}

						focusConfirmBtn
					>
						{SweatSucmsg}
					</SweetAlert>

                    // <SweetAlert warning
                    //     title={translations['alerts']['missingfields'].title}
                    //     confirmBtnBsStyle="warning"
                    //     onConfirm={() => setSweatWarning(!sweatWarning)}
                    // >
                    //     {SweatSucmsg}
                    // </SweetAlert>
                )
            }

            {
                (SweatError &&
                    <SweetAlert danger
                        title={translations['alerts']['error'].title}
                        confirmBtnBsStyle="danger"
                        onConfirm={() => setSweatError(!SweatError)}
                    >
                        {SweatErrmsg}
                    </SweetAlert>
                )
            }
        </>                                    
    );
}

export default ListProcesses;