const Menu = [
  { path: '/app/dashboard', icon: 'fas fa-project-diagram', title: 'Dashboard' },
  { path: '/app/list-processes', icon: 'fa fa-table', title: 'Lista de Processos' },
  { path: '/app/import', icon: 'fa fa-upload', title: 'Importar Processos' },
  { path: '/app/backoffice', icon: 'fa fa-cog', title: 'Backoffice',
    children: [
      {
        path: '/app/courts', title: 'Tribunais'
      }, {
        path: '/app/terminationmotives', title: 'Motivos de Cessação',
        
      }, {
        path: '/app/focalpoints', title: 'Lista de Focal Points',
      },
      {
        path: '/app/users', title: 'Utilizadores'

      },
      {
        path: '/app/roles', title: 'Tipos de Utilizador'

      }
    ]
   },
]

export default Menu;
