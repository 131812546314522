import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';
import userpermissions from '../utils/permissions';

import PermissionList from "./../components/PermissionList";

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';



export default function Roles() {
    const history = useHistory();
    const translations = language();
    const userabilities = userpermissions();

    const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    // const [modalDialogFile, setModalDialogFile] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [id, setId] = useState(0);
    const [roles, setRoles] = useState([]);
    

    async function getRoles() {
        setReload(true)
        try {
            await api.get(configData.API + '/roles').then((response) => {
                // console.log('roles request.data', response.data);

                setRoles(response.data);
                // document.getElementById('name').value = response.data.data.name;
                // document.getElementById('email').value = response.data.data.email;


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        setReload(false)

    }

    useEffect(() => {
        getRoles();
    }, []);

    async function toggleModal(id) {
        await setModalDialog(!modalDialog);
        setErrors('');

        if (id !== 0) {
            getRoles();
            setId(id);
        } else {
            setId(0);
        }
    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    async function handleNew(e) {
        // console.log("handleNew")
        e.preventDefault();
    }

    async function handleEdit(e) {
        e.preventDefault();

    }

    const [deleter, setDeleteR] = useState(0);
    async function handleDelete(row) {
        try {
            await api.post(configData.API + '/deleteuser/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);
                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(translations['backoffice']['users']['responses']['delete'][err.response.data]);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    function handleChange(e) {
        // console.log('handleChange', e.target.value, e.target.checked)
        let temp = roles;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setRoles(temp);
        // console.log('after temp', temp)

    }

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };

    const tableColumns = [
        // {
        //     name: translations['backoffice']['roles']['table'].active,
        //     selector: 'str_active',
        //     sortable: true,
        //     center: true,
        //     // width: 100
        // },
        {
            name: translations['backoffice']['roles']['table'].name,
            selector: 'name',
            sortable: true,
            center: true,
            width: 150
        },
        {
            name: translations['backoffice']['roles']['table'].permissions,
            selector: 'permissionlist',
            sortable: true,
            center: true,
            // width: 100
            cell: row => <center>
                {renderPermissions(row)}
            </center>
                
        },
        {
            name: 'Opções',
            width: 10,
            cell: row => <center>
                {renderOptions(row)}
            </center>
        }
    ];

    function renderPermissions(row) {
        return (
            row.permissionlist.map((permission,key) => (
                <span key={key} className="badge badge-primary">{permission}</span>
            ))
        )
    }

    function renderOptions(row) {
        let show = true;
        if (row.id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={() => toggleModal(`${row.id}`)}>
                                        {translations['backoffice']['roles']['table-buttons'].edit}
                                    </div>
                                </DropdownItem>
                              
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }

    return (
        <>
            <h1 className="page-header">{translations['backoffice'].title}
                <small>&nbsp;{translations['backoffice']['roles'].title}</small>
            </h1>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">{translations['backoffice']['roles']['panel'].title}</h4>
                    <div className="panel-heading-btn">
                        <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
                    </div>
                   
                </div>
                <PanelBody>
                    <DataTable
                        keys="id"
                        pagination
                        columns={tableColumns}
                        data={roles}
                        paginationComponentOptions={paginationComponentOptions}
                        noDataComponent={"Sem Registos"}
                        customStyles={customStyles}
                    
                    />
                    {/* <ReactTable data={users} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" /> */}
                    {(reload &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                </PanelBody>
            </Panel>

        <Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
            <ModalHeader toggle={() => toggleModal(0)}>
                {
                    id === 0 ?
                            translations['backoffice']['roles']['modal']['header']['add'] :
                            translations['backoffice']['roles']['modal']['header']['edit']
                }
            </ModalHeader>
            <form onSubmit={id === 0 ? handleNew : handleEdit} ref={form}>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['name'].title}</label>
                        <div className="col-md-9">
                            <input type="text" id="name" name="role_name"
                                className="form-control m-b-5"
                                placeholder={translations['backoffice']['users']['modal']['body']['name'].placeholder}
                                // defaultValue={userpermissions.name}
                                onChange={(e) => handleChange(e)}
                            />
                            
                        </div>
                    </div>
                    <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['email'].title}</label>
                        <div className="col-md-9">
                            <input type="email" id="email" name="user_email"
                                className={(errors.user_email) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['users']['modal']['body']['email'].placeholder}
                                // defaultValue={roles.email}
                                onChange={(e) => handleChange(e)}
                            />
                            {errors.user_email &&
                                <span className="is-invalid">{errors.user_email[0]}</span>
                            }
                        </div>
                    </div>
                    <div>
                            <PermissionList handleChange={handleChange}/>
                    </div>

                   

                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations['backoffice']['users']['modal']['footer'].close}</button>
                    <button type="submit" className="btn btn-primary">{translations['backoffice']['users']['modal']['footer'].save}</button>
                </ModalFooter>
            </form>
        </Modal>
        {
            (sweatSuccess &&
                <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
            )
        }
        {
            (alertWarning &&
                <SweetAlert warning showCancel
                    confirmBtnText={translations['alerts']['warning'].confirmBtn}
                    cancelBtnText={translations['alerts']['warning'].cancelBtn}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={translations['alerts']['warning'].title}
                    onConfirm={() => { handleDelete(deleter); setAlertWarning(false) }}
                    onCancel={() => setAlertWarning(false)}
                >
                    {translations['backoffice']['users']['alert']['warning'].message}
                </SweetAlert>
            )
        }
        {
            (SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                    {SweatErrmsg}
                </SweetAlert>
            )
        }

    </>

)}