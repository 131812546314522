import React, { Component } from "react";
import api from '../../../config/api';
import configData from '../../../config/config.js';

class TerminationMotiveList extends Component {
    constructor(props) {
        super(props);
        this.handleChangeTerminationMotive = this.handleChangeTerminationMotive.bind(this);
        this.getTerminationMotivelist = this.getTerminationMotivelist.bind(this);


        this.state = {
            'termination_motive': this.props.termination_motive,
            'terminationMotivelist': {},
            // 'disabled': (this.props.disabled === 'false') ? false : true,
            'disabled': this.props.disabled,

        };

    }

    componentDidMount() {
        this.getTerminationMotivelist();

    }

    getTerminationMotivelist() {
        //LISTS
        api.get(configData.API + '/terminationmotivelist').then((request) => {
            // console.log('request.data terminationmotivelist', request.data.data);
            this.setState({
                terminationMotivelist: request.data.data
            });

        }).catch((err) => {
            console.log(err);
        });
    }


    handleChangeTerminationMotive(e) {

        this.setState({
            termination_motive: e.target.value,
        });


    }

    render() {

        const options = Object.keys(this.state.terminationMotivelist).map((key) =>
            <option key={this.state.terminationMotivelist[key].id} value={this.state.terminationMotivelist[key].id} > {this.state.terminationMotivelist[key].description}</option>
        );

        const stclassName = `form-group col-xl-${this.props.cols}`;

        return (
            <div className={stclassName}>
                <label>Motivo de Cessação</label>
                <select
                    id="termination_motive"
                    name='termination_motive'
                    className="form-control m-b-5"
                    disabled={this.props.disable}
                    defaultValue={(this.props.termination_motive !== null) ? this.props.termination_motive : 0}
                    onChange={this.props.handleChangeTerminationMotive}
                    onBlur={this.props.handleBlurTerminationMotive}
                >
                    <option value="">Selecione o Motivo de Cessação</option>
                    {options}
                </select>
            </div>
        )
    }


}

export default TerminationMotiveList;