import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component-with-filter';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import DistrictList from "./../components/DistrictList";

// import validateEmail from '../../../utils/validateEmail';
// import validateImage from '../../../utils/validateImage';

export default function Courts() {
    const history = useHistory();
    const translations = language();

    const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    // const [modalDialogFile, setModalDialogFile] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [id, setId] = useState(0);
    const [courts, setCourts] = useState([]);

    const [court, setCourt] = useState([]);
    const [district, setDistrict] = useState(0);
    const [county, setCounty] = useState(0);
    // const [reccourt, setRecCourt] = useState({
    //     description:'',
    //     email:'',
    //     court_district_id:0,
    //     court_county_id:0,
    //     magistrate:'',
    //     quality:'',
    //     address:'',
    //     postal_code:'',
    //     contact:'',
    //     district_president_judge:''
    // });



    async function getCourt(id) {
        // console.log('getCourt',id)
        try {
            await api.get(configData.API + '/court/' + id).then((response) => {
                // console.log('getCourt request.data', response.data);

                setCourt(response.data);
                setCounty(response.data.county_id);
                setDistrict(response.data.county.district_id);

             
                document.getElementById('description').value = response.data.description;
                document.getElementById('email').value = response.data.email;
                document.getElementById('court_district_id').value = response.data.county.district_id;
                document.getElementById('court_county_id').value = response.data.county_id;
                

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }

    async function toggleModal(id) {
        await setModalDialog(!modalDialog);
        setCourt([]);
        setCounty(0);
        setDistrict(0);
        setErrors('');

        if ((id !== 0) && (id !== null)) {
            getCourt(id);

            setId(id);
        } else {
            setId(0);
        }
    }

    function getData() {
        // console.log('getData')
        setReload(true);
        try {
            api.get(configData.API + '/getcourts').then((response) => {
                // console.log('import request.data', response.data);
                setCourts(response.data.data);


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        setReload(false);

    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    async function handleNew(e) {
        e.preventDefault();
        

        setReload(true);

        const formContent = new FormData(form.current);

        await api.post(configData.API + '/court', formContent).then((response) => {
            // console.log('response', response);
           
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 2000);


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }

        });
        setReload(false);
    }

    async function handleEdit(e) {
        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);
        await api.post(configData.API + '/court/' + id, formContent).then((response) => {
            // console.log('response update', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                // history.go(0)
            }, 3000);


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }



        });
        setReload(false);
    }

    const [deleter, setDeleteR] = useState(0); //nao sei se e preciso
    async function handleDelete(row) {
        try {
            await api.post(configData.API + '/deletecourt/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);
                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(err.message);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    function handleChange(e) {
        // console.log('handlechange', e.target)
    
        let temp = court;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setCourt(temp);
        
        
    }

    async function iniciate() {
        await getData();
    }

    useEffect(() => {
        iniciate();
    }, []);

    

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };

    const tableColumns = [
        {
            name: translations['backoffice']['focalpoints']['table'].active,
            selector: 'str_active',
            sortable: true,
            center: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['courts']['table'].description,
            selector: 'description',
            sortable: true,
            center: true,
            width: 150,
            filterable: true,
        },
        {
            name: translations['backoffice']['courts']['table'].email,
            selector: 'email',
            sortable: true,
            center: true,
            filterable: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['courts']['table'].county,
            selector: 'county.str',
            sortable: true,
            center: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['courts']['table'].created_at,
            selector: 'created_at',
            sortable: true,
            center: true,
            filterable: false,
            // width: 30
        },
        {
            name: 'Opções',
            width: 10,
            cell: row => <center>
                {renderOptions(row)}
            </center>            
        }
    ];

    function renderOptions(row) {
        let show = true;
        if (row.id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={() => toggleModal(`${row.id}`)}>
                                        {translations['backoffice']['courts']['table-buttons'].edit}
                                    </div>
                                </DropdownItem>
                                <DropdownItem as="button">
                                    <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {(row.active === 1) ? translations['backoffice']['courts']['table-buttons'].delete : translations['backoffice']['courts']['table-buttons'].active}
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }

    return (
        <>
            <h1 className="page-header">{translations['backoffice'].title}
                <small>&nbsp;{translations['backoffice']['courts'].title}</small>
            </h1>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">{translations['backoffice']['courts']['panel'].title}</h4>
                    <div className="panel-heading-btn">
                        <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
                    </div>
                   
                </div>
                <PanelBody>
                    <DataTable
                        keys="id"
                        pagination
                        columns={tableColumns}
                        paginationComponentOptions={paginationComponentOptions}
                        noDataComponent={"Sem Registos"}
                        data={courts}
                        customStyles={customStyles}
                    // pageDefaultSize={4}
                    />
                    {(reload &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                </PanelBody>
            </Panel>

            <Modal isOpen={modalDialog} size={'xl'} toggle={() => toggleModal(0)}>
                <ModalHeader toggle={() => toggleModal(0)}>
                    {
                        (id === 0) ?
                            translations['backoffice']['courts']['modal']['header']['add'] :
                            translations['backoffice']['courts']['modal']['header']['edit']
                    }
                </ModalHeader>
                <form onSubmit={(id === 0)? handleNew : handleEdit} ref={form}>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-1">{translations['backoffice']['courts']['modal']['body']['description'].title}</label>
                            <div className="row col-md-4">
                                <input type="text" 
                                id="court_description" name="court_description" 
                                className={(errors.court_description) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['courts']['modal']['body']['description'].placeholder} 
                                defaultValue={court.description} 
                                onChange={(e) => handleChange(e)} />
                                {errors.court_description &&
                                    <span className="is-invalid">{errors.court_description[0]}</span>
                                }
                            </div>
                            <label className="col-form-label  col-md-1">{translations['backoffice']['courts']['modal']['body']['email'].title}</label>
                            <div className="col-md-6">
                                <input type="email" 
                                id="court_email" name="court_email" 
                                className={(errors.court_email) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['courts']['modal']['body']['email'].placeholder} 
                                defaultValue={court.email}
                                onChange={(e) => handleChange(e)} />
                                {errors.court_email &&
                                    <span className="is-invalid">{errors.court_email[0]}</span>
                                }
                            </div>

                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-1">{translations['backoffice']['courts']['modal']['body']['magistrate'].title}</label>
                            <div className="col-md-3">
                                <input type="text"
                                    id="magistrate" name="magistrate"
                                    className={(errors.magistrate) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                    placeholder={translations['backoffice']['courts']['modal']['body']['magistrate'].placeholder}
                                    defaultValue={court.magistrate}
                                    onChange={(e) => handleChange(e)} />
                                {errors.magistrate &&
                                    <span className="is-invalid">{errors.magistrate[0]}</span>
                                }
                            </div>
                            <label className="col-form-label col-md-1">{translations['backoffice']['courts']['modal']['body']['quality'].title}</label>
                            <div className="col-md-1">
                                <input type="text"
                                    id="quality" name="quality"
                                    className={(errors.quality) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                    placeholder={translations['backoffice']['courts']['modal']['body']['quality'].placeholder}
                                    defaultValue={court.quality}
                                    onChange={(e) => handleChange(e)} />
                                {errors.quality &&
                                    <span className="is-invalid">{errors.quality[0]}</span>
                                }
                            </div>
                            <label className="col-form-label col-md-2 ">{translations['backoffice']['courts']['modal']['body']['district_president_judge'].title}</label>
                            <div className="col-md-4">
                                <input type="text"
                                    id="district_president_judge" name="district_president_judge"
                                    className={(errors.district_president_judge) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                    placeholder={translations['backoffice']['courts']['modal']['body']['district_president_judge'].placeholder}
                                    defaultValue={court.district_president_judge}
                                    onChange={(e) => handleChange(e)} />
                                {errors.district_president_judge &&
                                    <span className="is-invalid">{errors.district_president_judge[0]}</span>
                                }
                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            {/* <label className="col-form-label col-md-3">{translations['backoffice']['courts']['modal']['body']['county'].title}</label> */}
                            {/* <div className="col-md-9"> */}
                                <DistrictList
                                    name_district='court_district_id'
                                    name_county='court_county_id'
                                    cols='6'
                                    hcourt='false'
                                    error_district={errors.district_id}
                                    error_county={errors.court_county_id}
                                    disabledistrict={false}
                                    disablecounty={false}
                                    handleChangeDistrict={(e) => handleChange(e)}
                                    district={district}
                                    county={county}
                                />
                            {/* </div> */}
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-1">{translations['backoffice']['courts']['modal']['body']['address'].title}</label>
                            <div className="col-md-4">
                                <input type="text"
                                    id="address" name="address"
                                    className={(errors.address) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                    placeholder={translations['backoffice']['courts']['modal']['body']['address'].placeholder}
                                    defaultValue={court.address}
                                    onChange={(e) => handleChange(e)} />
                                {errors.magistrate &&
                                    <span className="is-invalid">{errors.address[0]}</span>
                                }
                            </div>
                            <label className="col-form-label  col-md-2">{translations['backoffice']['courts']['modal']['body']['postal_code'].title}</label>
                            <div className="col-md-2">
                                <input type="text"
                                    id="postal_code" name="postal_code"
                                    className={(errors.postal_code) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                    placeholder={translations['backoffice']['courts']['modal']['body']['postal_code'].placeholder}
                                    defaultValue={court.postal_code}
                                    onChange={(e) => handleChange(e)} />
                                {errors.postal_code &&
                                    <span className="is-invalid">{errors.postal_code[0]}</span>
                                }
                            </div>
                            <label className="col-form-label float-right col-md-1">{translations['backoffice']['courts']['modal']['body']['contact'].title}</label>
                            <div className="col-md-2">
                                <input type="text"
                                    id="contact" name="contact"
                                    className={(errors.contact) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                    placeholder={translations['backoffice']['courts']['modal']['body']['contact'].placeholder}
                                    defaultValue={court.contact}
                                    onChange={(e) => handleChange(e)} />
                                    {errors.contact &&
                                      <span className="is-invalid">{errors.contact[0]}</span>
                                }
                            </div>
                        </div>
                        
                       
                        
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations['backoffice']['courts']['modal']['footer'].close}</button>
                        <button type="submit" className="btn btn-primary">{translations['backoffice']['courts']['modal']['footer'].save}</button>
                    </ModalFooter>
                </form>
            </Modal>
            {(sweatSuccess &&
                <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
            )}
            {(alertWarning &&
                <SweetAlert warning showCancel
                    confirmBtnText={translations['alerts']['warning'].confirmBtn}
                    cancelBtnText={translations['alerts']['warning'].cancelBtn}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={translations['alerts']['warning'].title}
                    onConfirm={() => { handleDelete(deleter); setAlertWarning(false) }}
                    onCancel={() => setAlertWarning(false)}
                >
                    {translations['backoffice']['courts']['alert']['warning'].message}
                </SweetAlert>
            )}
            {(SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                {SweatErrmsg}
                </SweetAlert>
            )}
        </>
    )
}