import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router'

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component-with-filter';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';


export default function TerminationMotives() {

    const history = useHistory();
    const translations = language();

    const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    // const [modalDialogFile, setModalDialogFile] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [id, setId] = useState(0);
    const [terminationmotives, setTerminationmotives] = useState([]);

    const [terminationmotive, setTerminationmotive] = useState([]);
    
    async function getTerminationmotive(id) {
        try {
            await api.get(configData.API + '/terminationmotive/' + id).then((response) => {
                // console.log('terminationmotive request.data', response.data);

                setTerminationmotive(response.data);
                // document.getElementById('active').checked = response.data.active;
                

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }

    async function toggleModal(id) {
        await setModalDialog(!modalDialog);
        setErrors('');

        if (id !== 0) {
            getTerminationmotive(id);
            setId(id);
        } else {
            // let tmp = {
            //     'description' : '',
            //     'active': 1
            // }
            // setTerminationmotive(tmp)
            setId(0);
        }
    }

    function getData() {
        // console.log('getData')
        setReload(true);
        try {
            api.get(configData.API + '/getterminationmotives').then((response) => {
                // console.log('import request.data', response.data);
                setTerminationmotives(response.data.data);
               

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        setReload(false);

    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    async function handleNew(e) {
        // console.log("handleNew")
        e.preventDefault();
        setReload(true);

        const formContent = new FormData(form.current);


        await api.post(configData.API + '/terminationmotive', formContent).then((response) => {
            // console.log('response', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 2000);
            
            // history.push(response.data.route);
            // setTimeout(() => {
            //     history.push(response.data.route);
            // }, 2000);
            


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else { 
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }



        });
        setReload(false);
    }

    async function handleEdit(e) {

        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);

        await api.post(configData.API + '/terminationmotive/'+id, formContent).then((response) => {
            // console.log('response update', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 3000);

            // history.push(response.data.route);
            // setTimeout(() => {
            //     history.push(response.data.route);
            // }, 2000);



        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }



        });
        setReload(false);
        
    }

    const [deleter, setDeleteR] = useState(0); 
    async function handleDelete(row) {
      
        try {
            await api.post(configData.API + '/deleteterminationmotive/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);

                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(translations['backoffice']['terminationmotives']['responses']['delete'][err.response.data]);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    
    function handleChange(e) {
        // console.log('handleChange', e.target.value,e.target.checked)
        let temp = terminationmotive;
        console.log('bfr temp', temp, e.target.type)
        temp[e.target.name] = e.target.value;
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 : 0;
        setTerminationmotive(temp);
        // console.log('after temp', temp)

    }

    async function iniciate() {
        await getData();
    }

    useEffect(() => {
        iniciate();
    }, []);

    
    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };
    
    const tableColumns = [
        {
            name: translations['backoffice']['terminationmotive']['table'].active,
            selector: 'str_active',
            sortable: true,
            center: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['terminationmotive']['table'].description,
            selector: 'description',
            sortable: true,
            center: true,
            filterable: true,
            width: 450
        },
        
        {
            name: translations['backoffice']['terminationmotive']['table'].created_at,
            selector: 'created_at',
            sortable: true,
            center: true,
            filterable: true,
            width: 30
        },
        {
            name: 'Opções',
            width: 10,
            cell: row => <center>
                {renderOptions(row)}
            </center>            
        }
    ];

    function renderOptions(row) {
        let show = true;
        if (row.id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={() => toggleModal(`${row.id}`)}>
                                        {translations['backoffice']['terminationmotive']['table-buttons'].edit}
                                    </div>
                                </DropdownItem>
                                <DropdownItem as="button">
                                    <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {(row.active === 1) ? translations['backoffice']['terminationmotive']['table-buttons'].delete : translations['backoffice']['terminationmotive']['table-buttons'].active}
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }


    return (
        <>
            <h1 className="page-header">{translations['backoffice'].title}
                <small>&nbsp;{translations['backoffice']['terminationmotive'].title}</small>
            </h1>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">{translations['backoffice']['terminationmotive']['panel'].title}</h4>
                    <div className="panel-heading-btn">
                        <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
                    </div>
                    
                    {/* {({ toggleExpand, toggleRemove, toggleCollapse, toggleReload, onClick }) => (
                        <div className="panel-heading-btn">
                            <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button> &nbsp;&nbsp;
                            <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={toggleReload}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
                            <button className="btn btn-xs btn-icon btn-circle btn-warning" onClick={toggleCollapse}><i className="fa fa-minus"></i></button>&nbsp;&nbsp;
                            <button className="btn btn-xs btn-icon btn-circle btn-danger" onClick={toggleRemove}><i className="fa fa-times"></i></button>
                        </div>
                    )} */}
                </div>
                <PanelBody>
                    <DataTable
                        keys="id"
                        pagination
                        columns={tableColumns}
                        data={terminationmotives}
                        paginationComponentOptions={paginationComponentOptions}
                        noDataComponent={"Sem Registos"}
                        customStyles={customStyles}
                    />
                    {/* <ReactTable data={terminationmotives} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" /> */}
                    {(reload &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                </PanelBody>
            </Panel>

            <Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
                <ModalHeader toggle={() => toggleModal(0)}>
                    {
                        (id === 0) ?
                            translations['backoffice']['terminationmotive']['modal']['header']['add'] :
                            translations['backoffice']['terminationmotive']['modal']['header']['edit']
                    }
                </ModalHeader>
                <form onSubmit={(id === 0) ? handleNew : handleEdit} ref={form}>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-4">{translations['backoffice']['terminationmotive']['modal']['body']['description'].title}</label>
                            <div className="col-md-7">
                                <input type="text" id="description" name="description" 
                                className={(errors.description) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['terminationmotive']['modal']['body']['description'].placeholder} 
                                defaultValue={terminationmotive.description} 
                                onChange={(e) => handleChange(e)}/>
                                {errors.description &&
                                    <span className="is-invalid">{errors.description[0]}</span>
                                }
                            </div>
                        </div>
                                                
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations['backoffice']['terminationmotive']['modal']['footer'].close}</button>
                        <button type="submit" className="btn btn-primary">{translations['backoffice']['terminationmotive']['modal']['footer'].save}</button>
                        {(reload &&
                            <div className="panel-loader">
                                <span className="spinner-small"></span>
                            </div>
                        )}
                    </ModalFooter>
                </form>
            </Modal>
            {(sweatSuccess &&
            <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
                
            )}
            {(alertWarning &&
                <SweetAlert warning showCancel
                    confirmBtnText={translations['alerts']['warning'].confirmBtn}
                    cancelBtnText={translations['alerts']['warning'].cancelBtn}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={translations['alerts']['warning'].title}
                    onConfirm={() => { handleDelete(deleter); setAlertWarning(false) }}
                    onCancel={() => setAlertWarning(false)}
                >
                    {translations['backoffice']['terminationmotive']['alert']['warning'].message}
                </SweetAlert>
            )}
            {(SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                {SweatErrmsg}
                </SweetAlert>
            )}
        </>
    )
}