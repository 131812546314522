import React, { Component } from "react";

class DurationList extends Component { 

    constructor(props) {
        super(props);   

        this.handleChangeDuration = this.handleChangeDuration.bind(this);

        this.state = {
            'duration': this.props.duration,
            'disabled': this.props.disabled,
            'errors': null
        }
    }

    componentDidMount() {
        
        if (this.props.errors)
            this.setState({
                errors: this.props.errors
            });


        this.setState({
            duration: this.props.duration
        });
    
    }

    handleChangeDuration(e) {
        // console.log("setDistrict Selected!!",e);

        this.state.duration = e.target.value;

        // if (e.target.value !== this.props.duration) 
        //     e.target.value

        

    }

    
    render() {
        let numbers = [];
        for (var i = 0; i <= this.props.limit; i++) {
            numbers.push(i);
        }

        const options = numbers.map((number) => 
            <option key={number} value={number}> {number}</option>

        );

        return (
            <>
            <select
                id="duration"
                name="duration"
                className="form-control text-center"
                disabled={this.props.disabled}
                value={(this.props.duration !== 0) ? this.props.duration : 0}
                onChange={this.props.handleChangeDuration}
            >
                {options}
            </select>
            {
                (this.props.errors) && (
                    <span className="is-invalid">{this.props.errors}</span>
                )
            }
            </>
        )
        }
    

}

export default DurationList;