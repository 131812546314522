import React from 'react';

import api from '../../../config/api.js';
import configData from '../../../config/config.js';
import language from '../../../pages/app/utils/language';

import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.goUserProfile = this.goUserProfile.bind(this);
		// this.getUser = this.getUser.bind(this);
		// this.toggleModal = this.toggleModal.bind(this);
		// this.handleChange = this.handleChange.bind(this);
		// this.handleEdit = this.handleEdit.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.state = {
			user: [],
			id: 0,
			profileActive: 0,
			name: '',
			dropdownOpen: false,
			userimage: false,
			logoalentapp: true
		};

		const userinfo = JSON.parse(sessionStorage.getItem('user'));
		// console.log(userinfo);

		this.id = 0;
		this.name = '';
		this.active = 0;
		
		if (userinfo !== null) {
			this.id = userinfo["id"];
			this.name = userinfo["name"];
			this.active = userinfo["active"];
		}

		this.state.profileActive = this.active;
		this.state.id = this.id;
		this.state.name = this.name;
		
		this.form = React.createRef();
	
	}


	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	goUserProfile() {

	}

	onLogout() {

		api.post(configData.API + '/logout').then((request, response) => {

			// console.log('logout request, response', request, response);

			//passport
			// localStorage.clear('access_token');
			// localStorage.clear('token_type');
			// localStorage.clear('expires_at');
			// localStorage.clear('name');
			// localStorage.clear('function');

			// this.props.history.push('/');
			// this.props.history.push(request.data.route);
		}).catch((err) => {
			console.log('error logout', err);
			// if (err.request.status === 401) {
			// 	this.setState({
			// 		SweatError: !this.state.SweatError,
			// 		SweatErrmsg: "Unauthorized User!",

			// 	});
			// }
		});


	}

	render() {
		const translations = language();
		

		return (
			<>
				<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
					<DropdownToggle tag="a" >
						{(this.state.userimage) &&
							<img src="../assets/img/user/user-13.jpg" alt="" />
						}
						{(this.state.logoalentapp) &&
							<img src="../assets/img/alentapp/alentapp_texto_full.png" className="logotext" alt="" />
						}
						
						<span className="d-none d-md-inline">{this.state.name}</span> <b className="caret"></b>
					</DropdownToggle>
					<DropdownMenu className="dropdown-menu dropdown-menu-right push-left" tag="ul">
						{/* <DropdownItem>Edit Profile</DropdownItem>
						<DropdownItem><span className="badge badge-danger pull-right">2</span> Inbox</DropdownItem>
						<DropdownItem>Calendar</DropdownItem> */}
						<DropdownItem>
							<Link to="/profile" className="dropdown-item">{translations['header']['user_profile']}</Link>
							{/* <div onClick={() => this.toggleModal(this.state.id)}>
								{translations['header']['user_profile']}
							</div> */}
						</DropdownItem>
						<DropdownItem divider={true}></DropdownItem>
							<Link to="/" onClick={this.onLogout} className="dropdown-item">{translations['header']['logout']}</Link>
						
					</DropdownMenu>
				</Dropdown>

				
			</>
		);
	}
};

export default DropdownProfile;
