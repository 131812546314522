import React, { Component } from "react";

class MeasureCoercionList extends Component {
    constructor(props) {
        super(props);
        this.handleChangeMeasureCoercion = this.handleChangeMeasureCoercion.bind(this);
        
        this.state = {
            // 'measure_coercion': this.props.measure_coercion,  //nao muda o estado
            'disabledmeasurecoercion': (this.props.disable === 'false') ? false : true,

        };

    }

    componentDidMount() {
        // console.log('\n\n MeasureCoercionList', this.props);
    }


    handleChangeMeasureCoercion(e) {

        // this.setState({
        //     measure_coercion: e.target.value,
        // });
        var d = true;
        if (e.target.value === 1) {
            d=false;
        }

        this.setState({
            disabledmeasurecoercion: d
        });

        // if (e.target.value == 1) setDisabledmeasureCoercionDescription(false);
        // else setDisabledmeasureCoercionDescription(true);


    }

    render() {

        let opts = ['Selecione a Medida de Coação','Sim', 'Não', 'Não Sabe'];

        let i = 0;
        const options = opts.map((opt) =>
            <option key={i} value={i++} >{opt}</option>
        );

        const stclassName = `form-group col-xl-${this.props.cols}`;

        return (
            <div className={stclassName}>
                <label>Medida de Coação *</label>
                <select
                    id="measure_coercion"
                    name='measure_coercion'
                    className={(this.props.errors) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                    disabled={this.props.disable}
                    value={(this.props.measure_coercion !== null) ? this.props.measure_coercion : 0}
                    onChange={this.props.handleChangeMeasureCoercion}
                >
                    {/* <option value="">Selecione um Distrito</option> */}
                    {options}
                </select>
                {
                    (this.props.errors) && (
                        <span className="is-invalid m-t-20">{this.props.errors[0]}</span>
                    )
                }
            </div>
        )
    }


}

export default MeasureCoercionList;