import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';


import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component-with-filter';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// import validateEmail from '../../../utils/validateEmail';
// import validateImage from '../../../utils/validateImage';

export default function Users() {
    const history = useHistory();
    const translations = language();

    const [reload, setReload] = useState(true);

    const [modalDialog, setModalDialog] = useState(false);
    // const [modalDialogFile, setModalDialogFile] = useState(false);

    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [alertWarning, setAlertWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');
    const [alertErrorConfirm, setAlertErrorConfirm] = useState(true);

    const [id, setId] = useState(0);
    const [users, setUsers] = useState([]);
    const [usersType, setUsersType] = useState([]);

    const [user, setUser] = useState([]);
    async function getUser(id) {
        try {
            await api.get(configData.API + '/user/' + id).then((response) => {
                // console.log('user request.data', response.data);

                setUser(response.data);
                document.getElementById('name').value = response.data.data.name;
                document.getElementById('email').value = response.data.data.email;
                document.getElementById('userType').value = response.data.data.role[0];
                

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }
    async function getUserTypes() {
        try {
            await api.get(configData.API + '/roles').then((response) => {
                // console.log('getUserTypes request.data', response.data);

                setUsersType(response.data);
                
                

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
    }

    async function toggleModal(id) {
        await setModalDialog(!modalDialog);
        setErrors('');

        if (id !== 0) {
            getUser(id);
            setId(id);
        } else {
            setId(0);
        }
    }


    function getData() {
        // console.log('getData')
        setReload(true);
        try {
            api.get(configData.API + '/getusers').then((response) => {
                // console.log('import request.data', response.data);
                setUsers(response.data.data);


            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!{ SweatError });
            setSweatErrmsg(error);
        }
        setReload(false);

    }

    const form = useRef(null);
    const [errors, setErrors] = useState('');
    async function handleNew(e) {
        // console.log("handleNew")
        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);

        // Details of the uploaded file
        // formContent.append('termination_date', termination_date);
        // for (let i = 0; i < files.length; i++) {
        //     formContent.append('file_' + i, files[i]);

        // }

        // console.log('handleForm', formContent);

        await api.post(configData.API + '/user', formContent).then((response) => {
            // console.log('response', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 3000);


        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }

        });
        setReload(false);
    }

    async function handleEdit(e) {
        e.preventDefault();

        setReload(true);

        const formContent = new FormData(form.current);
        await api.post(configData.API + '/user/' + id, formContent).then((response) => {
            // console.log('response update', response);
            // console.log('request.data process add', JSON.stringify(response.data.data));
            //sweetalert success
            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

            toggleModal(0)
            setTimeout(() => {
                history.go(0)
            }, 3000);




        }).catch((err) => {
            // console.log('erro POST', err, err.request.status);
            if (err.request.status === 422) { //validation errors
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);

            } else {
                if (err.request.status === 419) { //authentication errors
                    history.push('/');
                } else {
                    setSweatSucmsg();
                    setSweatError(!{ SweatError });
                    setSweatErrmsg(err);
                }

            }


        });
        setReload(false);
    }

    const [deleter, setDeleteR] = useState(0); 
    async function handleDelete(row) {
        try {
            await api.post(configData.API + '/deleteuser/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);
                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(translations['backoffice']['users']['responses']['delete'][err.response.data]);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    function handleChange(e) {
        // console.log('handleChange', e.target.value, e.target.checked)
        let temp = user;
        // console.log('bfr temp', temp, e.target.type)
        // temp[e.target.name] = (e.target.type !== 'checkbox') ? e.target.value : (e.target.checked) ? 1 :
        temp[e.target.name] = e.target.value;
        setUser(temp);
        // console.log('after temp', temp)

    }
    function handleTypeChange(e) {
  
        setUser(prevState => ({ ...prevState, role: e.target.value}))

    }

    async function resetPassword(row) {
        // console.log('resetPassword')
        try {
            await api.post(configData.API + '/resetpassword/' + row.id)
                .then(response => {
                    setSweatSucmsg(response.data.message);
                    setSweatSuccess(true);
                    setTimeout(() => {
                        history.go(0)
                    }, 3000);
                })
                .catch(err => {
                    setSweatErrmsg(translations['backoffice']['users']['responses']['delete'][err.response.data]);
                    setSweatError(true);
                })
        } catch (err) {
            setSweatErrmsg(err.message);
            setSweatError(true);
        }
    }

    useEffect(() => {
        getData();
        getUserTypes();
    }, []);

 

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
        headCells: {
			style: {
				paddingLeft: '8px', // override the cell padding for head cells
				paddingRight: '8px',
				fontSize: '13px',
                fontWeight: '600'
			},
		},
    };

    const tableColumns = [
        {
            name: translations['backoffice']['focalpoints']['table'].active,
            selector: 'str_active',
            sortable: true,
            center: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['users']['table'].name,
            selector: 'name',
            sortable: true,
            center: true,
            filterable: true,
            width: 150
        },
        {
            name: translations['backoffice']['users']['table'].email,
            selector: 'email',
            sortable: true,
            center: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['users']['table'].role,
            selector: 'role',
            sortable: true,
            center: true,
            filterable: true,
            // width: 100
        },
        {
            name: translations['backoffice']['users']['table'].created_at,
            selector: 'created_at',
            sortable: true,
            center: true,
            filterable: true,
            // width: 30
        },
        {
            name: translations['backoffice']['users']['table'].password_change_at,
            selector: 'password_change_at',
            sortable: true,
            center: true,
            filterable: true,
            // width: 30
        },
        {
            name: 'Opções',
            width: 10,
            cell: row => <center>
                {renderOptions(row)}
            </center>            
        }
    ];

    

    function renderOptions(row) {
        let show = true;
        if (row.id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={() => toggleModal(`${row.id}`)}>
                                        {translations['backoffice']['users']['table-buttons'].edit}
                                    </div>
                                </DropdownItem>
                                {/* <DropdownItem as="button"> */}
                                    {/* <Link to="/rolepermissions" className="dropdown-item">{translations['backoffice']['users']['table-buttons'].permissions}</Link> */}
                                    {/* <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {translations['backoffice']['users']['table-buttons'].permissions}
                                    </div> */}
                                {/* </DropdownItem> */}
                                <DropdownItem as="button">
                                    <div onClick={() => { resetPassword(row)}}>
                                        {translations['backoffice']['users']['table-buttons']['password-reset']}
                                    </div>
                                </DropdownItem>
                                <DropdownItem as="button">
                                    <div onClick={() => { setDeleteR(row); (row.active === 1) ? setAlertWarning(true) : handleDelete(row); }}>
                                        {(row.active === 1) ? translations['backoffice']['users']['table-buttons'].delete : translations['backoffice']['users']['table-buttons'].active}
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }


    return (
        <>
            <h1 className="page-header">{translations['backoffice'].title}
                <small>&nbsp;{translations['backoffice']['users'].title}</small>
            </h1>
            <Panel>
                <div className="panel-heading">
                    <h4 className="panel-title">{translations['backoffice']['users']['panel'].title}</h4>
                    <div className="panel-heading-btn">
                        <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
                    </div>
                    
                </div>
                <PanelBody>
                    <DataTable
                        keys="id"
                        pagination
                        columns={tableColumns}
                        data={users}
                        paginationComponentOptions={paginationComponentOptions}
                        noDataComponent={"Sem Registos"}
                        customStyles={customStyles}
                    />
                    {/* <ReactTable data={users} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" /> */}
                    {(reload &&
                        <div className="panel-loader">
                            <span className="spinner-small"></span>
                        </div>
                    )}
                </PanelBody>
            </Panel>

            <Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
                <ModalHeader toggle={() => toggleModal(0)}>
                    {
                        id === 0 ?
                            translations['backoffice']['users']['modal']['header']['add'] :
                            translations['backoffice']['users']['modal']['header']['edit']
                    }
                </ModalHeader>
                <form onSubmit={id === 0 ? handleNew : handleEdit} ref={form}>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['name'].title}</label>
                            <div className="col-md-9">
                                <input type="text" id="name" name="user_name" 
                                className={(errors.user_name) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['users']['modal']['body']['name'].placeholder} 
                                defaultValue={user.name}
                                onChange={(e) => handleChange(e)}
                                />
                                {errors.user_name &&
                                    <span className="is-invalid">{errors.user_name[0]}</span>
                                }
                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['email'].title}</label>
                            <div className="col-md-9">
                                <input type="email" id="email" name="user_email" 
                                className={(errors.user_email) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                placeholder={translations['backoffice']['users']['modal']['body']['email'].placeholder} 
                                defaultValue={user.email}
                                onChange={(e) => handleChange(e)}
                                />
                                {errors.user_email &&
                                    <span className="is-invalid">{errors.user_email[0]}</span>
                                }
                            </div>
                        </div>
                        
                        <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">{translations['backoffice']['users']['modal']['body']['user-type'].title}</label>
                            <div className="col-md-9">
                                <select className="form-control" id="userType" name="userType" onChange={(e) => handleTypeChange(e)}>
                                    <option value={0}>{translations['backoffice']['users']['modal']['body']['user-type'].placeholder}</option>
                                    {
                                        usersType.map(userType => (
                                            <option key={userType.id} value={userType.id}>{userType.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        
                        
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations['backoffice']['users']['modal']['footer'].close}</button>
                        <button type="submit" className="btn btn-primary">{translations['backoffice']['users']['modal']['footer'].save}</button>
                    </ModalFooter>
                </form>
            </Modal>
            {(sweatSuccess &&
                <SweetAlert success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title={translations['alerts']['success'].title}
                    onConfirm={() => setSweatSuccess(false)}
                >
                    {SweatSucmsg}
                </SweetAlert>
            )}
            {(alertWarning &&
                <SweetAlert warning showCancel
                    confirmBtnText={translations['alerts']['warning'].confirmBtn}
                    cancelBtnText={translations['alerts']['warning'].cancelBtn}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={translations['alerts']['warning'].title}
                    onConfirm={() => { handleDelete(deleter); setAlertWarning(false) }}
                    onCancel={() => setAlertWarning(false)}
                >
                    {translations['backoffice']['users']['alert']['warning'].message}
                </SweetAlert>
            )}
            {(SweatError &&
                <SweetAlert danger
                    showConfirm={alertErrorConfirm}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="default"
                    title={translations['alerts']['error'].title}
                    onConfirm={() => setSweatError(false)}
                >
                {SweatErrmsg}
                </SweetAlert>
            )}
        </>
    )
}