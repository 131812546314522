import configData from './config';
import axios from 'axios';


const api = axios.create({
  baseURL: configData.BASE_URL,
  withCredentials: true,
  headers: {
    "content-Type": "multipart/form-data;application/json",
    "Accept": "application/json"
  }
});

api.interceptors.request.use((config) => {

  //passport
  // const token = localStorage.getItem("access_token");
    
  //   token
  //     ? config.headers.Authorization = `Bearer ${token}`
  //     : config.headers.Authorization = {}

  
  // console.log('config', config);
  // console.log('config', config.url);
    return config;
});
  
api.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log('error', error);
      if (typeof error.response !== 'undefined') {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            window.location.replace("/");
          }
      }
      
  
      return Promise.reject(error);
    }
)

export default api;