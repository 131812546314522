import React, { Component } from "react";
import api from '../../../config/api';
import configData from '../../../config/config.js';


class DistrictList extends Component {
    // console.log('props', props);

    constructor(props) {
        super(props);

        // console.log('props',this.props)

        this.handleChangeCounty = this.handleChangeCounty.bind(this);

        this.getDistrictlist = this.getDistrictlist.bind(this);
        this.getCountylist = this.getCountylist.bind(this);

        this.handleChangeCourt = this.handleChangeCourt.bind(this);

        this.state={
            'district': 0,
            'county': 0,
            'court': 0,

            'districtlist' : {},
            'countylist': {},
            'courtlist': {},

            'readlistcounty' : false,
            'readlistcourt': false,
            'hcourt': (this.props.hcourt === 'false') ? false : true,
            
        };

 
 
    }

    componentDidMount() {
        
        // console.log('comp district list didmount', this.props.district, this.props.county)

        this.getDistrictlist();

        if (this.props.district !== 0) {
            
            this.getCountylist(this.props.district);
            this.state.district = this.props.district;
            
        }
        

        if ((this.props.hcourt) && (this.props.county !== 0)) {
            this.getCourtlist(this.props.county);
          
        }

        if(this.props.errors) 
            this.state.errors = this.props.errors;
        
        this.state.district = (this.props.district !== 0) ? this.props.district : this.state.district;
        
    }


    async getDistrictlist() {
        //LISTS
        await api.get(configData.API + '/districtlist').then((request) => {
            // console.log('request.data districtlist', request.data.data);
            this.setState({
                districtlist: request.data.data
            });

            if (this.props.district !== 0) {
                document.getElementById(this.props.name_district).value = this.props.district;
                this.state.district = this.props.district;
            }


        }).catch((err) => {
            console.log(err);
        });
    }

    handleChangeDistrict(e) {
        this.setState({
            district: e.target.value
        });
        
        document.getElementById(this.props.name_district).value = e.target.value;
        
        this.getCountylist(e.target.value);

    }

    
    getCountylist(district_id) {
        // console.log('getCountylist', this.state.district, district_id);
        //LISTS

        if (district_id !== 0) {
            // console.log('comp getCountylist', this.state.district, district_id);

            api.get(configData.API + '/countylist/' + district_id).then((request) => {
                // console.log('request.data countylist', request.data.data);
                this.setState({
                    countylist: request.data.data
                });

                if (this.props.county !== 0) {
                    document.getElementById(this.props.name_county).value = this.props.county;
                    this.state.county = this.props.county;
                }
                

            }).catch((err) => {
                console.log(err);
            });
        }
    }

    

    handleChangeCounty(e) {
        // console.log("handleChangeCounty Selected!!");
        this.setState({
            county: e.target.value,
        });

        if (this.props.hcourt){
            this.getCourtlist(e.target.value);
            this.state.court = this.props.court;
            
        }
        
    }

    getCourtlist(county_id) {
        //LISTS

        if (county_id !== 0) {
            api.get(configData.API + '/courtlist/' + county_id).then((request) => {
                // console.log('request.data CourtList', request.data.data);
                this.setState({
                    courtlist: request.data.data,
                    // disabledcourt: !this.state.disabledcourt
                });

                if (this.props.court !== 0) {
                    document.getElementById('court_id').value = this.props.court;
                    this.state.court = this.props.court;
                }


            }).catch((err) => {
                console.log(err);
            });
        }

        
    }

    

    handleChangeCourt(e) {

        this.setState({
            court: e.target.value,
            // disabledcourt: !this.state.disabledcourt
        });
        document.getElementById('court_id').value = e.target.value;

    }

    
    
    render() {

        

        if ((!this.state.readlistcounty) && ((this.props.district !== 0) || (this.state.countylist) === undefined))  {
            if (this.props.district !== 0) {
                document.getElementById(this.props.name_district).value = this.props.district;
                this.state.district = this.props.district;
            }
            this.getCountylist(this.props.district);
            this.state.readlistcounty = true
        }

        if ((this.props.hcourt) && ((!this.state.readlistcourt) && ((this.props.county !== 0) || (this.state.courtlist) === undefined))) {
            this.getCourtlist(this.props.county);
            this.state.readlistcourt = true
        }

        const options = Object.keys(this.state.districtlist).map((key) =>
            <option key={this.state.districtlist[key].id} value={this.state.districtlist[key].id} > {this.state.districtlist[key].description}</option>
        );

        var options_county=[];
        if (this.state.countylist !== undefined) {
            options_county = Object.keys(this.state.countylist).map((key) =>
                <option key={this.state.countylist[key].id} value={this.state.countylist[key].id} > {this.state.countylist[key].description}</option>
            );
        }

        // console.log('options_county', options_county);
        var options_court = [];
        if ((this.props.hcourt) && (this.state.courtlist !== undefined)) {
            options_court = Object.keys(this.state.courtlist).map((key) =>
                <option key={this.state.courtlist[key].id} value={this.state.courtlist[key].id} > {this.state.courtlist[key].description}</option>
            );
        }

        const stclassName = `form-group col-xl-${this.props.cols}`;
      

        return (
            <>
            {/* <div class="row"> */}
                <div className={stclassName}>
                    {
                        (this.state.hcourt) ? <label>Distrito (Tribunal) * </label> : <label>Distrito * </label>
                    }
                    
                    <select
                        id={this.props.name_district}
                        name={this.props.name_district}
                        className={(this.props.error_district) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                        disabled={this.props.disabledistrict}
                        defaultValue={(this.props.district !== 0) ? this.props.district : this.state.district}
                        onChange={(e) => this.handleChangeDistrict(e)}
                        // onBlur={this.props.handleBlurDistrict}
             
                    >
                        <option value="0">Selecione um Distrito</option>
                        {options}
                    </select>
                    {
                        (this.props.error_district) && (
                            <span className="is-invalid">{this.props.error_district}</span>
                        )
                    }
                    
                </div>
                <div className={stclassName}>

                    {
                        (this.state.hcourt) ? <label>Concelho (Tribunal) * </label> : <label>Concelho * </label>
                    }
                   
                    <select
                        id={this.props.name_county}
                        name={this.props.name_county}
                        className={(this.props.error_county) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                        disabled={this.props.disablecounty}
                        defaultValue={(this.props.county !== 0) ? this.props.county : this.state.county}
                        onChange={(e) => this.handleChangeCounty(e)}
                        // onBlur={this.props.handleBlurCounty}
                    >
                        <option value="">Selecione um Concelho</option>
                        {options_county}
                    </select>
                    {
                        (this.props.error_county) && (
                            <span className="is-invalid">{this.props.error_county}</span>
                        )
                    }
      
                </div>
                {
                    (this.state.hcourt) && (
                        <div className={stclassName}>
                            <label>Tribunal *</label>
                            <select
                                id='court_id'
                                name='court_id'
                                className={(this.props.error_court) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                disabled={this.props.disabledcourt}
                                defaultValue={(this.props.court !== 0) ? this.props.court : this.state.court}
                                onChange={(e) => this.handleChangeCourt(e)}
                                // onBlur={this.props.handleBlurCourt}
                            >
                                <option value="">Selecione um Tribunal</option>
                                {options_court}
                            </select>
                            {
                                (this.props.error_court) && (
                                    <span className="is-invalid">{this.props.error_court}</span>
                                )
                            }
                        </div>
                    )
                }
                
            </>
        )
    }

    
}

export default DistrictList;