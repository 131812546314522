import React, { useEffect, useState, useRef } from 'react';
import { Panel, PanelBody, PanelFooter } from '../../../components/panel/panel.jsx';
import { useParams, useHistory } from 'react-router-dom';

import api from '../../../config/api';
import configData from '../../../config/config.js';
import language from '../utils/language';

import DistrictList from "./../components/DistrictList";
import GenderList from "./../components/GenderList";
import MeasureCoercionList from "./../components/MeasureCoercionList";
import CohabitationList from "./../components/CohabitationList";
import FocalPointList from "./../components/FocalPointList";
import TerminationMotiveList from "./../components/TerminationMotiveList";
import DurationList from "./../components/DurationList";
import StateList from "./../components/StateList";

import FilesUploadComponent from "./../components/FilesUpload";


// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import DataTable from 'react-data-table-component';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



import SweetAlert from 'react-bootstrap-sweetalert';

function ViewProcessForm(props) {

    const { id } = useParams();
    // console.log('param id', id);

    const history = useHistory();
    const translations = language();

    const moment = require('moment');
    require('moment/locale/pt');
    // registerLocale('pt', pt);
    // setDefaultLocale(pt);
    const [reload, setReload] = useState(true);

    const [processcig, setProcesscig] = useState([]);

    const [disabled, setDisabled] = useState(false);
    const [disablebutton, setDisablebutton] = useState(false);
    const [errors, setErrors] = useState('');
    const [edit, setEdit] = useState(true);

    const [SweatSucmsg, setSweatSucmsg] = useState('');
    const [sweatSuccess, setSweatSuccess] = useState(false);
    const [sweatWarning, setSweatWarning] = useState(false);
    const [SweatError, setSweatError] = useState(false);
    const [SweatErrmsg, setSweatErrmsg] = useState('');

    const form = useRef(null);
    const refGender = React.createRef();
    const [birth_date, setBirth_date] = useState(new Date());
    const [gender, setGender] = useState(0);
    const [state, setState] = useState(null);
    const [cohabitation, setCohabitation] = useState(0);
    const [model6, setModel6] = useState('');
    const [equipamentocvp, setEquipamentocvp] = useState('');

    const [victim_district, setVictimDistrict] = useState(0);
    const [victim_county, setVictimcounty] = useState(0);
    const [court_district, setCourtDistrict] = useState(0);
    const [court_county, setCourtcounty] = useState(0);
    const [court, setCourt] = useState(0);
    const [measureCoercion, setMeasureCoercion] = useState(0);
    const [measure_coercion_description, setMeasureCoercionDescription] = useState('');
    const [litigation, setLitigation] = useState('');
    const [litigationsequence, setLitigationsequence] = useState('');
    const [focal_point_id, setFocalPoint] = useState(0);
    const [focal_point_contact, setFocal_point_contact] = useState('');
    const [duration, setDuration] = useState(0);
    const [disabledDuration, setDisabledDuration] = useState(true);
    const [origtermination_date, setOrigTerminationDate] = useState('');
    const [termination_date, setTerminationDate] = useState('');
    const [cessationTerminationDate, setCessationTerminationDate] = useState('');
    const [extension, setExtension] = useState('');
    const [disabledExtension,setDisabledExtension] = useState(true);
    const [extension_date, setExtensionDate] = useState('');
    const [disabledExtensionDate,setDisabledExtensionDate] = useState(true);
    
    const [termination_order_date, setTerminationOrderDate] = useState('');
    const [termination_motive, setTerminationMotive] = useState('');
    const [activation_date, setActivationDate] = useState('');
    const [observations, setObservations] = useState('');

    const [disabledMeasureCoercionDescription, setDisabledMeasureCoercionDescription] = useState(true);
    
    const [focalpointAr, setFocalpointAr] = useState([]);

    const possibleDateTimeFormats = [
        // 'D MMMM YYYY',
        // 'D MMMM YYYY HH:mm',
        'DD-MM-YY',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'DD.MM.YYYY HH:mm',
        'DD/MM/YY',
        'DD/MM/YYYY',
        'DD/MM/YYYY HH:mm:ss',
        'HH:mm:ss',
        'h:mm',
        'M/D/YYYY',
        'D/M/YYYY',
        'MM-DD-YY',
        'MM-DD-YYYY',
        'MM-DD-YYYY HH:mm:ss',
        'MM/DD/YY',
        'MM/DD/YYYY',
        'MM/DD/YYYY HH:mm:ss',
        'MMM D YYYY',
        'MMM D YYYY LT',
        'MMMM Do YYYY',
        'MMMM Do YYYY LT',
        'YYYY-DD-MM HH:mm:ss',
        'YYYY-MM',
        'YYYY-MM-DD',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DD LT',
        'YYYY-MM-DD h:mm:ss A',
        'YYYY-MM-DDTHH:mm:ssZ',
        'ddd, MMM D YYYY LT',
        'dddd D MMMM YYYY HH:mm',
        'dddd, MMMM Do YYYY LT'
    ];
    
    async function getFocalPointlist() {
        //LISTS
        api.get(configData.API + '/focalpointlist').then((request) => {
            // console.log('request.data focalpointlist', request.data.data);
            setFocalpointAr(request.data.data)
           
            

        }).catch((err) => {
            console.log(err);
        });
    
    }

    async function iniciate() {
        
        if(focalpointAr.length===0) await getFocalPointlist()
        if (Object.keys(processcig).length === 0) {
            await getData(id);

        }
    }

    useEffect(() => {
        // console.log('props useeffect',props)
        // console.log('processcig', processcig, Object.keys(processcig).length)
        iniciate();

    }, []);

    async function getData() {
        // console.log('getdata id', id)
        //  api.post(configData.API + '/verifyprocess', paramsFormData).then((request) => {

        try {
            api.get(configData.API + '/process/' + id).then((response) => {
                // console.log('process request.data', response.data);

                setProcesscig(response.data);

                setLitigation(response.data.litigation)
                setLitigationsequence(response.data.litigationsequence)
                setState(response.data.state_id)
                setCohabitation(response.data.cohabitation)
                setModel6(response.data.model6)
                setEquipamentocvp(response.data.equipamentocvp)
                setGender(response.data.gender_id);
                setBirth_date(new Date(response.data.birth_date));
                // setBirth_date(moment(response.data.birth_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
                setVictimDistrict(response.data.victim_district_id);
                setVictimcounty(response.data.victim_county_id);
                setCourtDistrict(response.data.court_district_id);
                setCourtcounty(response.data.court_county_id);
                setCourt(response.data.court_id);
                setFocalPoint(response.data.focal_point_id);
                setFocal_point_contact(response.data.focal_point_contact);
                setDuration(response.data.duration);
                if(response.data.termination_motive_id !== null || response.data.termination_motive_id !== 0){
                    document.getElementById('termination_motive').value=response.data.termination_motive_id
                }
                setTerminationMotive(response.data.termination_motive_id);

                setMeasureCoercion(response.data.measureCoercion);

                if(response.data.measure_coercion !== null || response.data.measure_coercion !== 0){
                    document.getElementById('measure_coercion').value=response.data.measure_coercion
                }

                if(response.data.measure_coercion_description !== null || response.data.measure_coercion_description !== ''){
                    document.getElementById('measure_coercion_description').disabled=false
                    document.getElementById('measure_coercion_description').value=response.data.measure_coercion_description 
                }    
                setMeasureCoercionDescription(response.data.measure_coercion_description);

                // document.getElementById('measure_coercion').value=response.data.measureCoercion
                // document.getElementById('measure_coercion_description').value=response.data.measure_coercion_description

                if (response.data.activation_date !== null) {
                    setDisabledDuration(false)

                    if(Number(response.data.duration) !== 0) {
                        setDisabledExtensionDate(false)
                        if ((response.data.extension_date !== null) && (response.data.extension_date !== '')) setDisabledExtension(false)
                    }
                    
                }

                if ((response.data.termination_date !== null) && (response.data.termination_date !== '')) {
                    setTerminationDate(new Date(response.data.termination_date));
                    setOrigTerminationDate(new Date(response.data.termination_date));
                    // setTerminationDate(moment(response.data.termination_date,'YYYY-MM-DD').format('DD-MM-YYYY'));
                    // setOrigTerminationDate(moment(response.data.termination_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
                }
                if ((response.data.cessation_termination_order_date !== null) && (response.data.cessation_termination_order_date !== '')) {
                    // setCessationTerminationDate(moment(response.data.cessation_termination_order_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
                    setCessationTerminationDate(new Date(response.data.cessation_termination_order_date))
                    
                }
                if ((response.data.termination_order_date !== null) && (response.data.termination_order_date !== '')) {
                    // setCessationTerminationDate(moment(response.data.termination_order_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
                    setTerminationOrderDate(new Date(response.data.termination_order_date))
                    
                }
                
                setExtension(response.data.extension);
                if ((response.data.extension_date !== null) && (response.data.extension_date !== '')) {
                    setExtensionDate(new Date(response.data.extension_date));
                    // setExtensionDate(moment(response.data.extension_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
                }
                if ((response.data.activation_date !== null) && (response.data.activation_date !== '')) {
                    setActivationDate(new Date(response.data.activation_date));
                    // setActivationDate(moment(response.data.extension_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
             
                }
                
                setTerminationMotive(response.data.termination_motive_id);
                setObservations(response.data.observations);
                setSavedFiles(response.data.docs);

                

                setReload(false)
            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            setSweatSucmsg();
            setSweatError(!SweatError);
            setSweatErrmsg(error);
        }
      
    }

    // function formatDate(dt) {
    //     if (dt !== '')
    //         return ((dt.getDate() < 10) ? '0' + dt.getDate() : dt.getDate()) + '-' +
    //             (((dt.getMonth() + 1) < 10) ? '0' + (dt.getMonth() + 1) : + (dt.getMonth() + 1)) + '-' +
    //             dt.getFullYear();
    //     else return '';
    // }

    function download(docid) {
   
        try {
            api.get(configData.API + '/download/' + docid).then((response) => {
                var path = configData.BASE_URL + '/api/download/' + docid
                
                // console.log('download request.data', response.data);
                // window.open("http://localhost:9500/api/download/1",'_blank')
                window.open(path,'_blank')
                //window.open(response,'_blank')

            });
        } catch (error) {
            console.log('ERROR DOWNLOAD')
        }
    }

    function getAge(bDate) {
        // console.log('getAge', bDate);

        return moment().diff(bDate,'y')
    }

    function calcTerminationdate(date, months) {
        //calculo da data de termo
        return moment(date).add(months,'months')
    }

    function handleChangeExtension(e) {
        setExtension(e.target.value);

        var newdate = calcTerminationdate(extension_date, e.target.value);
        setTerminationDate(newdate);
        
    }

    function OpExtensionList(props) {
        // console.log('props', props);

        let numbers = [];
        for (var i = 0; i <= 36; i++) {
            numbers.push(i);
        }

        const options = numbers.map((numbers) =>
            <option key={numbers} value={numbers} > {numbers}</option>
        );

        return (
            <select
                id="extension"
                name="extension"
                className="form-control text-center"
                disabled={disabledExtensionDate}
                defaultValue={extension}
                onChange={(e) => handleChangeExtension(e)}
            >
                {options}
            </select>

        )
    }

    const [countyList, setCountyList] = useState({});
    const [courtcountyList, setCourtCountyList] = useState({});
    function getCountylist(district_id,field) {
        // console.log('getCountylist', this.state.district, district_id);
        //LISTS

        if (district_id !== 0) {
            // var districtid = document.getElementById(this.props.name_district).value;
            // console.log('comp getCountylist', this.state.district, district_id);

            api.get(configData.API + '/countylist/' + district_id).then((request) => {
                
                if (field === 'victim_district_id') setCountyList(request.data.data);
                else
                    setCourtCountyList(request.data.data);

            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const [courtList,setCourtList] = useState({});
    function getCourtlist(county_id) {
        //LISTS

        if (county_id !== 0) {
            api.get(configData.API + '/courtlist/' + county_id).then((request) => {
                // console.log('request.data CourtList', request.data.data);
                setCourtList(request.data.data);


            }).catch((err) => {
                console.log(err);
            });
        }


    }
    

    

    function OpModel6List(props) {
        // console.log('props', props);

        const opts= ['Não', 'Sim'];
        
        const options = opts.map((opt,index) =>
            <option key={opt} value={opt} >{opt}</option>
        );

        return (
            <select
                id="model6"
                name="model6"
                className="form-control text-center"
                disabled={disabled}
                value={model6}
                onChange={(e) => handleChangeModel6(e)}
            >
                {options}
            </select>

        )
    }

    function handleChangeEquipamentocvp(e) {
        setEquipamentocvp(e.target.value);
    }
    
    function OpEquipamentocvpList(props) {
        // console.log('props', props);

        const opts= ['Não', 'Sim'];
        
        const options = opts.map((opt,index) =>
            <option key={opt} value={opt} >{opt}</option>
        );

        return (
            <select
                id="equipamentocvp"
                name="equipamentocvp"
                className="form-control text-center"
                disabled={disabled}
                value={equipamentocvp}
                onChange={(e) => handleChangeEquipamentocvp(e)}
            >
                {options}
            </select>

        )
    }

    

    function handleChange(field, value) {
        // console.log('handlechange', field, value)
        // console.log('handlechange', e.target.value)

        /* v0
        let tmp = processcig;

        Object.keys(tmp).map((keyname, key) => {
            console.log('keyname,key, value', keyname, key, tmp[keyname])
            // return keyname, tmp[keyname]
            if (keyname === field) {
                console.log('match', keyname, field)
                return tmp[keyname] = value;
            }
        });
        console.log('tmp', tmp)
        setProcesscig(tmp);
        document.getElementById(field).value = value;
        */
        let tmp = processcig;
        tmp[field] = value;
        setProcesscig(tmp)

        if(field === 'litigation') {
            setLitigation(value);
        }
        if(field === 'litigationsequence') {
            setLitigationsequence(value);
        }
        if(field === 'gender_id') {
            setGender(value);
        }
        if (field === 'victim_district_id') {
            setVictimDistrict(value);
            // document.getElementById('victim_district_id').value = value;
            // getCountyList(value,field);
        }
        if (field === 'victim_county_id') {
            setVictimcounty(value);
        }
        if (field === 'court_district_id') {
            setCourtDistrict(value);
            // setCountyList(value);
        }
        if (field === 'court_county_id') {
            setCourtcounty(value);
        }
        if (field === 'court') {
            setCourt(value);
        }
        if (field === 'measure_coercion') {
            setMeasureCoercion(value);
            // console.log('measureCoercion value', value)
            tmp['measure_coercion'] = value
            setProcesscig(tmp)

            if (Number(value) === 1) {
                setDisabledMeasureCoercionDescription(false);
                document.getElementById('measure_coercion_description').disabled = false;
            }else {
                setDisabledMeasureCoercionDescription(true);
                document.getElementById('measure_coercion_description').disabled = true;
            }
            
        }

        if (field === 'focal_point_id') {
            var filter = focalpointAr.filter((el)=> el.id===Number(value))
            setFocalPoint(value);
            
            if (filter.length>0) {
                var email = filter[0].email
                setFocal_point_contact(email)
                tmp['focal_point_contact'] = email
                setProcesscig(tmp)

            }

            // document.getElementById('focal_point_contact').value=filter.email
            
        }
        if (field === 'termination_motive') {
            setTerminationMotive(value);
        }
        if (field === 'extension') {
            setExtension(value);
        }  
        if (field === 'observations') {
            setObservations(value);
            document.getElementById('observations').value = value;
        }
        
        
    }

    function handleChangeMeasureCoercionDescription(e) {

        setMeasureCoercionDescription(e.target.value);
        let tmp = processcig;
        tmp['measure_coercion_description'] = e.target.value;
        setProcesscig(tmp)
    }
    
    
    const handleChangeBirthDate = (date) => {
        // console.log('handleChangeBirthDate', date)
        
        setBirth_date(date);

        let tmp = processcig;
        let calcage = getAge(date);
        // tmp['birth_date'] = formatDate(date);
        tmp['birth_date'] = moment(date).format('DD-MM-YYYY');
        tmp['age'] = calcage;

        setBirth_date(date)

        // setProcesscig(tmp);
        setProcesscig(tmp)

    }

    function handleChangeDuration(e) {
        // console.log("handleChangeDuration", e.target.value);
        // console.log("handleChangeDuration", activation_date);
        // console.log("handleChangeDuration", edit);
        setDuration(e.target.value);

        if (edit) {
            if (Number(e.target.value) === 0) {
                setTerminationDate('');
            } else {
                let newdate = calcTerminationdate(activation_date, e.target.value);
                // console.log('newdate', newdate);
                setTerminationDate(newdate);
                // document.getElementById('termination_date').value = newdate;
                setOrigTerminationDate(newdate);

                setDisabledExtensionDate(false)

                // console.log('extension', extension)
                // console.log('termination_date', termination_date)
                // console.log('origtermination_date', origtermination_date)
                if ((Number(extension) !== 0) && (extension !== null)) {
                    let newdateext = calcTerminationdate(origtermination_date, e.target.value);
                    // console.log('newdateext', newdateext);
                    setTerminationDate(newdateext);
                 
                }
            }

        }
    }


    const handleChangeActivationDate = (date) => {
        // console.log('handleChangeActivationDate',edit)

        if(date.length<10) return;
        if (moment(date, possibleDateTimeFormats, true).isValid()) {
        }else {
            // console.log('no valid date',date);
            return
            
        }
        
        
        setActivationDate(date);
        // console.log('handleChangeActivationDate',date)
        
        if (date !== null) {
            //altera tb o termo de cessacao se a duracao for !== 0
            // console.log('handleChangeActivationDate duration',duration);
            if (duration !== 0) {
                var newdate = calcTerminationdate(date, duration);
                // console.log('newdate', newdate);
                setTerminationDate(newdate);
                setOrigTerminationDate(newdate);
            }
        
                setDisabledDuration(false)
                setDisabledExtension(false)
            
            
        } else {
            setDisabledDuration(true)
            setDisabledExtension(true)
        }
        

    }

    

    
    const handleChangeExtensionDate = (date) => {
        // console.log('handleChangeExtensionDate', date);
        setExtension(0)
        setExtensionDate(date);
        setTerminationDate(date)
        if (date !== null) {
     
            setDisabledExtensionDate(false)
          
        }else setDisabledExtensionDate(true)

    }

    const handleChangeTerminationOrderDate = (date) => {
        setTerminationOrderDate(date);
        

    }
 
    const handleChangeCessationTerminationDate = (date) => {
        // console.log('handleChangeCessationTerminationDate')
        setCessationTerminationDate(date);
        setTerminationDate(null)
        

    }
    const handleChangeTerminationDate = (date) => {
        // console.log('handleChangeCessationTerminationDate')
        setTerminationDate(date)
        

    }
   
    
    function handleChangeGender(e) {
        // console.log('handleChangeGender', e.target.value);
        setGender(e.target.value);

        let tmp = processcig;
        tmp['gender_id'] = e.target.value;
        setProcesscig(tmp)
        
    }

    

    function handleChangeCohabitation(e) {
        setCohabitation(e.target.value);

        let tmp = processcig;
        tmp['cohabitation'] = e.target.value;
        setProcesscig(tmp)
    }

    function handleChangeModel6(e) {
        setModel6(e.target.value);
        let tmp = processcig;
        tmp['model6'] = e.target.value;
        setProcesscig(tmp)
    }

    function handleChangeEquipamentocvp(e) {
        
        setEquipamentocvp(e.target.value);
        let tmp = processcig;
        tmp['equipamentocvp'] = e.target.value;
        setProcesscig(tmp)
    }

    function handleChangeState(e) {
        setState(e.target.value);
        let tmp = processcig;
        tmp['state_id'] = e.target.value;
        setProcesscig(tmp)
    }

    function doEditForm() {
        // console.log('edit');
        setEdit(true);
        setDisabled(false);
        
        
        if (activation_date !== null) {
            setDisabledDuration(false)

            if(Number(duration) !== 0) {
                setDisabledExtensionDate(false)
                if ((extension_date !== null) && (extension_date !== '')) setDisabledExtension(false)
            }
            
        }
        


        
        //div dos docs
        // document.getElementById('process_files').className = '';
    }

    function doCancel() {
        // console.log('doCancel');

        setDisabled(true);
        setEdit(false);

    }

    function handleback() {

        history.push('/app/list-processes');
        
    }

    const [savedfiles, setSavedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    // const [disableFiles, setDisableFiles] = useState(true);

    function onFileChange(e) {
        // console.log(e.target.files)

        // console.log('onfilechange savedfiles', savedfiles)
        var artmp_files = files;
        let artmp_savedfiles = [];

        //passar a estrutura para um tmp
        var tmpfile = {};
        for (const key of Object.keys(savedfiles)) {
            tmpfile =
            {
                'id': savedfiles[key].id,
                'process_id': savedfiles[key].process_id,
                'state': savedfiles[key].state,
                'name': savedfiles[key].name,
                'filetype': savedfiles[key].filetype,
                'created_at': savedfiles[key].created_at

            };

            artmp_savedfiles.push(tmpfile);
        }

        tmpfile = {};
        for (const key of Object.keys(e.target.files)) {

            tmpfile =
            {
                'id': 0,
                'state': "A enviar",
                'name': e.target.files[key].name,
                'filetype': e.target.files[key].type,
                'created_at': new Date(e.target.files[key].lastModified).toLocaleString().replace('/', '-').replace('/', '-')

            }
                ;

            artmp_savedfiles.push(tmpfile)
            // artmp_files.push(tmpfile)
            artmp_files.push(e.target.files[key])
        }

        // console.log('onfilechange array tmp', artmp_savedfiles, artmp_files, files)

        setSavedFiles(artmp_savedfiles);
        setFiles(artmp_files);

        // console.log('onfilechange', files, savedfiles)
    }

    function deleteFile(id) {
        //elimina o fich da bd    
        // console.log('delete', id)

        const formdata = new FormData();
        formdata.append('process_doc_id', id);

        // console.log('formdata', formdata);

        api.post(configData.API + '/deletefile/' + id, formdata).then((response) => {
            // console.log('request.data process delete', JSON.stringify(response.data.data));
            //sweetalert success

            setSweatSuccess(true);
            setSweatSucmsg(response.data.message);

        }).catch((err) => {
            console.log('erro POST DELETE', err);
            if (err.request.status === 422) {
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);
                setEdit(false);
            } else {
                setSweatSucmsg();
                setSweatError(!SweatError);
                setSweatErrmsg(err);
            }



        });


    }

    const paginationComponentOptions = {
		rowsPerPageText: 'Registos por Página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
		// noRowsPerPage: 10
	};

    const columns = [
        {
            name: 'Estado',
            selector: row => row.state,
            width: 10,
            sortable: true,
            center: true,
            // className: 'text-center'
        },
        {
            name: 'Ficheiro',
            selector: row => <a className='link' id='link' onClick={(file) => download(row.id)}>{row.name}</a>,
            sortable: true,
            center: true,
            // className: 'text-center'
        },
        {
            name: 'Tipo de Ficheiro',
            selector: row => row.filetype,
            sortable: true,
            center: true,
        },
        {
            name: 'Última Modificação',
            selector: row => row.created_at,
            sortable: true,
            center: true,
        },
        {
            name: 'Opções',
            center: true,
            cell: row => <center>
                {renderOptions(row.id)}
            </center>,
        }
    ]

    function renderOptions(id) {
        let show = true;
        if (id === 0) show = false;
        return (

            <>
                {
                    (show) && (
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="inverse" caret><i className="fa fa-cog fa-xs"></i> </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem as="button">
                                    <div onClick={(e) => deleteFile(`${id}`)}>
                                        Eliminar
                                    </div>
                                </DropdownItem>
                                {/* <DropdownItem as="button">
                            <div onClick={() => deactivateProcess(`${id}`)}>
                                Desativar
                            </div>
                        </DropdownItem> */}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    )
                }
            </>
        )


    }

    function handleSubmit(event) {
        event.preventDefault();
        setDisablebutton(true)
        // console.log('submit form');
        // console.log('submit', processcig)
        
        const formContent = new FormData(form.current);

        formContent.append('process', JSON.stringify(processcig));
        formContent.append('litigation', processcig.litigation);
        formContent.append('birth_date',moment(birth_date,'DD-MM-YYYY').format('DD-MM-YYYY'));
        formContent.append('termination_date', ((termination_date !== null)) ? moment(termination_date,'DD-MM-YYYY').format('DD-MM-YYYY'):null);
        formContent.append('termination_order_date', moment(termination_order_date,'DD-MM-YYYY').format('DD-MM-YYYY'));
        
        formContent.append('termination_date', moment(termination_date,'DD-MM-YYYY').format('DD-MM-YYYY'));
        
        formContent.append('activation_date', (activation_date !== null) ? moment(activation_date,'DD-MM-YYYY').format('DD-MM-YYYY') : null);
        formContent.append('extension_date', moment(extension_date,'DD-MM-YYYY').format('DD-MM-YYYY'));
        // formContent.append('extension_date', (processcig.extension_date !== null) ? processcig.extension_date:null);
        formContent.append('cessationTerminationDate', (cessationTerminationDate !== null) ?  moment(cessationTerminationDate,'DD-MM-YYYY').format('DD-MM-YYYY') : null);
        formContent.append('cessation_termination_order_date', (processcig.cessation_termination_order_date !== null) ?  moment(processcig.cessation_termination_order_date,'DD-MM-YYYY').format('DD-MM-YYYY') : null);
        // formContent.append('measure_coercion_description', processcig.measure_coercion_description);
        
        // formContent.append('process', JSON.stringify(processcig));

        // Details of the uploaded file
        // console.log('submit files', files)
        for (let i = 0; i < files.length; i++) {
            formContent.append('file_' + i, files[i]);

        }

     

        api.post(configData.API + '/processu/' + id, formContent).then((response) => {
            // console.log('request.data process update', JSON.stringify(response.data.data));
            //sweetalert success

            setSweatSucmsg(response.data.message);
            if(response.data.type) setSweatWarning(true);
            else setSweatSuccess(true);

            setDisablebutton(false)

            setTimeout(() => {
                history.push('/app/list-processes');
            }, 3000);


        }).catch((err) => {
            console.log('erro POST', err);
            setDisablebutton(false)
            if (err.request.status === 422) {
                // console.log('erros', err.response, err.request, err.message);
                setErrors(err.response.data.errors);
                setEdit(true);
            } else {
                setSweatSucmsg();
                setSweatError(!SweatError);
                setSweatErrmsg(err);
            }



        });
        

    }

        

    return (
        <Panel>
            {/* <PanelHeader>
                    Ver dados do Processo - {num_order}
                </PanelHeader> */}
            <div className="panel-heading">
                <h4 className="panel-title">Ver dados do Processo - {processcig.num_order}</h4>
                <div className="panel-heading-btn">
                    <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => handleback()}><i className="fa fa-reply"></i></button>
                </div>
                
            </div>
            <PanelBody>
                 {(reload &&
							<div className="panel-loader">
								<span className="spinner-small"></span>
							</div>
						)} 
                <form ref={form} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group m-b-15 col-xl-2 ">
                            
                            <label htmlFor="">Nº Processo Judicial *</label>
                            <input
                                id="litigation"
                                name="litigation"
                                type="text"
                                className='form-control m-b-5 text-center'
                                defaultValue={litigation}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                
                            />
                            
                            
                        </div>
                        <div className="form-group m-b-15 col-xl-2 ">
                            <label>Processo Judicial Relacionado</label>
                            <input
                                id="litigationsequence"
                                name="litigationsequence"
                                type="text"
                                className='form-control m-b-5'
                                defaultValue={litigationsequence}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />

                        </div>
                        <div className="form-group m-b-15 col-xl-1 ">

                            <label>Estado</label>
                            <StateList
                                state={state}
                                handleChangeState={(e) => handleChangeState(e)}
                                disabled={disabled}
                            />

                        </div>
                        <div className="form-group m-b-15 col-xl-1">
                            <label htmlFor="">Data de Despacho *</label>
                            {
                                (disabled) ? 
                                    <input type={'text'} 
                                        className={'form-control m-b-15 text-center'}
                                        defaultValue={(termination_order_date) ? moment(termination_order_date).format('DD-MM-YYYY') : ''} disabled />
                                
                                :
                                <Datetime
                                    className={(errors.termination_order_date) ? 'text-center is-invalid' : 'text-center'}
                                    id="termination_order_date"
                                    name="termination_order_date"
                                    locale="PT"
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat={false}
                                    selected={termination_order_date}
                                    value={termination_order_date}
                                    disabled={disabled}
                                    onChange={(date) => handleChangeTerminationOrderDate(date)}
                                    
                                /> 
                            }
                         
                            {
                                (errors.termination_order_date) && (
                                    <span className="is-invalid m-t-20">{errors.termination_order_date[0]}</span>
                                )
                            }

                        </div>
                        <div className="form-group m-b-15 col-xl-2 ">

                            <CohabitationList
                                cols={12}
                                cohabitation={cohabitation}
                                handleChangeCohabitation={(e) => handleChangeCohabitation(e)}
                                disabled={disabled}
                            />

                        </div>
                        <div className="form-group m-b-15 col-xl-1">
                            <label htmlFor="">Modelo 6</label>
                            <OpModel6List />
                            
                        </div>
                        <div className="form-group m-b-15 col-xl-2">
                            <label title="Equipamento Entregue na CVP" htmlFor="">Equip. Entregue na CVP</label>
                            <OpEquipamentocvpList />
                            
                        </div>
                        
                        <div className="form-group m-b-15 col-xl-1">
                            <GenderList
                                disabled={disabled}
                                handleChangeGender={(e) => handleChange(e.target.name, e.target.value)}
                                gender={gender}
                            />
                            {errors.gender_id &&
                                <span className="is-invalid">{errors.gender_id[0]}</span>

                            }

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group m-b-15">
                                        <label htmlFor="">Nome do/a Utente * </label>
                                        <input
                                            name="name"
                                            type="text"
                                            className={(errors.name) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                            defaultValue={processcig.name}
                                            disabled={disabled}
                                            onChange={(e) => handleChange(e.target.name, e.target.value)}

                                        />
                                        {errors.name &&
                                            <span>{errors.name[0]}</span>

                                        }
                                    </div>
                                </div>
                                <div className="form-group col-xl-2">
                                    <label htmlFor="">Data de Nascimento * </label>

                                    {
                                    (disabled) ? 
                                        <input type='text'
                                            className={'form-control m-b-15 text-center'}
                                            name="birth_date"
                                            defaultValue={(birth_date) ? moment(birth_date).format('DD-MM-YYYY') : ''} disabled />
                                    
                                    :
                                    <Datetime
                                        // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                        className={(errors.birth_date) ? 'text-center is-invalid' : 'text-center'}
                                        id="birth_date"
                                        name="birth_date"
                                        locale="PT"
                                        dateFormat="DD-MM-YYYY"
                                        timeFormat={false}
                                        selected={birth_date}
                                        value={birth_date}
                                        onChange={(date) => handleChangeBirthDate(date)}
                                        
                                    />
                                    }
                                    {errors.birth_date &&
                                        <span className="is-invalid">{errors.birth_date[0]}</span>

                                    }
                                </div>

                                 <div className="form-group m-b-15 col-xl-2">
                                    <label htmlFor="">Idade</label>
                                    <input
                                        id='age'
                                        name='age'
                                        type="number"
                                        className="form-control m-b-5 text-center"
                                        defaultValue={processcig.age}
                                        // onChange={(e) => setAge(e.target.value)}
                                        disabled

                                    />
                                </div>

                                
                            </div>

                            <div className="row">    
                                <DistrictList
                                    cols='4'
                                    name_district='victim_district_id'
                                    name_county='victim_county_id'
                                    // countylist_temp={countylist}
                                    // disable='true'
                                    disabledistrict={disabled}
                                    disablecounty={disabled}
                                    hcourt='false'
                                    error_district={errors.victim_district_id}
                                    error_county={errors.victim_county_id}
                                    // victim_district={victim_district}
                                    // victim_county={victim_county}
                                    // refd={refVictimdistrict}
                                    // refc={refVictimcounty}
                                    // handleChangeDistrict={handleChangeDistrictVictim}
                                    handleChangeDistrict={(e) => handleChange(e.target.name, e.target.value)}
                                    district={victim_district}
                                    county={victim_county}
                                     // countylist={countylist}
                                />

                                <div className="form-group col-xl-4">
                                    <label htmlFor="">Localidade (Vitima) * </label>
                                    <input
                                        name="locality"
                                        type="text"
                                        className="form-control m-b-5"
                                        defaultValue={processcig.locality}
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                    {errors.locality &&
                                        <span className="is-invalid">{errors.locality[0]}</span>

                                    }
                                </div> 


                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    <div className="row">

                        <legend className="no-border f-w-700 p-b-0 m-t-10 m-b-10 m-l-10 f-s-16 text-inverse">Tribunal</legend>
                        <DistrictList
                            name_district='court_district_id'
                            name_county='court_county_id'
                            cols='4'
                            hcourt='true'
                            error_district={errors.court_district_id}
                            error_county={errors.court_county_id}
                            error_court={errors.court_id}
                            disabledcourt={disabled}
                            disabledistrict={disabled}
                            disablecounty={disabled}
                            handleChangeDistrict={(e) => handleChange(e.target.name, e.target.value)}
                            district={court_district}
                            county={court_county}
                            court={court}
                        />

                    </div>

                    <div className="row">

                        <MeasureCoercionList
                            disable={disabled}
                            handleChangeMeasureCoercion={(e) => handleChange(e.target.name, e.target.value)}
                            cols='2'
                            errors={errors.measure_coercion}
                            measure_coercion={measureCoercion}
                        />

                        {/* <div className={stclassName}>
                            <label>Medida de Coação *</label>
                            <select
                                id="measureCoercion"
                                name='measureCoercion'
                                className={(this.props.errors) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                disabled={this.props.disable}
                                value={(this.props.measureCoercion !== null) ? this.props.measureCoercion : 0}
                                onChange={this.props.handleChangeMeasureCoercion}
                            >
                                {options}
                            </select>
                            {
                                (this.props.errors) && (
                                    <span className="is-invalid m-t-20">{this.props.errors[0]}</span>
                                )
                            }
                        </div> */}


                        <div className="m-b-15 col-xl-5">
                            <label>Qual?</label>
                            <div >
                                <input
                                    id="measure_coercion_description"
                                    name="measure_coercion_description"
                                    disabled={disabledMeasureCoercionDescription}
                                    type="text"
                                    className="form-control m-b-5"
                                    defaultValue={measure_coercion_description}
                                    onChange={(e) => handleChangeMeasureCoercionDescription(e)}

                                />
                            </div>
                        </div>
                    

                        <FocalPointList
                            // disable='true'
                            disabled={disabled}
                            handleChangeFocalPoint={(e) => handleChange(e.target.name, e.target.value)}
                            cols='2'
                            errors={errors.focal_point_id}
                            focal_point_id={focal_point_id}
                          
                        />

                        <div className="form-group m-b-15 col-xl-3">
                            <label htmlFor="">Focal Point: Contato *</label>
                            <input
                                id="focal_point_contact"
                                name="focal_point_contact"
                                type="text"
                                className={(errors.focal_point_contact) ? 'form-control m-b-5 is-invalid' : 'form-control m-b-5'}
                                defaultValue={focal_point_contact}
                                disabled={disabled}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                            {errors.focal_point_contact &&
                                <span className="is-invalid">{errors.focal_point_contact[0]}</span>

                            }
                        </div>
                    </div>
                    <hr></hr>

                    <div className="row">
                        <div className="form-group m-b-5 col-xl-5 offset-1">
                            <div className="row">
                                <div className="form-group col-xl-5 ">
                                    <legend className="no-border f-w-700 p-b-0 m-t-10 f-s-16 text-inverse">Ativação</legend>
                                    <div className='col-xl-12'>
                                        <label htmlFor="">Data de Ativação *</label>
                                        {
                                        (disabled) ? 
                                            <>
                                                <input type={'text'} 
                                                    name="activation_date"
                                                    className={'form-control m-b-15 text-center'}
                                                    defaultValue={(activation_date) ? moment(activation_date).format('DD-MM-YYYY') : ''} disabled />
                                            </>
                                        :
                                       
                                        <Datetime
                                            className="text-center"
                                            id="activation_date"
                                            name="activation_date"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={activation_date}
                                            value={activation_date}
                                            onChange={(date) => handleChangeActivationDate(date)}
                                            
                                        />
                                        }
                                        {errors.activation_date &&
                                            <span className='invalid-feedback'>{errors.activation_date[0]}</span>

                                        }
                                    </div>
                                </div>


                                <div className="form-group m-b-15 m-t-40 col-xl-6">
                                    <label htmlFor="">Duração (meses) *</label>
                                    <DurationList
                                        disabled={disabledDuration}
                                        duration={duration}
                                        errors={errors.duration}
                                        limit={36}
                                        handleChangeDuration={(e) => handleChangeDuration(e)}

                                    />

                                </div>
                            </div>
                        </div>

                        <div className="form-group m-b-15 col-xl-5 ">
                            <div className="row">
                                <div className="form-group m-b-15 col-xl-5 ">
                                    <legend className="no-border f-w-700 p-b-0 m-t-10 f-s-16 text-inverse">Prorrogação</legend>
                                    <div className="form-group m-b-15 offset-1">
                                        <label htmlFor="">Data de Prorrogação *</label>
                                        {
                                        (disabledExtensionDate) ? 
                                            <input type={'text'} 
                                                name="extension_date"
                                                className={'form-control m-b-15 text-center'}
                                                defaultValue={(extension_date) ? moment(extension_date).format('DD-MM-YYYY') : ''} disabled />
                                        
                                        :
                                        <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className={(errors.extension_date) ? 'text-center is-invalid' : 'text-center'}
                                            id="extension_date"
                                            name="extension_date"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={extension_date}
                                            value={extension_date}
                                            onChange={(date) => handleChangeExtensionDate(date)}
                                            
                                        />
                                    }
                                        
                                        {errors.extension_date &&
                                            <span className='invalid-feedback'>{errors.extension_date[0]}</span>

                                        }
                                    </div>
                                </div>
                                <div className="form-group m-b-15  m-t-40 col-xl-6 ">

                                    <label htmlFor="">Prorrogação (meses)</label>
                                    <OpExtensionList />


                                </div>
                            </div>
                        </div>
                    </div>

              

                        <div className="row form-group m-b-15 ">
                            <div className='col-xl-12  offset-1 '>
                                <div className="row">
                                    <div className="form-group col-xl-6">
                                        <legend className="no-border f-w-700 p-b-0 m-t-10 f-s-16 text-inverse">Cessação</legend>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-xl-2 p-l-35'>
                                        <label htmlFor="">Data de Despacho de Cessação *</label>
                                         {
                                            (disabled) ? 
                                                <input type={'text'} 
                                                name="cessationTerminationDate"
                                                    className={'form-control m-b-15 text-center'}
                                                    defaultValue={(cessationTerminationDate) ? moment(cessationTerminationDate).format('DD-MM-YYYY') : ''} disabled />
                                            
                                            :
                                        <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className={(errors.cessation_termination_order_date) ? 'text-center is-invalid' : 'text-center'}
                                            id="cessationTerminationDate"
                                            name="cessationTerminationDate"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={cessationTerminationDate}
                                            value={cessationTerminationDate}
                                            onChange={(date) => handleChangeCessationTerminationDate(date)}
                                            
                                        />
                                         }
                                        
                                        {errors.cessation_termination_order_date &&
                                            <span className='invalid-feedback'>{errors.cessation_termination_order_date[0]}</span>

                                        }
                                    </div>

                                    <div className="form-group m-b-15 col-xl-5">
                                        <TerminationMotiveList
                                            disable={disabled}
                                            handleChangeTerminationMotive={(e) => handleChange(e)}

                                            termination_motive={termination_motive}
                                            cols='12'

                                        />
                                    </div>
                                {/* </div> */}
                            {/* </div>
                            <div className='col-xl-5'> */}
                                {/* <div className="row m-t-20"> */}
                                    <div className="form-group m-b-15 col-xl-2">
                                        <label htmlFor="">Termo Previsto da Cessação</label>
                                        {
                                        (disabled) ? 
                                            <input type={'text'} 
                                            name="termination_date"
                                                className={'form-control m-b-15 text-center'}
                                                defaultValue={(termination_date) ? moment(termination_date).format('DD-MM-YYYY') : ''} disabled />
                                        
                                        :
                                        <Datetime
                                            // className={(errors.activation_date) ? 'text-center m-b-5 is-invalid' : 'text-center m-b-5'}
                                            className="text-center"
                                            id="termination_date"
                                            name="termination_date"
                                            locale="PT"
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            selected={termination_date}
                                            value={termination_date}
                                            onChange={(date) => handleChangeTerminationDate(date)}
                                            
                                        />
                                    }
                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                        


                    <div className="row form-group m-b-15 ">
                        <div className="col-md-6 ">
                            <label htmlFor="">Observações</label>
                            <textarea className="form-control m-b-5"
                                value={observations}
                                // onChange={handleChangeObserv}
                                // onChange={(e) => setObservations(e.target.value)}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                id="observations"
                                name="observations"
                                disabled={disabled}
                                rows={20}
                            >
                                {observations}</textarea>

                        </div>
                        <div className="col-md-6 ">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Ficheiros de Processo</h6>
                                </div>
                            </div>
                            
                            <FilesUploadComponent
                                files={files}
                                onFileChange={onFileChange}
                                disabled={disabled}
                                className={(disabled) ? 'd-none' : ''}
                            />
                            
                            <div className="row">
                                <DataTable
                                    keys="id"
                                    pagination
                                    paginationPerPage={5}
                                    id="filedocs"
                                    columns={columns}
                                    data={savedfiles}
                                    paginationComponentOptions={paginationComponentOptions}
                                    noDataComponent={"Sem Registos"}
                                />

                            </div>

                        </div>

                    </div>

                    <div>
                        {
                            (sweatSuccess &&
                                <SweetAlert success
                                    title="Sucesso!"
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => setSweatSuccess(!sweatSuccess)}
                                >
                                    {SweatSucmsg}
                                </SweetAlert>
                            )
                        }

                         {
                            (sweatWarning &&
                                <SweetAlert warning
                                    title={translations['alerts']['missingfields'].title}
                                    confirmBtnBsStyle="warning"
                                    onConfirm={() => setSweatWarning(!sweatWarning)}
                                >
                                    {SweatSucmsg}
                                </SweetAlert>
                            )
                        }

                        {
                            (SweatError &&
                                <SweetAlert danger
                                    title="Erro!"
                                    confirmBtnBsStyle="danger"
                                    onConfirm={() => setSweatError(!SweatError)}
                                >
                                    {SweatErrmsg}
                                </SweetAlert>
                            )
                        }
                    </div>

                    {

                        (edit) && (
                            <>
                                <hr></hr>
                                <div>

                                    <button className="btn btn-danger m-r-10" type="button" onClick={doCancel}>Cancelar</button>
                                    <button className="btn btn-success " disabled={disablebutton} type="submit">Guardar</button>
                                </div>
                            </>
                        )
                    }
                </form>
            </PanelBody>

            {/* {

                (!edit) ? (
                    <PanelFooter>
                        <button className="btn btn-primary " onClick={doEditForm}>Editar</button>
                    </PanelFooter>
                )
                    : ''
            } */}
        </Panel>
    )   
}

export default ViewProcessForm;
